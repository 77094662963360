import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, LinearProgress } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import Alert from "@material-ui/lab/Alert";

import OpenQuestionCard from "../questions/OpenQuestionCard";
import axios from "axios";
OpenQuestionAssesment.propTypes = {};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1, 2),
  },

  pagination: {
    marginRight: "auto !important",
    marginLeft: "auto !important",
    marginTop: "8px",
  },
}));

function OpenQuestionAssesment(props) {
  const classes = useStyles();

  const case_id = props.match.params.case_id;
  const [questions, setQuestions] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const numberOfItemsPerPage = 3;

  const handleChange = (event, value) => {
    setPage(value);
  };

  const loadOpenQuestions = (case_id) => {
    // Load the case data from the backend
    setLoading(true);
    axios
      .get("apa/api/questions/open/", {
        params: {
          case_id: case_id,
          include_model_answers: true,
        },
      })
      .then((res) => {
        setQuestions(res.data);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  React.useEffect(() => {
    if (questions == null) {
      loadOpenQuestions(case_id);
    }
  }, [loadOpenQuestions]);

  return (
    <React.Fragment>
      {loading ? (
        <LinearProgress />
      ) : questions && questions.length ? (
        <React.Fragment>
          <Grid container spacing={3} justify="center">
            {questions.slice((page - 1) * numberOfItemsPerPage, page * numberOfItemsPerPage).map((question, index) => (
              <Grid item xs={4}>
                <OpenQuestionCard
                  question={question}
                  type="model_answers"
                  onChangeModelAnswers={(newModelAnswers) => {
                    const newData = [...questions];
                    newData[index + numberOfItemsPerPage * (page - 1)].model_answers = newModelAnswers;
                    setQuestions(newData);
                  }}
                  editable
                  key={page}
                />
              </Grid>
            ))}
          </Grid>

          <Box position="fixed" bottom={0} mb={2} display="flex" width={0.85} justifyContent="center">
            <Pagination
              className={classes.pagination}
              color="primary"
              variant="outlined"
              size="large"
              showFirstButton
              showLastButton
              count={Math.ceil(questions.length / numberOfItemsPerPage)}
              page={page}
              onChange={handleChange}
            />
          </Box>
        </React.Fragment>
      ) : (
        <Alert severity="info">No open questions exist for this case.</Alert>
      )}
    </React.Fragment>
  );
}

export default OpenQuestionAssesment;
