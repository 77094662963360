import { Backdrop, LinearProgress, Box, Fab, Grid, Paper, Popover, Snackbar, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import SelectCase from "../general/SelectCase";
import MultipleChoiceQuestionCard from "../questions/MultipleChoiceQuestionCard";
import MultilpeChoiceQuestionCreateCard from "./MultipleChoiceQuestionCreateCard";
import SnackbarContentWrapper from "../utility/SnackbarContentWrapper";
import Alert from "@material-ui/lab/Alert";
import Pagination from "@material-ui/lab/Pagination";

MultipleChoiceTesting.propTypes = {};

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
    "&:hover": {
      color: "white",
    },
  },
  paper: {
    padding: theme.spacing(1, 2),
  },
  popover: {
    maxWidth: "1500",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pagination: {
    marginRight: "auto !important",
    marginLeft: "auto !important",
    marginTop: "8px",
  },
}));

function MultipleChoiceTesting(props) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [selectedCase, setSelectedCase] = React.useState(null);
  const [questions, setQuestions] = React.useState(null);
  const [editQuestion, setEditQuestion] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const open = Boolean(anchorEl);

  const fabRef = React.useRef();

  const [mode, setMode] = React.useState("default");

  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const numberOfItemsPerPage = 6;

  const loadMCQuestions = (case_id) => {
    // Load the case data from the backend
    axios
      .get("apa/api/questions/mc/", {
        params: {
          case_id: case_id,
        },
      })
      .then((res) => {
        setQuestions(res.data);
      })
      .catch((error) => console.log(error));
  };

  const handleClose = () => {
    setEditQuestion(null);
    setAnchorEl(null);
    setMode("default");
  };

  const handleOpenPopover = (event) => {
    setAnchorEl(fabRef.current);
  };

  const handleEdit = (question) => {
    setEditQuestion(question);
    setMode("edit");
    handleOpenPopover();
  };

  const handleDelete = (question) => {
    axios.delete(`apa/api/questions/mc/${question.id}/`).then((res) => {
      loadMCQuestions(selectedCase.id);
      setSnackBarMessage("Succesfully deleted question!");
      setOpenSnackBar(true);
    });
  };

  const handleSucces = () => {
    // setUpdateKey(updateKey + 1);
    loadMCQuestions(selectedCase.id);
    if (mode === "edit") {
      setSnackBarMessage("Succesfully edited question!");
    } else {
      setSnackBarMessage("Succesfully created new question!");
    }

    setOpenSnackBar(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
    setSnackBarMessage("");
  };

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid item xs={6}>
            <Box mb={2}>
              <Paper>
                <SelectCase
                  onChange={(newValue) => {
                    setSelectedCase(newValue);
                    loadMCQuestions(newValue.id);
                  }}
                  default
                  variant="outlined"
                />
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      {questions ? (
        <React.Fragment>
          <Box style={{ height: 550 }}>
            <Grid container spacing={3}>
              {questions.slice((page - 1) * numberOfItemsPerPage, page * numberOfItemsPerPage).map((question) => (
                <Grid item xs={4}>
                  <MultipleChoiceQuestionCard
                    key={page}
                    question={question}
                    type="testing"
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    key={page}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box position="fixed" bottom={0} mb={2} display="flex" width={0.75} justifyContent="center">
            {questions && questions.length / numberOfItemsPerPage > 1 ? (
              <Pagination
                className={classes.pagination}
                color="primary"
                variant="outlined"
                size="large"
                showFirstButton
                showLastButton
                count={Math.ceil(questions.length / numberOfItemsPerPage)}
                page={page}
                onChange={(event, value) => {
                  setPage(value);
                }}
              />
            ) : null}
          </Box>
        </React.Fragment>
      ) : (
        <Alert severity="info">
          No mc questions exist for this case yet. Use the + button in the bottom right corner to add a new one.
        </Alert>
      )}
      {selectedCase ? (
        <React.Fragment>
          <Popover
            open={open}
            className={classes.popover}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Box width={600}>
              <MultilpeChoiceQuestionCreateCard
                onClose={handleClose}
                mode={mode}
                question={editQuestion}
                case_id={selectedCase.id}
                onSuccess={handleSucces}
              />
            </Box>
          </Popover>
          <Backdrop className={classes.backdrop} open={open}></Backdrop>

          <Tooltip title="Add new">
            <Fab className={classes.fab} color="primary" onClick={handleOpenPopover} ref={fabRef}>
              <AddIcon />
            </Fab>
          </Tooltip>
        </React.Fragment>
      ) : null}

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackBar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContentWrapper onClose={handleSnackbarClose} variant="success" message={snackBarMessage} />
      </Snackbar>
    </React.Fragment>
  );
}

export default MultipleChoiceTesting;
