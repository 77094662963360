import { Box, Button, Step, StepLabel, Stepper, Typography, LinearProgress, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import React from "react";
import GradingSchemesTable from "../gradingSchemes/GradingSchemesTable";
import PrescriptionsTable from "../prescriptions/PrescriptionsTable";
import Alert from "@material-ui/lab/Alert";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ["Select prescription(s)", "Select grading scheme", "Results"];
}

export default function PresciptionGradingStepper(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const case_id = props.match.params.case_id;
  const [prescriptions, setPrescriptions] = React.useState(null);
  const [chosenPrescriptions, setChosenPrescriptions] = React.useState([]);
  const [loadingPrescriptions, setLoadingPrescriptions] = React.useState(false);
  const [gradingScheme, setGradingScheme] = React.useState(null);
  const [gradedPrescriptions, setGradedPrescriptions] = React.useState([]);
  const [loadingGrading, setLoadingGrading] = React.useState(false);

  const handleChosenGradingScheme = (grading_scheme) => {
    setGradingScheme(grading_scheme === null ? null : grading_scheme.id);
  };

  const loadPrescriptions = () => {
    setLoadingPrescriptions(true);
    axios
      .get("apa/api/answers/prescriptions/", {
        params: {
          case_id: case_id,
          case_access_id: localStorage.getItem("case_acces_id"),
        },
      })
      .then((res) => {
        const data = res.data.map((prescription) => ({
          ...prescription,
          drug: prescription.drug ? prescription.drug.name : null,
          administration_form: prescription.administration_form ? prescription.administration_form.signature_form : null,
          strength: prescription.strength ? prescription.strength.name : null,
          grade: prescription.grading_result ? Math.round((prescription.grading_result.grade + Number.EPSILON) * 100) / 100 : null,
          created_at: new Date(prescription.created_at).toLocaleDateString(),
          modified_at: new Date(prescription.modified_at).toLocaleDateString(),
          owner: prescription.owner ? prescription.owner.username : null,
        }));

        setPrescriptions(data);
        setLoadingPrescriptions(false);
      })
      .catch((error) => {
        setPrescriptions([]);
        setLoadingPrescriptions(false);
      });
  };

  React.useEffect(() => {
    if (prescriptions == null && !loadingPrescriptions) {
      loadPrescriptions();
    }
  }, [loadPrescriptions]);

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <PrescriptionsTable
            case_id={case_id}
            mode={"answers"}
            selection
            prescriptions={prescriptions ? prescriptions : []}
            loading={loadingPrescriptions}
            onChoose={(data) => setChosenPrescriptions(data)}
          />
        );
      case 1:
        return <GradingSchemesTable case_id={case_id} mode={"choose"} onChoose={handleChosenGradingScheme} />;
      case 2:
        return loadingGrading ? (
          <Paper>
            <Box height={500}>
              <LinearProgress />
            </Box>
          </Paper>
        ) : (
          <PrescriptionsTable
            prescriptions={gradedPrescriptions}
            mode={"answers"}
            onEditGrade={(newData, oldData) => {
              const data = [...gradedPrescriptions];
              data[data.indexOf(oldData)] = newData;
              setGradedPrescriptions([...data]);
            }}
            graded
          />
        );
      default:
        return "Unknown stepIndex";
    }
  };

  const handleGrading = () => {
    setLoadingGrading(true);
    axios
      .post(
        "apa/api/grading/",
        { prescriptions: prescriptions.map((prescription) => prescription.id) },
        {
          params: {
            gradingscheme_id: gradingScheme,
          },
        }
      )
      .then((res) => {
        const data = res.data.map((prescription) => ({
          ...prescription,
          drug: prescription.drug ? prescription.drug.name : null,
          administration_form: prescription.administration_form ? prescription.administration_form.signature_form : null,
          strength: prescription.strength ? prescription.strength.name : null,
          grade: Math.round((prescription.grading_result.grade + Number.EPSILON) * 100) / 100,
        }));
        setLoadingGrading(false);
        setGradedPrescriptions(data);
      })
      .catch((error) => console.log(error));
  };

  const handleNext = () => {
    if (activeStep === 1) {
      handleGrading();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    switch (activeStep) {
      case 1:
        setChosenPrescriptions(null);
        break;
      case 2:
        setGradingScheme(null);
        setGradedPrescriptions(null);
        break;
      default:
        break;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setGradedPrescriptions(null);
    setGradingScheme(null);
    setChosenPrescriptions(null);
    setLoadingGrading(false);
    setActiveStep(0);
  };

  const renderNextButton = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <Button variant="contained" color="primary" onClick={handleNext} disabled={prescriptions === null ? true : false}>
            Next
          </Button>
        );
      case 1:
        return (
          <Button variant="contained" color="primary" onClick={handleNext} disabled={gradingScheme === null ? true : false}>
            Next
          </Button>
        );
      case 2:
        return (
          <React.Fragment>
            <Button onClick={handleReset} className={classes.backButton}>
              Reset
            </Button>
            <Button variant="contained" color="primary" onClick={() => alert("Not yet implemented!")} disabled>
              Send to Pscribe
            </Button>
          </React.Fragment>
        );
      default:
        return "Unknown stepIndex";
    }
  };

  return (
    <React.Fragment>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box>
        {localStorage.getItem("period") ? (
          <Box my={1}>
            <Alert severity="info">Answers are from the period: {localStorage.getItem("period")}</Alert>
          </Box>
        ) : null}
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography className={classes.instructions} variant="body1" gutterBottom>
              Completed grading!
            </Typography>
            <Button onClick={handleReset} className={classes.backButton}>
              Reset
            </Button>
            <Button variant="contained" color="primary">
              Return to Pscribe
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box my={2}>{getStepContent(activeStep)}</Box>
            <Box position="fixed" bottom={0} mb={2} display="flex" width={1}>
              <Box>
                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.backButton}>
                  Back
                </Button>
                {renderNextButton(activeStep)}
              </Box>
            </Box>
          </React.Fragment>
        )}
      </Box>
    </React.Fragment>
  );
}
