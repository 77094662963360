import { Backdrop, Box, Fab, Grid, Popover, Snackbar, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import SnackbarContentWrapper from "../utility/SnackbarContentWrapper";
import CaseCreateCard from "./CaseCreateCard";
import CasesTable from "./CasesTable";

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
    "&:hover": {
      color: "white",
    },
  },
  textField: {
    marginBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1, 2),
  },
  popover: {
    maxWidth: "1500",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function CaseDashboard() {
  // Dashboard for the cases, displays them in a table and contains a button to create new ones
  const classes = useStyles();
  const [editCase, setEditCase] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const fabRef = React.useRef();
  const [cardMode, setCardMode] = React.useState("default");
  // Update key is used to force a refresh on the table component
  const [updateKey, setUpdateKey] = React.useState(1);

  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");

  const handleClose = () => {
    setAnchorEl(null);
    setEditCase(null);
    setCardMode("default");
  };

  const handleOpenPopover = (event) => {
    setAnchorEl(fabRef.current);
  };
  const open = Boolean(anchorEl);

  const handleEdit = (_case) => {
    setEditCase(_case);
    setCardMode("edit");
    handleOpenPopover();
  };

  // Display success snackbar
  const handleSucces = () => {
    setUpdateKey(updateKey + 1);
    if (cardMode === "edit") {
      setSnackBarMessage("Succesfully edited case!");
    } else {
      setSnackBarMessage("Succesfully created case!");
    }
    setOpenSnackBar(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
    setSnackBarMessage("");
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs>
          <CasesTable onEdit={handleEdit} key={updateKey} />
        </Grid>
      </Grid>
      {/* Popover card to create a new case */}
      <Popover
        open={open}
        className={classes.popover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box width={600}>
          <CaseCreateCard onClose={handleClose} mode={cardMode} case={editCase} onSuccess={handleSucces} />
        </Box>
      </Popover>
      <Backdrop className={classes.backdrop} open={open}></Backdrop>

      {/* New case button */}
      <Tooltip title="Add new">
        <Fab className={classes.fab} color="primary" onClick={handleOpenPopover} ref={fabRef}>
          <AddIcon />
        </Fab>
      </Tooltip>

      {/* Snackbar for succes message */}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackBar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContentWrapper onClose={handleSnackbarClose} variant="success" message={snackBarMessage} />
      </Snackbar>
    </Box>
  );
}
