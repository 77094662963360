import React from "react";
import { Route } from "react-router-dom";
import CaseDashboard from "../cases/CasesDashboard";
import Dashboard from "../general/Dashboard";
import ApiExample from "../general/ApiExample";
import Help from "../general/Help";
import PrescriptionGradingStepper from "../grading/PrescriptionGradingStepper";
import MultipleChoiceGradingStepper from "../grading/MultipleChoiceQuestionGradingStepper";

import GradingScheme from "../gradingSchemes/GradingScheme";

import PrescriptionsTesting from "../prescriptions/PrescriptionsTesting";
import PsribeRedirect from "../general/PscribeRedirect";
import AdminDashboard from "../admin/AdminDashboard";
import AdminUsers from "../admin/Users";
import NotFound from "./NotFound";
import MultipleChoiceAssesment from "../questions/MultipleChoiceAssesment";
import OpenQuestionAssesment from "../questions/OpenQuestionAssesment";
import PrescriptionAssesment from "../prescriptions/PrescriptionAssesment";
import MultipleChoiceAnswers from "../questions/MultipleChoiceAnswers";
import OpenQuestionAnswers from "../questions/OpenQuestionAnswers";
import PrescriptionAnswers from "../prescriptions/PrescriptionAnswers";
import MultipleChoiceTesting from "../questions/MultipleChoiceTesting";
import OpenQuestionTesting from "../questions/OpenQuestionTesting";
import OpenQuestionGradingStepper from "../grading/OpenQuestionGradingStepper";
import Profile from "../users/Profile";
import UpdateProfile from "../users/UpdateProfile";
import PscribeRedirect from "../general/PscribeRedirect";

// Routes for the main application
const SiteRoutes = (props) => (
  <div>
    <Route exact path="/" component={Dashboard} />
    <Route exact path="/api/example" component={ApiExample} />
    <Route exact path="/redirect/:case_id/:token/:case_access_id" render={(props) => <PscribeRedirect connected {...props} />} />
    <Route exact path="/oqa/assesment/:case_id" component={OpenQuestionAssesment} />
    <Route exact path="/oqa/answers/:case_id" component={OpenQuestionAnswers} />
    <Route exact path="/oqa/testing" component={OpenQuestionTesting} />
    <Route exact path="/oqa/assesment/grade/:case_id" component={OpenQuestionGradingStepper} />
    <Route exact path="/mca/assesment/:case_id" component={MultipleChoiceAssesment} />
    <Route exact path="/mca/assesment/grade/:case_id" component={MultipleChoiceGradingStepper} />
    <Route exact path="/mca/answers/:case_id" component={MultipleChoiceAnswers} />
    <Route exact path="/mca/testing" component={MultipleChoiceTesting} />
    <Route exact path="/apa/assesment/:case_id" component={PrescriptionAssesment} />
    <Route exact path="/apa/assesment/grade/:case_id" component={PrescriptionGradingStepper} />
    <Route exact path="/apa/assesment/scheme/new/:case_id" render={(props) => <GradingScheme mode={"new"} {...props} />} />
    <Route exact path="/apa/assesment/scheme/:id" render={(props) => <GradingScheme mode={"details"} {...props} />} />
    <Route exact path="/apa/assesment/scheme/edit/:id" render={(props) => <GradingScheme mode={"edit"} {...props} />} />
    <Route exact path="/apa/answers/:case_id" component={PrescriptionAnswers} />
    <Route exact path="/apa/testing" component={PrescriptionsTesting} />

    <Route path="/help" component={Help} />
    <Route exact path="/cases/testing" component={CaseDashboard} />

    <Route path="/admin/dashboard" component={AdminDashboard} />
    <Route path="/admin/users" component={AdminUsers} />
    <Route exact path="/profile" component={Profile} />
    <Route exact path="/profile/update" component={UpdateProfile} />
    {/* <Route exact path="/404" component={NotFound} />
    <Redirect to="/404" /> */}
  </div>
);

export default SiteRoutes;
