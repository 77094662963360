import { Box, Breadcrumbs, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
// Map the route to title
const titleMap = {
  gradingschemes: "Grading schemes",
  cases: "Cases",
  prescriptions: "Prescriptions",
  grade: "Grading",
  help: "Help",
  pscribe: "Pscribe API",
  api: "API",
};
// Map title with subtitles
const subTitleMap = {
  gradingschemes: "available",
  choose_prescriptions: "choose prescriptions",
  example: "example",
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1, 2),
  },

  link: {
    color: theme.palette.grey[700],
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const parseTitleHeader = (pathnames) => {
  // Default title
  let title = "Dashboard";

  let subtitle = "";

  // Map titles if it is not on the default page
  if (pathnames.length > 0) {
    title = titleMap[pathnames[0]];
    if (pathnames.length > 1) {
      subtitle = subTitleMap[pathnames[1]];
    } else {
      subtitle = subTitleMap[title];
    }
  }

  return (
    <div>
      <Box display="flex" alignItems="flex-end" justifyContent="space-between">
        <Box display="flex" alignItems="flex-end">
          <Typography variant="h5">{title}</Typography>
          <Typography style={{ marginLeft: "4px" }} variant="subtitle1">
            {subtitle}
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  if (s === "mca" || s === "apa" || s === "oqa") return s.toUpperCase();
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export default function SiteBreadCrumbs(props) {
  // Breadcrumbs component display the interactable routing to the user.
  let location = useLocation();
  const classes = useStyles();
  let path_names = location.pathname.split("/").filter((x) => x);
  // Check if the last element is an id so it can be ignored
  if (/\d/.test(path_names[path_names.length - 1])) {
    const id = path_names.pop();
  }
  const case_id = localStorage.getItem("case_id");
  const [home, setHome] = React.useState("");
  React.useEffect(() => {
    if (home.length < 1) {
      loadHome();
    }
  });

  const loadHome = () => {
    axios
      .get(`apa/api/cases/${case_id}`)
      .then((res) => {
        setHome(res.data.title);
      })
      .catch((error) => {
        setHome("Home");
        console.log(error);
      });
  };

  return (
    <Paper elevation={0} className={classes.paper}>
      {/* Parse the title form the pathnames */}
      {/* {parseTitleHeader(path_names)} */}

      <Breadcrumbs aria-label="breadcrumb">
        <Link className={classes.link} color="inherit" to="/" key="/">
          {home}
        </Link>
        {/* Map the pathnames to links  */}
        {path_names.map((value, index) => {
          const last = index === path_names.length - 1;
          const to = `/${path_names.slice(0, index + 1).join("/")}/${case_id ? case_id : null}`;
          return last ? (
            <Typography color="textPrimary" key={to}>
              {capitalize(value)}
            </Typography>
          ) : (
            <Link className={classes.link} color="inherit" to={to} key={to}>
              {capitalize(value)}
            </Link>
          );
        })}
      </Breadcrumbs>
    </Paper>
  );
}
