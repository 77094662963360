import { Box, Button, Card, CardActions, CardContent, IconButton, makeStyles, TextField, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import React from "react";
import SelectList from "../utility/SelectList";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(1),
  },
  description: {
    fontSize: 14,
  },
}));

function CaseCreateCard(props) {
  const classes = useStyles();

  const [indications, setIndications] = React.useState([]);
  const [loadingIndications, setLoadingIndications] = React.useState(false);
  const [selectedIndications, setSelectedIndications] = React.useState([]);

  const [state, setState] = React.useState({
    author: "",
    title: "",
    description: "",
    organisation: "",
  });

  React.useEffect(() => {
    if (props.case) {
      setState({
        ...state,
        ...props.case,
      });
      loadIndications(props.case.indications);
    } else if (indications.length == 0 && !loadingIndications) {
      loadIndications();
    }
  }, [props.case]);

  const handleInputChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const handleSubmit = () => {
    props.onClose();
    let data = { ...state };
    data.indications = selectedIndications;

    if (props.mode == "edit") {
      axios.put(`apa/api/cases/${state.id}/`, data).then(
        (response) => {
          props.onSuccess();
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      axios.post("apa/api/cases/", data).then(
        (response) => {
          props.onSuccess();
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  const loadIndications = (default_indications) => {
    setLoadingIndications(true);
    axios
      .get("apa/api/idm/indications")
      .then((res) => {
        setIndications(res.data);
        setLoadingIndications(false);
        if (default_indications) {
          setSelectedIndications(default_indications.map((indication) => res.data.find((e) => e.name == indication.name)));
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5" component="h2">
            {props.mode === "edit" ? "Edit" : "New"} case
          </Typography>
          <IconButton onClick={props.onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
        <form>
          <TextField name="title" className={classes.textField} fullWidth label="Title" value={state.title} onChange={handleInputChange} />
          <Box display="flex" justifyContent="space-between">
            <TextField name="author" className={classes.textField} label="Author" value={state.author} onChange={handleInputChange} />
            <TextField
              name="organisation"
              className={classes.textField}
              label="Organisation"
              value={state.organisation}
              onChange={handleInputChange}
            />
          </Box>

          <Box my={1}>
            <SelectList
              multiple
              options={indications.sort(
                (a, b) => -b.name.match("[a-zA-Z]").pop().toUpperCase().localeCompare(a.name.match("[a-zA-Z]").pop().toUpperCase())
              )}
              onChange={(event, newValue) => {
                setSelectedIndications(newValue);
              }}
              size="small"
              value={selectedIndications}
              // ListboxProps={{ style: { maxHeight: "16rem" } }}
              groupBy={(option) => option.name.match("[a-zA-Z]").pop().toUpperCase()}
              getOptionLabel={(option) => option.name}
              loading={loadingIndications}
              renderInput={(params) => <TextField {...params} label="Indication(s)" />}
            />
          </Box>

          <TextField
            name="description"
            className={classes.textField}
            fullWidth
            multiline
            rows="10"
            label="Description"
            value={state.description}
            onChange={handleInputChange}
            InputProps={{
              classes: {
                input: classes.description,
              },
            }}
          />
        </form>
      </CardContent>

      <CardActions>
        <Button onClick={props.onClose} color="primary">
          Cancel
        </Button>
        <Button color="primary" onClick={handleSubmit}>
          {props.mode === "edit" ? "Save" : "Submit"}
        </Button>
      </CardActions>
    </Card>
  );
}

export default CaseCreateCard;
