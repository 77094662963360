import React from "react";
import PropTypes from "prop-types";
import GradingSchemesTable from "../gradingSchemes/GradingSchemesTable";
import { Grid, Paper, Typography, Fab, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
PrescriptionAssesment.propTypes = {};

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
    "&:hover": {
      color: "white",
    },
  },
  paper: {
    padding: theme.spacing(1, 2),
  },
}));

function PrescriptionAssesment(props) {
  const classes = useStyles();
  const case_id = props.match.params.case_id;
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs>
          <GradingSchemesTable case_id={case_id} />
        </Grid>
      </Grid>

      <Tooltip title="Add">
        <Fab
          color="primary"
          aria-label="add"
          component={(props) => <Link to={`/apa/assesment/scheme/new/${case_id}`} {...props} />}
          className={classes.fab}
        >
          <AddIcon />
        </Fab>
      </Tooltip>
    </React.Fragment>
  );
}

export default PrescriptionAssesment;
