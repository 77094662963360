import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import registerServiceWorker from "./serviceWorker";
import { unregister as unregisterServiceWorker } from "./serviceWorker";
import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { BrowserRouter } from "react-router-dom";
import auth_reducer from "./store/reducers/auth";
import { reducer as formReducer } from "redux-form";
import axios from "axios";
import Cookies from "js-cookie";

//React index file
//Starting point of the application

// Redux setup
const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers_combined = combineReducers({ auth: auth_reducer, form: formReducer });

const store = createStore(reducers_combined, composeEnhances(applyMiddleware(thunk)));

// Axios defaults setup so that this only needs to be defined once for the requests
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.headers.common["Authorization"] = `Token ${Cookies.get("token")}`;

if (process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = "http://localhost:8000/";
} else {
  axios.defaults.baseURL = "https://pharmagrader.com/";
}

// Redirects to any non www host to www
const isLocalHost = (hostname) =>
  !!(hostname === "localhost" || hostname === "[::1]" || hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/));

if (typeof window !== "undefined" && window.location && window.location.protocol === "http:" && !isLocalHost(window.location.hostname)) {
  let redirectUrl = window.location.href.replace(/^http(?!s)/, "https");
  if (window.location.hostname.indexOf("www") === 0) {
    //redirectUrl = window.location.href.replace("www.", "");
  }
  window.location.href = redirectUrl;
} else if (window.location.hostname.indexOf("www") === 0) {
  window.location.href = window.location.href.replace("www.", "");
}
//Starting point of the react app using a browsertouer for routing and the provider for redux
const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

//Render the application
ReactDOM.render(app, document.getElementById("root"));
unregisterServiceWorker();
