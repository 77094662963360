import axios from "axios";
import * as actionTypes from "./actionTypes";
import Cookies from "js-cookie";
import { useHistory, Link } from "react-router-dom";

export const authStart = () => {
  // Starts the authentication by calling the AUTH_START action.
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (token, username, group) => {
  // Calls the AUTH_SUCCESS action and sets the token to the action token.
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token,
    username: username,
    group: group,
  };
};

export const authFail = () => {
  // Calls AUTH_FAIL action and sets error to the corresponding action error.
  return {
    type: actionTypes.AUTH_FAIL,
    error: true,
  };
};

export const logout = () => {
  // Logs out the user. Removes the token from cookies and the username + group from local storage. Returns the AUTH_LOGOUT action.
  Cookies.remove("token");
  localStorage.removeItem("username");
  localStorage.removeItem("group");
  // localStorage.removeItem("case_access_id");
  // localStorage.removeItem("period");
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const authLogin = (username, password) => {
  // Logs in the user.
  return (dispatch) => {
    dispatch(authStart());

    delete axios.defaults.headers.common["Authorization"];
    axios

      .post("apa/rest-auth/login/", {
        // Sends a post request to the rest auth.
        username: username,
        password: password,
      })
      .then((res) => {
        const token = res.data.key;
        //console.log(res.data)
        // Check here if tokens exists: if not: return error msg

        axios.defaults.headers.common["Authorization"] = `Token ${token}`;
        // Use token authorization.
        Cookies.set("token", token, { expires: 7 });
        //localStorage.setItem("username", res.data.name);
        localStorage.setItem("id", res.data.user);
        // Set the cookies token and an experiation data of 7 days.
        dispatch(authSuccess(token, username, null));
        dispatch(authCheckState());
      })
      .catch((err) => {
        dispatch(authFail(err));
      });
  };
};

export const getUsername = () => {
  // Returns the username from localStorage.
  const username = localStorage.getItem("username");
  return username;
};

export const getUserID = () => {
  // Returns the user ID from localStorage.
  const id = localStorage.getItem("id");
  return id;
};

export const authSignup = (username, email, first_name, last_name, password1, password2) => {
  return (dispatch) => {
    dispatch(authStart());

    delete axios.defaults.headers.common["Authorization"];
    axios
      .post("apa/rest-auth/registration/", {
        username: username,
        first_name: first_name,
        last_name: last_name,
        email: email,
        password1: password1,
        password2: password2,
        group: "standard",
      })
      .then((res) => {
        const token = res.data.key;
        const id = res.data.user;
        const name = username;
        axios.defaults.headers.common["Authorization"] = `Token ${token}`;
        Cookies.set("token", token, { expires: 7 });

        localStorage.setItem("username", name);
        localStorage.setItem("id", id);
        dispatch(authSuccess(token));
      })
      .catch((err) => {
        dispatch(authFail(err));
      });
  };
};

export const authUpdate = (username, first_name, last_name) => {
  return (dispatch) => {
    dispatch(authStart());

    delete axios.defaults.headers.common["Authorization"];
    axios
      .patch("apa/auth/users/" + localStorage.getItem("id") + "/", {
        username: username,
        first_name: first_name,
        last_name: last_name,
      })
      .then((res) => {
        const token = res.data.key;
        const id = res.data.user;
        const name = username;
        axios.defaults.headers.common["Authorization"] = `Token ${token}`;

        localStorage.setItem("username", name);
        dispatch(authSuccess(token));
        let history = useHistory();
        history.push("/");
      })
      .catch((err) => {
        dispatch(authFail(err));
      });
  };
};

export const authConnect = (token, username, group) => {
  return (dispatch) => {
    dispatch(authSuccess(token, username, group));
    let history = useHistory();
    history.push("/");
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const token = Cookies.get("token");
    dispatch(authStart());

    if (!token) {
      dispatch(logout());
    } else {
      axios.defaults.headers.common["Authorization"] = `Token ${token}`;
      axios
        .get("apa/auth/users/current_user/", {})
        .then((res) => {
          // Succesfully logged in using token
          localStorage.setItem("username", res.data.username);
          localStorage.setItem("group", res.data.groups[0].name);
          dispatch(authSuccess(token, res.data.username, res.data.groups[0].name));
        })
        .catch((err) => {
          // Failed logging in using token
          dispatch(logout());
          console.log(err);
        });
    }
  };
};

export const authResetPassword = (email) => {};
