import { TextField, Typography, Grid, Paper, Box } from "@material-ui/core";
import MaterialTable from "../utility/MaterialTable";
import axios from "axios";
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import OpenQuestionCard from "../questions/OpenQuestionCard";
import OpenQuestionTable from "./OpenQuestionsTable";
import SelectCase from "../general/SelectCase";

OpenQuestionTesting.propTypes = {};

function OpenQuestionTesting(props) {
  const [selectedCase, setSelectedCase] = React.useState(null);

  const [selectedQuestion, setSelectedQuestion] = React.useState(null);

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={7}>
          <Box mb={2}>
            <Paper>
              <SelectCase
                onChange={(newValue) => {
                  setSelectedCase(newValue);
                }}
                default
                variant="outlined"
              />
            </Paper>
          </Box>

          {selectedCase ? (
            <OpenQuestionTable
              case_id={selectedCase.id}
              mode="testing"
              key={selectedCase.id}
              onSelectQuestion={(question) => setSelectedQuestion(question)}
            />
          ) : null}
        </Grid>
        <Grid item xs={5}>
          {selectedQuestion ? <OpenQuestionCard question={selectedQuestion} key={selectedQuestion.id} type="answers" editable /> : null}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default OpenQuestionTesting;
