import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Fab, Tooltip } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { Link } from "react-router-dom";
import CheckIcon from "@material-ui/icons/Check";
import OpenQuestionCard from "../questions/OpenQuestionCard";
import axios from "axios";
OpenQuestionsGraded.propTypes = { questions: Array.isRequired };

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1, 2),
  },
  pagination: {
    marginRight: "auto !important",
    marginLeft: "auto !important",
    marginTop: "8px",
  },
}));

function OpenQuestionsGraded(props) {
  const classes = useStyles();

  const [state, setState] = React.useState({ open_questions: null });
  const [page, setPage] = React.useState(1);
  const numberOfItemsForPage = 2;

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <React.Fragment>
      {props.questions ? (
        <React.Fragment>
          <Grid container spacing={3} justify="center" style={{ height: 550 }}>
            {props.questions.slice((page - 1) * numberOfItemsForPage, page * numberOfItemsForPage).map((question) => (
              <Grid item xs={6}>
                <OpenQuestionCard question={question} type="graded" key={page} />
              </Grid>
            ))}
          </Grid>
          <Box position="fixed" bottom={0} mb={2} display="flex" width={0.75} justifyContent="center">
            <Pagination
              className={classes.pagination}
              color="primary"
              variant="outlined"
              size="large"
              showFirstButton
              showLastButton
              count={Math.ceil(props.questions.length / numberOfItemsForPage)}
              page={page}
              onChange={handleChange}
            />
          </Box>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
}

export default OpenQuestionsGraded;
