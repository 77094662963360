import React from "react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { LinearProgress, Box, Card, CardContent, CssBaseline, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { autofill } from "redux-form";
import { connect } from "react-redux";
import * as actions from "../../store/actions/auth";

const useStyles = makeStyles((theme) => ({
  card: {
    margin: "auto",
    width: "17rem",
    height: "17rem",
    opacity: ".8",
  },
  progress: {
    marginTop: "14px",
    margin: "auto",
    display: "block",
  },
  text: {
    marginTop: "14px",
    marginLeft: "14px",
    display: "block",
  },
}));

const PscribeRedirect = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  let history = useHistory();

  React.useEffect(() => {
    if (props.connected) {
      history.push("/");
    }
    if (!loading && props.match.params.case_id && props.match.params.token) {
      setLoading(true);
      setTimeout(connect, (Math.random() * (3000 - 500 + 1)) << 0);
    }
  });

  const connect = () => {
    localStorage.setItem("case_id", props.match.params.case_id);

    if ("case_access_id" in props.match.params) {
      localStorage.setItem("case_access_id", props.match.params.case_access_id);
      axios
        .get("apa/api/periods/" + props.match.params.case_access_id)
        .then((res) => {
          // Succesfully logged in using token
          localStorage.setItem("period", res.data.period);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    const token = props.match.params.token;
    Cookies.set("token", props.match.params.token);

    axios.defaults.headers.common["Authorization"] = `Token ${token}`;
    axios
      .get("apa/auth/users/current_user/", {}, { timeout: 2000 })
      .then((res) => {
        // Succesfully logged in using token
        localStorage.setItem("username", res.data.username);
        localStorage.setItem("group", res.data.groups[0].name);
        props.connect(token, res.data.username, res.data.groups[0].name);
        setTimeout(function () {
          alert("Hello");
        }, 3000);
        history.push("/");
      })
      .catch((err) => {
        // Failed logging in using token
        console.log(err);
      });
    axios
      .get("apa/auth/users/current_user/", {}, { timeout: 2000 })
      .then((res) => {
        // Succesfully logged in using token
        localStorage.setItem("username", res.data.username);
        localStorage.setItem("group", res.data.groups[0].name);
        props.connect(token, res.data.username, res.data.groups[0].name);
        setTimeout(function () {
          alert("Hello");
        }, 3000);
        history.push("/");
      })
      .catch((err) => {
        // Failed logging in using token
        console.log(err);
      });
  };
  return (
    <Box pt={24} display="block">
      <Card className={classes.card}>
        <CardContent>
          <CircularProgress className={classes.progress} size="10rem" thickness="1" />

          <Typography className={classes.text} variant="overline" gutterBottom>
            Connecting from pscribe...
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    connect: (token, username, group) => dispatch(actions.authConnect(token, username, group)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PscribeRedirect);
