import React from "react";
import PropTypes from "prop-types";
import { TextField, Typography, Grid, Paper, Box } from "@material-ui/core";
import axios from "axios";
import SelectList from "../utility/SelectList";

SelectCase.propTypes = {
  onChange: PropTypes.func.isRequired,
};

function SelectCase(props) {
  const [caseOptions, setCaseOptions] = React.useState([]);
  const [selectedCase, setSelectedCase] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const loadCases = () => {
    setLoading(true);
    axios
      .get("apa/api/cases/")
      .then((res) => {
        setCaseOptions(res.data);
        if (props.default) {
          const case_id = localStorage.getItem("case_id");
          if (case_id) {
            const matched_case = res.data.find((_case) => _case.id == case_id);

            setSelectedCase(matched_case);
            props.onChange(matched_case);
          }
        }
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  React.useEffect(() => {
    if (!caseOptions.length && !loading.cases) {
      loadCases();
    }
  }, [loadCases]);

  return (
    <SelectList
      options={caseOptions}
      onChange={(event, newValue) => {
        setSelectedCase(newValue);
        props.onChange(newValue);
      }}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Typography variant="body2" display="block" noWrap>{`${option.author} ${option.organisation} - ${option.title}`}</Typography>
        </React.Fragment>
      )}
      size="medium"
      value={selectedCase}
      getOptionLabel={(option) => option.title}
      loading={loading.cases}
      renderInput={(params) => <TextField {...params} variant={props.variant} label="Case" />}
    />
  );
}

export default SelectCase;
