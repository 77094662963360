import { Avatar, Button, Card, CardContent, CssBaseline, Grid, Link, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import React from "react";
import { connect } from "react-redux";
import RouterLink from "react-router-dom/Link";
import { Field, reduxForm } from "redux-form";
import * as actions from "../../store/actions/auth";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  card: {
    margin: "auto",
    width: "25rem",
  },
}));

const renderTextField = ({ label, input, meta: { touched, invalid, error }, ...custom }) => <TextField label={label} placeholder={label} error={touched && invalid} helperText={touched && error} variant="outlined" fullWidth {...input} {...custom} />;

function Login(props) {
  const submitAuth = (values, event, props) => {
    props.onAuth(values.username, values.password);
  };

  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
       
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>

          <form className={classes.form} onSubmit={props.handleSubmit(submitAuth)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div>
                  <Field name="username" label="Username" component={renderTextField} />
                </div>
              </Grid>
              <Grid item xs={12}>
                <Field name="password" component={renderTextField} type="password" label="Password" />
              </Grid>

              <Grid item xs={12}>
                {props.errorstate ? (
                  <Typography color="error" variant="subtitle1">
                    Failed to login
                  </Typography>
                ) : null}
              </Grid>
            </Grid>

            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
              Login
            </Button>
            <Grid container justify="flex-end">
              <Grid item>
                <Link component={RouterLink} to="/signup" variant="body2">
                  Don't have an account? Sign up
                </Link>
              </Grid>
              
            </Grid>
            <Grid container justify="flex-end">
              <Grid item>
                <Link component={RouterLink} to="/resetpassword" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              
            </Grid>
          </form>
        </div>
      </CardContent>
    </Card>
  );
}

Login = reduxForm({
  // a unique name for the form
  form: "login",
})(Login);

const mapStateToProps = (state) => {
  return {
    errorstate: state.auth.error,
    error: state.auth.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (username, password) => dispatch(actions.authLogin(username, password)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
