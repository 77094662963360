import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { Typography } from "@material-ui/core";

export default function ComfirmDeleteDialog(props) {
  const { onClose, open, ...other } = props;

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="xs" open={open} {...other}>
      <DialogTitle id="confirmation-dialog-title">Are you sure you want to delete?</DialogTitle>
      <DialogContent>
        <Typography variant="body2" gutterBottom>
          Warning, this cannot be{" "}
          <Box display="inline" color="error.main" fontWeight="fontWeightBold">
            <u>undone!</u>
          </Box>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={props.handleOk} color="secondary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ComfirmDeleteDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleOk: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};
