import { Avatar, Button, Card, CardContent, CssBaseline, Grid, TextField, Typography, Box, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import React from "react";
import { connect } from "react-redux";
import RouterLink from "react-router-dom/Link";
import { Field, reduxForm } from "redux-form";
import * as actions from "../../store/actions/auth";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  card: {
    margin: "auto",
    width: "25rem",
  },
}));

// const renderTextField = ({defaultValue, label, input, meta: { touched, invalid, error }, ...custom }) =>
// <TextField value={input.value} defaultValue={defaultValue} label={label} placeholder={label} error={touched && invalid} helperText={touched && error} variant="outlined" fullWidth {...input} {...custom} />;
const renderTextField = ({ label, input, meta: { touched, invalid, error }, ...custom }) => (
  <TextField
    label={label}
    placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom}
    variant="outlined"
    fullWidth
    {...input}
  />
);
function Profile(props) {
  const submitAuth = (values, event, props) => {
    props.onAuth(values.username, values.password);
  };

  //id = localStorage.getItem("id")
  const [state, setState] = React.useState({
    username: "",
    email: "",
    first_name: "",
    last_name: "",
    password: "",
    role: "",
  });

  const GetUserInfo = () => {
    axios.get("apa/auth/users/" + localStorage.getItem("id")).then((res) => {
      const data = res.data;
      //setState({ ...state, data });
      setState({
        username: data.username,
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        role: data.groups[0].name,
      });
    });
  };

  React.useEffect(GetUserInfo, []);

  const classes = useStyles();

  return (
    <Container maxWidth="md">
      <Avatar className={classes.blue}></Avatar>

      <Grid container spacing={2}>
        <Grid item xs={2}>
          <p>Username</p>
        </Grid>
        <Grid item xs={10}>
          <Field name="username" label={state.username} component={renderTextField} disabled={true} />
          {/* <Field name="username"  value={state.username} component="input" type="text" disabled={true}/> */}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <p>Email</p>
        </Grid>
        <Grid item xs={10}>
          <Field name="email" label={state.email} component={renderTextField} disabled={true} />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={2}>
          <p>First name</p>
        </Grid>
        <Grid item xs={10}>
          <Field name="firstname" label={state.first_name} component={renderTextField} disabled={true} />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={2}>
          <p>Last name</p>
        </Grid>
        <Grid item xs={10}>
          <Field name="lastname" label={state.last_name} component={renderTextField} disabled={true} />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={2}>
          <p>Role</p>
        </Grid>
        <Grid item xs={10}>
          <Field name="role" label={state.role} component={renderTextField} disabled={true} />
        </Grid>
      </Grid>

      <Button component={Link} to="/profile/update" fullWidth variant="contained" color="primary" className={classes.submit}>
        Update info
      </Button>
    </Container>
  );
  return (
    <Card className={classes.card}>
      <CardContent>
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>

          <form className={classes.form} onSubmit={props.handleSubmit(submitAuth)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div>
                  <Field name="username" label="Username" component={renderTextField} />
                </div>
              </Grid>
              <Grid item xs={12}>
                <Field name="password" component={renderTextField} type="password" label="Password" />
              </Grid>

              <Grid item xs={12}>
                {props.errorstate ? (
                  <Typography color="error" variant="subtitle1">
                    Failed to login
                  </Typography>
                ) : null}
              </Grid>
            </Grid>

            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
              Login
            </Button>
            <Grid container justify="flex-end">
              <Grid item>
                <Link component={RouterLink} to="/signup" variant="body2">
                  Don't have an account? Sign up
                </Link>
              </Grid>
            </Grid>
            <Grid container justify="flex-end">
              <Grid item>
                <Link component={RouterLink} to="/resetpassword" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </CardContent>
    </Card>
  );
}

Profile = reduxForm({
  // a unique name for the form
  form: "update",
})(Profile);

const mapStateToProps = (state) => {
  return {
    errorstate: state.auth.error,
    error: state.auth.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (username, password) => dispatch(actions.authLogin(username, password)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);

//   const GetUserInfo = () => {
//     // Loads the users from the backend into the state
//     setLoading(true);
//     id = localStorage.getItem("id")
//     axios
//       .get("/auth/user/" + id)
//       .then((res) => {
//         const data = res.data.map((user) => ({
//           ...user,
//           group: user.groups[0].name,
//           date_joined: new Date(user.date_joined).toLocaleDateString(),
//           last_login: new Date(user.last_login).toLocaleString(),
//         }));
//         setState({ ...state, data });
//         setLoading(false);
//       })
//       .catch((error) => console.log(error));
//   };
