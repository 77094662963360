import { Box, makeStyles, Paper, Typography } from "@material-ui/core";
import axios from "axios";
import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1, 2),
  },
}));

export default function Case(props) {
  // Card that represents a case
  const classes = useStyles();
  // Card data
  const [_case, setCase] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const loadCase = (case_id) => {
    // Load the case data from the backend
    setLoading(true);
    axios
      .get(`apa/api/cases/${case_id}`)
      .then((res) => {
        delete res.data.prescriptions;
        let loaded_case = { ...res.data };
        // Set the indication and drug group names
        loaded_case.indications = loaded_case.indications.map((indication) => indication.name).join(", ");
        setCase({ ...loaded_case });
        setLoading(false);
      })
      .catch((error) => {
        if ("onError" in props) {
          props.onError();
        }

        console.log(error);
      });
  };

  React.useEffect(() => {
    // Load a case from the provided id from the props
    if (_case == null && !loading) {
      if (props.case) {
        setCase({ ...props.case });
      } else {
        loadCase(props.id);
      }
    }
  }, [loadCase]);

  return (
    <React.Fragment>
      {_case != null ? (
        <Paper className={classes.paper}>
          <Typography variant="h6">{_case.title}</Typography>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="subtitle1" gutterBottom>
              <Box fontWeight="fontWeightBold">{_case.indications}</Box>
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              <Box fontWeight="fontWeightMedium">
                {_case.author} - {_case.organisation}
              </Box>
            </Typography>
          </Box>

          <Typography variant="body2" style={{ whiteSpace: "pre-line" }} gutterBottom>
            {_case.description}
          </Typography>
        </Paper>
      ) : (
        <Skeleton variant="rect" height={300} />
      )}
    </React.Fragment>
  );
}
