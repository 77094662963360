import { Backdrop, Box, Fab, Grid, Popover, Snackbar, Tooltip, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import SnackbarContentWrapper from "../utility/SnackbarContentWrapper";
import PrescriptionCreateCard from "./PrescriptionCreateCard";
import PrescriptionsTable from "./PrescriptionsTable";
import SelectCase from "../general/SelectCase";
import axios from "axios";
const useStyles = makeStyles((theme) => ({
  fab: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
    "&:hover": {
      color: "white",
    },
  },
  paper: {
    padding: theme.spacing(1, 2),
  },
  popover: {
    maxWidth: "1500",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function PrescriptionsTesting(props) {
  const classes = useStyles();
  const [editPrescription, setEditPrescription] = React.useState(null);
  const [prescriptions, setPrescriptions] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const fabRef = React.useRef();
  const [cardMode, setCardMode] = React.useState("default");

  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");

  const [selectedCase, setSelectedCase] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const handleClose = () => {
    setAnchorEl(null);
    setEditPrescription(null);
    setCardMode("default");
  };

  const handleOpenPopover = (event) => {
    setAnchorEl(fabRef.current);
  };
  const open = Boolean(anchorEl);

  const handleEdit = (prescription) => {
    setEditPrescription(prescription);
    setCardMode("edit");
    handleOpenPopover();
  };

  const handleSucces = (prescription) => {
    if (cardMode === "edit") {
      const data = [...prescriptions];
      data[data.indexOf(editPrescription)] = prescription;
      setPrescriptions([...data]);
      setSnackBarMessage("Succesfully edited prescription!");
    } else {
      setPrescriptions([...prescriptions, { ...prescription }]);

      setSnackBarMessage("Succesfully created prescription!");
    }
    setOpenSnackBar(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
    setSnackBarMessage("");
  };

  const loadPrescriptions = (case_id) => {
    setLoading(true);
    axios
      .get("apa/api/answers/prescriptions/", {
        params: {
          case_id: case_id,
        },
      })
      .then((res) => {
        const data = res.data.map((prescription) => ({
          ...prescription,
          drug: prescription.drug ? prescription.drug.name : null,
          administration_form: prescription.administration_form ? prescription.administration_form.signature_form : null,
          strength: prescription.strength ? prescription.strength.name : null,
          grade: prescription.grading_result ? Math.round((prescription.grading_result.grade + Number.EPSILON) * 100) / 100 : null,
          created_at: new Date(prescription.created_at).toLocaleDateString(),
          modified_at: new Date(prescription.modified_at).toLocaleDateString(),
          owner: prescription.owner ? prescription.owner.username : null,
        }));

        setPrescriptions(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Paper>
            <SelectCase
              onChange={(newValue) => {
                setSelectedCase(newValue);
                loadPrescriptions(newValue.id);
              }}
              default
              variant="outlined"
            />
          </Paper>
        </Grid>
        <Grid item xs>
          {selectedCase ? (
            <PrescriptionsTable
              mode="testing"
              onEdit={handleEdit}
              onDelete={(prescription) => {
                const data = [...prescriptions];
                data.splice(data.indexOf(prescription), 1);
                setPrescriptions(data);
              }}
              prescriptions={prescriptions}
              loading={loading}
            />
          ) : null}
        </Grid>
      </Grid>

      {selectedCase ? (
        <React.Fragment>
          <Popover
            open={open}
            className={classes.popover}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Box width={600}>
              <PrescriptionCreateCard
                onClose={handleClose}
                mode={cardMode}
                prescription={editPrescription}
                case_id={selectedCase.id}
                onSuccess={handleSucces}
              />
            </Box>
          </Popover>
          <Backdrop className={classes.backdrop} open={open}></Backdrop>

          <Tooltip title="Add new">
            <Fab className={classes.fab} color="primary" onClick={handleOpenPopover} ref={fabRef}>
              <AddIcon />
            </Fab>
          </Tooltip>
        </React.Fragment>
      ) : null}

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackBar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContentWrapper onClose={handleSnackbarClose} variant="success" message={snackBarMessage} />
      </Snackbar>
    </Box>
  );
}

export default PrescriptionsTesting;
