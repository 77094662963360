import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Grid,
  Snackbar,
  Switch,
  Tooltip,
} from "@material-ui/core";
import axios from "axios";
import React from "react";
import ReactJson from "react-json-view";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../store/actions/auth";
import SelectCase from "./SelectCase";
import HelpOutline from "@material-ui/icons/HelpOutline";
import SnackbarContentWrapper from "../utility/SnackbarContentWrapper";
function ApiExample(props) {
  let history = useHistory();
  const [redirect, setRedirect] = React.useState(true);
  const [useExistingUser, setUseExistingUser] = React.useState(false);
  const [selectedCase, setSelectedCase] = React.useState(null);
  const [openErrorMessage, setOpenErrorMessage] = React.useState(false);

  const [possibleOpenQuestions, setPossibleOpenQuestions] = React.useState([]);

  const [possibleMCQuestions, setPossibleMCQuestions] = React.useState([]);
  function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
  }

  function createIDArray(n, max) {
    var arr = [];
    while (arr.length < n) {
      var r = Math.floor(Math.random() * max) + 1;
      if (arr.indexOf(r) === -1) arr.push(r);
    }
    return arr;
  }

  let indications = ["Anticonceptie", "Groeistoornissen", "Tonsillitis", "Sinusitis", "Prostatitis", "Hypertensie", "ADHD"];
  shuffleArray(indications);
  let names = ["John Doe", "Mary Jane", "Bill Gates", "Jeff Bezos", "Sundar Pichai", "Larry Page", "Elon Musk"];
  let organisations = ["UMCG", "Erasmus MC", "VUMC", "AZM", "Farm_RuG"];
  shuffleArray(organisations);

  function random(max) {
    return Math.floor(Math.random() * max + 1);
  }

  const _case = {
    title: `API Test Case ${new Date().toJSON().replace(/-/g, "/")} `,
    indications: indications.slice(0, Math.floor(Math.random() * 2) + 1),
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer lobortis ante eu nisl consequat, eget posuere eros cursus. Aenean consequat ex sed nulla rutrum aliquet. Phasellus ut nibh vitae purus ultrices consequat et nec augue. Aliquam erat volutpat. Suspendisse tristique tincidunt nisl et hendrerit. Ut diam est, cursus sit amet felis non, eleifend egestas eros. Nulla luctus velit risus, gravida imperdiet tellus aliquet sed. Praesent mollis ac turpis et accumsan. Integer sit amet auctor nisl. Suspendisse potenti. Nunc ac tincidunt felis. Proin sed libero feugiat felis semper aliquet vel a arcu. Phasellus eu nulla nisl. ",
    author: names[Math.floor(Math.random() * names.length)],
    organisation: organisations[Math.floor(Math.random() * organisations.length)],
    source: "pscribe",
  };

  const open_question_count = 5;
  const open_ids = createIDArray(open_question_count, 10000);
  const open_questions = open_ids.map((item, index) => ({
    question: `Question ${item}`,
    question_id: item,
    index: index,
    stepIndex: random(3),
    parentStepIndex: random(3),
  }));

  const open_answers = Array(10)
    .fill()
    .map((item) => ({
      answer: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ac urna vel ex vehicula rhoncus vel in mauris. Sed auctor eu risus vel scelerisque. Suspendisse ac eros a turpis eleifend maximus. Maecenas dictum mi quis lectus euismod eleifend. Maecenas eu est ut diam vehicula ultrices. Curabitur ac efficitur lacus. Nullam. `,
      question_id: open_ids[Math.floor(Math.random() * open_question_count)],
      student_id: random(10000),
    }));

  const mc_question_count = 5;
  const mc_ids = createIDArray(mc_question_count, 10000);
  const mc_questions = mc_ids.map((item, index) => ({
    question: `Question ${item}`,
    question_id: item,
    index: index,
    stepIndex: random(3),
    ParentStepIndex: random(3),
    choices: Array(Math.ceil(Math.random() * (4 - 1) + 1))
      .fill()
      .map((_, i) => "Option " + i),
  }));

  const mc_answers = Array(10)
    .fill()
    .map((item) => ({
      answer: Math.floor(Math.random() * 1),
      question_id: mc_ids[Math.floor(Math.random() * mc_question_count)],
      student_id: random(10000),
    }));

  const drug_data = [
    { drug: "paracetamol", administration_form: "Tablet", strength: "100 mg" },
    { drug: "paracetamol", administration_form: "Tablet", strength: "500 mg" },
    { drug: "paracetamol", administration_form: "Tablet", strength: "1000 mg" },
    { drug: "paracetamol", administration_form: "Zetpil", strength: "120 mg" },
    { drug: "paracetamol", administration_form: "Zetpil", strength: "250 mg" },
    { drug: "paracetamol", administration_form: "Zetpil", strength: "1000 mg" },
    { drug: "paracetamol", administration_form: "Infusievloeistof", strength: "10 mg/ml" },
  ];
  const prescriptions = drug_data.map((item) => ({
    student_id: random(10000),
    drug: item.drug,
    administration_form: item.administration_form,
    strength: item.strength,
    therapy_unit: "days",
    intake_unit: "daily",
    total_quantity: Math.floor(Math.random() * 30),
    quantity: Math.floor(Math.random() * 3) + 1,
    frequency: Math.floor(Math.random() * 3) + 1,
    therapy_time: Math.floor(Math.random() * 30),
    instructions: "Instructies",
    address_gp: `Arts informatie`,
    address_patient: "Patient informatie",
  }));

  const open_prescriptions = Array(2)
    .fill()
    .map((item) => ({
      text_id: "\\r\\n\\r\\nR/ amitriptyline tablet 25 mg\\r\\n\\r\\nDTD 28 tabletten\\r\\n\\r\\nS/ 2 maal daags 1 tablet \\r\\n\\r\\n",
      // text_id:
      //   "Datum: 2020-07-27 \r\nR/ Paracetamol kauwtablet 120 mg\r\n\r\ndtd no/ 20 tabletten \r\n\r\nS/ 1 tablet zo nodig bij pijn max 4dd. \r\n\r\nTenminste 4 uur tussen 2 opeenvolgende doses. \r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\nParaaf \r\n\r\n",
      student_id: random(10000),
    }));
  const base_json = {
    source: "local",
    username: "APItester",
    email: "tester@gmail.com",
    case_access_id: random(10),
    period: "2013-05-14 12:00 tot 2013-05-16 13:30",
    first_name: "John",
    last_name: "Doe",
    type: "All | Case | Answers | Connect",
  };
  const handleFromPscribe = (type) => {
    let data = base_json;
    switch (type) {
      case "all":
        data = {
          ...data,
          case: _case,
          case_id: selectedCase ? selectedCase.id : null,
          open_questions: open_questions,
          mc_questions: mc_questions,
          open_answers: open_answers,
          mc_answers: mc_answers,
          prescriptions: prescriptions,
          open_prescriptions: open_prescriptions,
          type: type,
        };
        break;
      case "case":
        data = {
          ...data,
          case_id: selectedCase ? selectedCase.id : null,
          case: _case,
          open_questions: open_questions,
          mc_questions: mc_questions,
          type: type,
        };
        break;
      case "answers":
        if (!selectedCase) {
          setOpenErrorMessage(true);
          return;
        }

        data = {
          ...data,
          case_id: selectedCase.id,
          open_answers: open_answers.map((answer) => {
            answer.question_id = possibleOpenQuestions[Math.floor(Math.random() * possibleOpenQuestions.length)];
            return answer;
          }),
          mc_answers: mc_answers.map((answer) => {
            answer.question_id = possibleMCQuestions[Math.floor(Math.random() * possibleMCQuestions.length)];
            return answer;
          }),
          prescriptions: prescriptions,
          open_prescriptions: open_prescriptions,
          type: type,
        };
        break;
      case "connect":
        if (!selectedCase) {
          setOpenErrorMessage(true);
          return;
        }
        data = {
          ...data,
          case_id: selectedCase.id,
          type: "connect",
        };
    }

    axios.post("apa/api/pscribe/", data).then(
      (response) => {
        if (redirect) {
          props.logout();
          // window.location = response.data.redirect_url;
          history.push(response.data.redirect_url);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const loadOpenQuestions = (case_id) => {
    // Load the case data from the backend
    axios
      .get("apa/api/questions/open/", {
        params: {
          case_id: case_id,
        },
      })
      .then((res) => {
        setPossibleOpenQuestions(res.data.map((question) => question.id));
      })
      .catch((error) => console.log(error));
  };

  const loadMCQuestions = (case_id) => {
    // Load the case data from the backend
    axios
      .get("apa/api/questions/mc/", {
        params: {
          case_id: case_id,
        },
      })
      .then((res) => {
        setPossibleMCQuestions(res.data.map((question) => question.id));
      })
      .catch((error) => console.log(error));
  };
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SelectCase
            variant="outlined"
            onChange={(newValue) => {
              setSelectedCase(newValue);
              loadOpenQuestions(newValue.id);
              loadMCQuestions(newValue.id);
            }}
            default={false}
            variant="outlined"
          />
        </Grid>
        <Grid item>
          <Box mt={2} display="flex" flexDirection="column">
            <ButtonGroup orientation="vertical" color="primary" variant="text">
              <Button onClick={() => handleFromPscribe("all")}>All</Button>
              <Button onClick={() => handleFromPscribe("case")}>Case</Button>
              <Button onClick={() => handleFromPscribe("answers")}>Answers</Button>
              <Button onClick={() => handleFromPscribe("connect")}>Connect</Button>
            </ButtonGroup>
            <FormControlLabel
              control={<Switch checked={redirect} color="primary" onChange={(e) => setRedirect(e.target.checked)} />}
              label="Redirect"
            />
            {/* <FormControlLabel control={<Switch checked={useExistingUser} color="primary" onChange={e => setUseExistingUser(e.target.checked)} />} label="Use existing user" /> */}
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Card>
            <CardHeader
              title="Base JSON"
              action={
                <Tooltip title="Information that is always required">
                  <HelpOutline color="primary" fontSize="small" style={{ marginTop: "12px" }} />
                </Tooltip>
              }
            />
            <CardContent>
              <ReactJson src={base_json} name="base" />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs>
          <Card>
            <CardHeader
              title="Case JSON"
              action={
                <Tooltip title="Case information used to make a new case or update the selected one">
                  <HelpOutline color="primary" fontSize="small" style={{ marginTop: "12px" }} />
                </Tooltip>
              }
            />
            <CardContent>
              <ReactJson src={_case} name="case" />
              <ReactJson src={open_questions} name="open_questions" collapsed />
              <ReactJson src={mc_questions} name="mc_questions" collapsed />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs>
          <Card>
            <CardHeader
              title="Answers JSON"
              action={
                <Tooltip title="Student answers corresponding to the selected case or the case JSON">
                  <HelpOutline color="primary" fontSize="small" style={{ marginTop: "12px" }} />
                </Tooltip>
              }
            />
            <CardContent>
              <ReactJson src={open_answers} name="open_answers" collapsed />
              <ReactJson src={mc_answers} name="mc_answers" collapsed />
              <ReactJson src={prescriptions} name="prescriptions" collapsed />
              <ReactJson src={open_prescriptions} name="open_prescriptions" collapsed />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openErrorMessage}
        autoHideDuration={6000}
        onClose={() => setOpenErrorMessage(false)}
      >
        <SnackbarContentWrapper
          onClose={() => setOpenErrorMessage(false)}
          variant="error"
          message={"Please select a case for this API action to work."}
        />
      </Snackbar>
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch) => {
  // Function to dispatch logout when user presses logout button
  return {
    logout: () => dispatch(logout()),
  };
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiExample);
