import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography,
  Divider,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import React from "react";
import SelectList from "../utility/SelectList";
// TODO modify this component to edit case too
const useStyles = makeStyles((theme) => ({}));

export default function PrescriptionCreateCard(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState({ drugs: true, administration_forms: false, strengths: false });
  // const [IDMoptions, setIDMoptions] = React.useState({ drugs: null, administration_forms: null, strengths: null });

  const [drugOptions, setDrugOptions] = React.useState([]);
  const [adminstrationFormOptions, setAdministrationFormOptions] = React.useState([]);
  const [strengthsOptions, setStrengthOptions] = React.useState([]);

  const [selectedDrug, setSelectedDrug] = React.useState(null);
  const [selectedAdministrationForm, setSelectedAdministrationForm] = React.useState(null);
  const [selectedStrength, setSelectedStrength] = React.useState(null);

  const [state, setState] = React.useState({
    address_gp: "",
    drug: null,
    administration_form: null,
    strength: null,
    total_quantity: 0,
    frequency: 0,
    quantity: 0,
    therapy_time: 0,
    instructions: "",
    address_patient: "",
    intake_unit: {
      label: "Daily",
      value: 1,
    },
    therapy_unit: {
      label: "Days",
      value: 1,
    },
  });

  const intake_dd_options = [
    {
      label: "Daily",
      value: 1,
    },
    {
      label: "Weekly",
      value: 7,
    },
    {
      label: "Monthly",
      value: 30,
    },
    {
      label: "As needed",
      value: 0,
    },
  ];
  const therapy_dd_options = [
    {
      label: "Days",
      value: 1,
    },
    {
      label: "Weeks",
      value: 7,
    },
    {
      label: "Months",
      value: 30,
    },
  ];

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const loadDrugs = (selectedDrug) => {
    setLoading({ ...loading, drugs: true });
    axios
      .get("apa/api/idm/drugs")
      .then((res) => {
        const drugs = res.data.map((drug) => ({
          value: drug.reference_name,
          label: drug.name,
        }));
        setDrugOptions(drugs);
        setLoading({ ...loading, drugs: false });
        if (selectedDrug) {
          setSelectedDrug(drugs.find((e) => e.label === selectedDrug));
        }
      })
      .catch((error) => console.log(error));
  };

  const loadAdministrationForms = (drugId, selectedAdministrationForm) => {
    setLoading({ ...loading, administration_forms: true });
    axios
      .get("apa/api/idm/administrationforms", {
        params: {
          drug: drugId,
        },
      })
      .then((res) => {
        const administration_forms = res.data.map((administration_form) => ({
          value: administration_form.signature_form,
          label: administration_form.signature_form,
        }));
        setAdministrationFormOptions(administration_forms);
        setLoading({ ...loading, administration_forms: false });
        setStrengthOptions([]);
        if (selectedAdministrationForm) {
          setSelectedAdministrationForm(administration_forms.find((e) => e.label === selectedAdministrationForm));
        }
      })
      .catch((error) => console.log(error));
  };

  const loadStrengths = (drugId, administration_formId, selectedStrength) => {
    setLoading({ ...loading, strengths: true });

    axios
      .get("apa/api/idm/strengths", {
        params: {
          drug: drugId,
          administrationform: administration_formId,
        },
      })
      .then((res) => {
        const strengths = res.data.map((strength) => ({
          value: strength.id,
          label: strength.name,
          conglomerate: "strengths" in strength ? true : false,
        }));
        setStrengthOptions(strengths);
        setLoading({ ...loading, strengths: false });
        if (selectedStrength) {
          setSelectedStrength(strengths.find((e) => e.label === selectedStrength));
        }
      })
      .catch((error) => console.log(error));
  };

  React.useEffect(() => {
    if (props.prescription) {
      setState({
        ...state,
        ...props.prescription,
        administration_form: props.prescription.administration_form,
        intake_unit: intake_dd_options.find((e) => e.label === capitalize(props.prescription.intake_unit)),
        therapy_unit: therapy_dd_options.find((e) => e.label === capitalize(props.prescription.therapy_unit)),
      });
      loadDrugs(props.prescription.drug);

      loadAdministrationForms(props.prescription.drug, props.prescription.administration_form);

      loadStrengths(props.prescription.drug, props.prescription.administration_form, props.prescription.strength);
    } else if (drugOptions.length == 0) {
      loadDrugs();
    }
  }, [props.prescription]);

  const handleInputChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.value });
  };

  const handleSubmit = () => {
    // Submit the prescription to the backend
    props.onClose();
    let data = { ...state };
    data.drug = selectedDrug ? selectedDrug.value : null;
    data.administration_form = selectedAdministrationForm ? selectedAdministrationForm.value : null;
    data.case = props.case_id;
    data.strength = selectedStrength ? selectedStrength.value : null;
    data.conglomerate_strength = selectedStrength ? selectedStrength.conglomerate : null;
    data.intake_unit = data.intake_unit.value;
    data.therapy_unit = data.therapy_unit.value;

    if (props.mode === "edit") {
      // Update/Edit case
      axios.put(`apa/api/answers/prescriptions/${state.student_id}/`, data).then(
        (response) => {
          props.onSuccess(data);
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      // Create case
      axios.post("apa/api/answers/prescriptions/", data).then(
        (response) => {
          props.onSuccess(data);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5" component="h2">
            {props.mode === "edit" ? "Edit" : "New"} prescription
          </Typography>
          <IconButton onClick={props.onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>

        <form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                className={classes.textField}
                fullWidth
                multiline
                rows="2"
                label="Doctor information"
                value={state.address_gp}
                onChange={handleInputChange("address_gp")}
              />
            </Grid>
            <Grid container item spacing={1}>
              <Grid item xs={12}>
                <SelectList
                  options={drugOptions.sort(
                    (a, b) => -b.label.match("[a-zA-Z]").pop().toUpperCase().localeCompare(a.label.match("[a-zA-Z]").pop().toUpperCase())
                  )}
                  onChange={(event, newValue) => {
                    setSelectedDrug(newValue);
                    setSelectedAdministrationForm(null);
                    setSelectedStrength(null);
                    if (newValue != null) {
                      loadAdministrationForms(newValue.value);
                    } else {
                      setAdministrationFormOptions([]);
                      setStrengthOptions([]);
                    }
                  }}
                  size="small"
                  value={selectedDrug}
                  groupBy={(option) => option.label.match("[a-zA-Z]").pop().toUpperCase()}
                  getOptionLabel={(option) => option.label}
                  loading={loading.drugs}
                  renderInput={(params) => <TextField {...params} label="Drug" />}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectList
                  options={adminstrationFormOptions}
                  onChange={(event, newValue) => {
                    if (newValue != null) {
                      loadStrengths(selectedDrug.value, newValue.value);
                    } else {
                      setStrengthOptions([]);
                    }
                    setSelectedAdministrationForm(newValue);
                    setSelectedStrength(null);
                  }}
                  size="small"
                  value={selectedAdministrationForm}
                  loading={loading.administration_forms}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => <TextField {...params} label="Administration form" />}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectList
                  options={strengthsOptions}
                  onChange={(event, newValue) => {
                    setSelectedStrength(newValue);
                  }}
                  size="small"
                  value={selectedStrength}
                  loading={loading.strengths}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => <TextField {...params} label="Strength" />}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={classes.textField}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ min: "0" }}
                onChange={handleInputChange("total_quantity")}
                value={state.total_quantity}
                label="Da"
              />
            </Grid>
            <Grid container item xs={12} spacing={3} alignItems="flex-end">
              <Grid item xs={3}>
                <TextField
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{ min: "0" }}
                  className={classes.textField}
                  onChange={handleInputChange("frequency")}
                  value={state.frequency}
                  label="Frequence"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  className={classes.textField}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{ min: "0" }}
                  onChange={handleInputChange("quantity")}
                  value={state.quantity}
                  label="Amount"
                />
              </Grid>
              <Grid item xs={4}>
                <SelectList
                  options={intake_dd_options}
                  onChange={(event, newValue) => {
                    setState({ ...state, intake_unit: newValue });
                  }}
                  size="small"
                  value={state.intake_unit}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => <TextField {...params} label="dd" />}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3} alignItems="flex-end">
              <Grid item xs={3}>
                <TextField
                  className={classes.textField}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{ min: "0" }}
                  onChange={handleInputChange("therapy_time")}
                  value={state.therapy_time}
                  label="Therapy length"
                />
              </Grid>
              <Grid item xs={4}>
                <SelectList
                  options={therapy_dd_options}
                  onChange={(event, newValue) => {
                    setState({ ...state, therapy_unit: newValue });
                  }}
                  size="small"
                  value={state.therapy_unit}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => <TextField {...params} label="dd" />}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={classes.textField}
                fullWidth
                multiline
                rows="2"
                label="Instructions"
                value={state.instructions}
                onChange={handleInputChange("instructions")}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                className={classes.textField}
                fullWidth
                multiline
                rows="2"
                label="Patient information"
                value={state.address_patient}
                onChange={handleInputChange("address_patient")}
              />
            </Grid>
          </Grid>
        </form>
      </CardContent>

      <CardActions>
        <Button onClick={props.onClose} color="primary">
          Cancel
        </Button>
        <Button color="primary" onClick={handleSubmit}>
          {props.mode === "edit" ? "Save" : "Submit"}
        </Button>
      </CardActions>
    </Card>
  );
}
