import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Box,
  IconButton,
  Radio,
  Checkbox,
  Snackbar,
  Grid,
} from "@material-ui/core";
import PropTypes from "prop-types";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import { green, red } from "@material-ui/core/colors";
import React from "react";
import axios from "axios";
import SnackbarContentWrapper from "../utility/SnackbarContentWrapper";
MultipleChoiceQuestionCard.propTypes = {
  question: PropTypes.object.isRequired,
};

function MultipleChoiceQuestionCard(props) {
  const [answers, setAnswers] = React.useState(null);
  const [answerAmount, setAnswerAmount] = React.useState(0);
  const [edit, setEdit] = React.useState(false);
  const [choices, setChoices] = React.useState([]);

  React.useEffect(() => {
    if (answers == null && props.question.answers) {
      const summed_answers = props.question.choices.map(
        (choice) => props.question.answers.filter((answer) => answer.choice.id == choice.id).length
      );
      setAnswers(summed_answers);
      setAnswerAmount(summed_answers.reduce((a, b) => a + b));
    }
    if (!choices.length) {
      setChoices(props.question.choices);
    }
  }, []);

  const roundToTwo = (num) => {
    return +(Math.round(num + "e+2") + "e-2");
  };

  const renderChoices = () => {
    if (props.type === "assesment") {
      return (
        <List dense>
          {props.question.choices.map((choice, i) => (
            <ListItem>
              <Typography style={{ marginRight: "8px" }} variant="caption" color="textSecondary">
                {String.fromCharCode(97 + choice.position)}
              </Typography>
              <ListItemText primary={choice.choice} primaryTypographyProps={{ variant: "caption" }} />

              <ListItemSecondaryAction>
                <Radio
                  edge="end"
                  onChange={() => {
                    const newData = choices.map((choice, index) => ({ ...choice, correct: i === index }));
                    //setChoices(newData);
                    axios.patch(`apa/api/questions/mc/${props.question.id}/`, { choices: newData }).then((res) => {
                      props.onChangeChoices(newData);
                    });
                  }}
                  checked={choice.correct}
                  color="primary"
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      );
    } else if (props.type === "graded") {
      return (
        <List dense>
          {choices.map((choice, i) => (
            <ListItem>
              <Typography style={{ marginRight: "8px" }} variant="caption" color="textSecondary">
                {String.fromCharCode(97 + choice.position)}
              </Typography>
              <ListItemText primary={choice.choice} primaryTypographyProps={{ variant: "caption" }} />

              <ListItemSecondaryAction>
                {/* <Radio edge="end" checked={choice.correct} disabled /> */}
                <Typography variant="caption" style={{ color: choice.correct ? green[500] : red[500] }}>
                  {answers[i]} {answerAmount === 0.0 ? 0 : roundToTwo((answers[i] / answerAmount) * 100)}%
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      );
    } else if (props.type === "answers") {
      return (
        <List dense>
          {choices.map((choice, i) => (
            <ListItem>
              <Typography style={{ marginRight: "8px" }} variant="caption" color="textSecondary">
                {String.fromCharCode(97 + choice.position)}
              </Typography>
              <ListItemText primary={choice.choice} primaryTypographyProps={{ variant: "caption" }} />
              {answers ? (
                <ListItemSecondaryAction>
                  <Typography variant="caption" color="primary">
                    {answers[i]} | {answerAmount === 0.0 ? 0 : roundToTwo((answers[i] / answerAmount) * 100)}%
                  </Typography>
                </ListItemSecondaryAction>
              ) : null}
            </ListItem>
          ))}
        </List>
      );
    }
    return (
      <List dense>
        {choices.map((choice, i) => (
          <ListItem>
            <Typography style={{ marginRight: "8px" }} variant="caption" color="textSecondary">
              {String.fromCharCode(97 + choice.position)}
            </Typography>
            <ListItemText primary={choice.choice} primaryTypographyProps={{ variant: "caption" }} />
            {answers ? (
              <ListItemSecondaryAction>
                <Typography variant="caption" style={{ color: choice.correct ? green[500] : red[500] }}>
                  {answers[i]} | {answerAmount === 0.0 ? 0 : roundToTwo((answers[i] / answerAmount) * 100)}%
                </Typography>
              </ListItemSecondaryAction>
            ) : null}
          </ListItem>
        ))}
      </List>
    );
  };

  const renderTopRight = () => {
    if (props.type === "testing") {
      return (
        <Box>
          <IconButton
            color="primary"
            size="small"
            onClick={(e) => {
              props.onEdit(props.question);
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="primary"
            size="small"
            onClick={(e) => {
              props.onDelete(props.question);
            }}
          >
            <DeleteIcon style={{ color: red[500] }} />
          </IconButton>
        </Box>
      );
    } else if (props.type === "graded" || props.type === "answers") {
      return (
        <Box>
          <Typography variant="caption">
            <b>n | %</b>
          </Typography>
        </Box>
      );
    }
    return null;
  };

  return (
    <React.Fragment>
      <Card>
        <CardContent style={{ paddingBottom: "0px" }}>
          <Grid container spacing={4}>
            <Grid item xs={10}>
              <Box>
                <Typography variant="caption" align="left" style={{ marginRight: "8px" }}>
                  <b>{props.question.position}</b>
                </Typography>
                <Typography variant="body2" display="inline" style={{ whiteSpace: "pre-line" }}>
                  {props.question.question}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box>{renderTopRight()}</Box>
            </Grid>
          </Grid>

          {renderChoices(props.question.choices)}
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default MultipleChoiceQuestionCard;
