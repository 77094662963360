import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Fab, Tooltip, LinearProgress, IconButton } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { Link } from "react-router-dom";
import CheckIcon from "@material-ui/icons/Check";
import OpenQuestionCard from "../questions/OpenQuestionCard";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import ReactExport from "react-data-export";
import GetAppIcon from "@material-ui/icons/GetApp";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
OpenQuestionAnswers.propTypes = {};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1, 2),
  },
  pagination: {
    marginRight: "auto !important",
    marginLeft: "auto !important",
    marginTop: "8px",
  },
  fab: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
    "&:hover": {
      color: "white",
    },
  },
}));

function OpenQuestionAnswers(props) {
  const classes = useStyles();

  const case_id = props.match.params.case_id;
  const [questions, setQuestions] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const numberOfItemsForPage = 2;

  const filename = localStorage.getItem("period") ? localStorage.getItem("period") : "Open Question Answers";
  const handleChange = (event, value) => {
    setPage(value);
  };

  const loadOpenQuestions = (case_id) => {
    // Load the case data from the backend
    setLoading(true);
    axios
      .get("apa/api/questions/open/", {
        params: {
          case_id: case_id,
          case_access_id: localStorage.getItem("case_acces_id"),
          include_answers: true,
        },
      })
      .then((res) => {
        const data = res.data.map((question) => ({
          ...question,
          model_answer: question.answers.length ? question.answers[0].model_answer : null,
        }));

        setQuestions(data);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  React.useEffect(() => {
    if (questions == null) {
      loadOpenQuestions(case_id);
    }
  }, [loadOpenQuestions]);

  return (
    <React.Fragment>
      {loading ? (
        <LinearProgress />
      ) : questions && questions.length ? (
        <React.Fragment>
          <Box mb={2} display="flex" alignItems="center">
            <ExcelFile
              filename={filename}
              element={
                <Tooltip title="Download data">
                  <IconButton color="primary" component="span">
                    <GetAppIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
              }
            >
              <ExcelSheet data={questions} name="Overview">
                <ExcelColumn label="Parent Step" value="parent_step_index" />
                <ExcelColumn label="Step" value="step_index" />
                <ExcelColumn label="Index" value="index" />
                <ExcelColumn label="Question" value="question" />
                <ExcelColumn label="Model Answer" value="model_answer" />
              </ExcelSheet>
              {questions.map((question) => (
                <ExcelSheet data={question.answers} name={`Question ${question.position}`}>
                  <ExcelColumn label="Student ID" value="student" />
                  <ExcelColumn label="Answer" value="answer" />
                  <ExcelColumn label="Grade" value="grade" />
                </ExcelSheet>
              ))}
            </ExcelFile>
            <Box flexGrow={1}>
              <Alert severity="info">
                {localStorage.getItem("period")
                  ? `Answers are from the period: ${localStorage.getItem("period")}`
                  : "Answers are from all periods."}
              </Alert>
            </Box>
          </Box>
          <Grid container spacing={3} justify="center" style={{ height: 600 }}>
            {questions.slice((page - 1) * numberOfItemsForPage, page * numberOfItemsForPage).map((question) => (
              <Grid item xs={6}>
                <OpenQuestionCard
                  question={question}
                  type={question.answers.some((answer) => answer.grade) ? "graded" : "answers"}
                  key={page}
                />
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={3} justify="center">
            <Grid item>
              <Pagination
                className={classes.pagination}
                color="primary"
                variant="outlined"
                size="large"
                showFirstButton
                showLastButton
                count={Math.ceil(questions.length / numberOfItemsForPage)}
                page={page}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Tooltip title="Grade">
            <Fab color="primary" component={(props) => <Link to={`/oqa/assesment/grade/${case_id}`} {...props} />} className={classes.fab}>
              <CheckIcon />
            </Fab>
          </Tooltip>
        </React.Fragment>
      ) : (
        <Alert severity="info">No open questions exist for this case.</Alert>
      )}
    </React.Fragment>
  );
}

export default OpenQuestionAnswers;
