import React from "react";
import PropTypes from "prop-types";
import PrescriptionsTable from "../prescriptions/PrescriptionsTable";
import { green, red, lightGreen, deepOrange } from "@material-ui/core/colors";
import { Grid, Paper, Typography, Fab, Tooltip, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import CheckIcon from "@material-ui/icons/Check";
import Alert from "@material-ui/lab/Alert";
const useStyles = makeStyles((theme) => ({
  fab: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
    "&:hover": {
      color: "white",
    },
  },
  paper: {
    padding: theme.spacing(1, 2),
  },
}));

function PrescriptionAnswers(props) {
  const classes = useStyles();
  const case_id = props.match.params.case_id;

  return (
    <React.Fragment>
      {localStorage.getItem("period") ? (
        <Box mb={2}>
          <Alert severity="info">Answers are from the period: {localStorage.getItem("period")}</Alert>
        </Box>
      ) : null}
      <PrescriptionsTable case_id={case_id} mode="answers" title={"Prescription answers"} graded export />
      <Tooltip title="Grade">
        <Fab color="primary" component={(props) => <Link to={`/apa/assesment/grade/${case_id}`} {...props} />} className={classes.fab}>
          <CheckIcon />
        </Fab>
      </Tooltip>
    </React.Fragment>
  );
}

export default PrescriptionAnswers;
