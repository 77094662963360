import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import clsx from "clsx";
import React from "react";
import MaterialTable from "../utility/MaterialTable";

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#137cbd",
    backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
});

function StyledRadio(props) {
  // Radio buttons used to edit user roles
  const classes = useStyles();

  return <Radio className={classes.root} disableRipple color="default" checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />} icon={<span className={classes.icon} />} {...props} />;
}

function UsersTable(props) {
  // Displays the users in a compact table
  const classes = useStyles();

  const [state, setState] = React.useState({
    columns: [
      { title: "Username", field: "username" },
      { title: "Email", field: "email" },
      { title: "First Name", field: "first_name" },
      { title: "Last Name", field: "last_name" },
      { title: "Date joined", field: "date_joined", editable: "never" },
      { title: "Last login", field: "last_login", editable: "never" },
      {
        title: "Role",
        field: "group",
        // Custom edit component to edit roles using a radio button
        editComponent: (props) => (
          <FormControl component="fieldset" margin="dense" size="small">
            <RadioGroup name="role" value={props.value} onChange={(e) => props.onChange(e.target.value)}>
              <FormControlLabel value="superadmin" control={<StyledRadio />} label="Super admin" />
              <FormControlLabel value="admin" control={<StyledRadio />} label="Admin" />
              <FormControlLabel value="tester" control={<StyledRadio />} label="Tester" />
              <FormControlLabel value="standard" control={<StyledRadio />} label="Standard" />
            </RadioGroup>
          </FormControl>
        ),
      },
    ],
    data: null,
  });

  const [loading, setLoading] = React.useState(false);

  const loadUsers = () => {
    // Loads the users from the backend into the state
    setLoading(true);
    axios
      .get("apa/auth/users/")
      .then((res) => {
        const data = res.data.map((user) => ({
          ...user,
          group: user.groups[0].name,
          date_joined: new Date(user.date_joined).toLocaleDateString(),
          last_login: new Date(user.last_login).toLocaleString(),
        }));
        setState({ ...state, data });
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  function create_UUID() {
    var dt = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }

  const createUser = (data) => {
    const password = create_UUID();
    axios
      .post("apa/rest-auth/registration/", {
        username: data.username,
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        password1: password,
        password2: password,
        group: data.group,
      })
      .then((res) => {
        //TODO use an email manager to send this to user
        alert(`Created user ${data.username} with password: ${password}`);
        const dataUpdate = [...state.data];
        dataUpdate.push(data);
        setState({ ...state, data: dataUpdate });
      });
  };

  const editUser = (newData, oldData) => {
    axios.put(`apa/auth/users/${newData.id}/`, newData).then((res) => {
      const dataUpdate = [...state.data];
      const index = oldData.tableData.id;
      dataUpdate[index] = newData;
      setState({ ...state, data: dataUpdate });
    });
  };

  const deleteUser = (data) => {
    axios.delete(`apa/auth/users/${data.id}/`).then((res) => {
      const dataUpdate = [...state.data];
      const index = data.tableData.id;
      dataUpdate.splice(index, 1);
      setState({ ...state, data: dataUpdate });
    });
  };

  React.useEffect(() => {
    // Load the users when the component is created
    if (state.data == null && !loading) {
      loadUsers();
    }
  }, [loadUsers]);

  return (
    <MaterialTable
      columns={state.columns}
      isLoading={loading}
      options={{
        actionsColumnIndex: state.columns.length,
        draggable: false,
        padding: "dense",
        pageSize: 10,
        pageSizeOptions: [10, 15, 20, 25],
      }}
      data={state.data == null ? [] : state.data}
      title="Users"
      editable={{
        onRowAdd: (newData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              createUser(newData);

              resolve();
            }, 100);
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              editUser(newData, oldData);
              resolve();
            }, 100);
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              deleteUser(oldData);
              resolve();
            }, 100);
          }),
      }}
    />
  );
}

export default UsersTable;
