import { Avatar, Button, Card, CardContent, CssBaseline, Grid, Link, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import React from "react";
import { connect } from "react-redux";
import RouterLink from "react-router-dom/Link";
import { Field, reduxForm } from "redux-form";
import * as actions from "../../store/actions/auth";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  card: {
    margin: "auto",
    width: "30rem",
  },
}));

const validate = (values) => {
  const errors = {};
  const requiredFields = ["username", "email", "password", "confirmpassword", "signupkey"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });

  if (values.username) {
    if (values.username.length > 15) {
      errors.username = "Username must be 15 characters or less";
    }
    if (/\s/.test(values.username)) {
      errors.username = "Username may not contain spaces";
    }
    if (values.username.length < 6) {
      errors.username = "Username must be 6 characters or more";
    }
  }

  if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (values.password) {
    if (values.password.length > 40) {
      errors.password = "Password must be 40 characters or less";
    }
    if (values.password.length < 6) {
      errors.password = "Password must be 6 characters or more";
    }
    if (/\s/.test(values.password)) {
      errors.password = "Password may not contain spaces";
    }
  }
  if (values.password && values.confirmpassword) {
    if (values.password !== values.confirmpassword) {
      errors.password = "Passwords do not match";
    }
  }
  if (values.signupkey && values.signupkey !== "123456") {
    errors.signupkey = "Incorrect sign up key";
  }

  return errors;
};

const renderTextField = ({ label, input, meta: { touched, invalid, error }, ...custom }) => <TextField label={label} placeholder={label} error={touched && invalid} helperText={touched && error} variant="outlined" fullWidth {...input} {...custom} />;

function SignUp(props) {
  const classes = useStyles();

  const submitAuth = (values, event, props) => {
    props.onAuth(values.username, values.email, values.first_name, values.last_name, values.password, values.confirmpassword, values.signupkey);
  };

  return (
    <Card className={classes.card}>
      <CardContent>

        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>

          <form className={classes.form} onSubmit={props.handleSubmit(submitAuth)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div>
                  <Field name="username" label="Username" component={renderTextField} required />
                </div>
              </Grid>
              <Grid item xs={12}>
                <Field name="email" component={renderTextField} label="Email" required />
              </Grid>

              <Grid item xs={6}>
                <Field name="first_name" component={renderTextField} label="First Name" />
              </Grid>
              <Grid item xs={6}>
                <Field name="last_name" component={renderTextField} label="Last Name" />
              </Grid>
              <Grid item xs={12}>
                <Field name="password" component={renderTextField} type="password" label="Password" required />
              </Grid>
              <Grid item xs={12}>
                <Field name="confirmpassword" component={renderTextField} type="password" label="Confirm password" required />
              </Grid>

              <Grid item xs={12}>
                <Field name="signupkey" component={renderTextField} type="password" label="Sign up key" required />
              </Grid>

              <Grid item xs={12}>
                {props.errorstate ? <Typography color="error" variant="subtitle1">Failed to signup</Typography> : null}
              </Grid>

            </Grid>

            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
              Sign Up
            </Button>
            <Grid container justify="flex-end">
              <Grid item>
                <Link component={RouterLink} to="/" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </CardContent>
    </Card>
  );
}

SignUp = reduxForm({
  validate,
  form: "signup",
})(SignUp);

const mapStateToProps = (state) => {
  return {
    errorstate: state.auth.error,
    loading: state.loading,
    error: state.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (username, email, first_name, last_name, password1, password2, signupkey) => dispatch(actions.authSignup(username, email, first_name, last_name, password1, password2, signupkey)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
