import {
  Card,
  CardContent,
  Typography,
  Box,
  TextField,
  List,
  ListItem,
  ListItemIcon,
  Radio,
  RadioGroup,
  FormControlLabel,
  ListItemText,
  Tooltip,
  FormControl,
} from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import MaterialTable from "../utility/MaterialTable";
import { MTableToolbar, MTableHeader } from "material-table";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import axios from "axios";
import { red, green, lightGreen, grey, yellow } from "@material-ui/core/colors";
OpenQuestionCard.propTypes = {
  question: PropTypes.object.isRequired,
  type: PropTypes.string,
};

function OpenQuestionCard(props) {
  const [modelAnswers, setModelAnswers] = React.useState(null);
  const [answers, setAnswers] = React.useState(null);
  const [selectedAnswer, setSelectedAnswer] = React.useState(null);

  let model_answer = null;
  let question_string = props.question.question;
  if (props.type === "graded") {
    if (props.question.answers.length) {
      model_answer = props.question.answers[0].model_answer;
    }
  }
  React.useEffect(() => {
    if (props.type === "grading" && !modelAnswers) {
      setModelAnswers(
        props.question.model_answers.map((model_answer) => ({
          model_answer: model_answer.answer,
          id: model_answer.id,
        }))
      );
      if (props.question.model_answers.length) {
        setSelectedAnswer(props.question.model_answers[0].id);
      }
    }
    if ((props.type === "answers" || props.type === "graded") && !answers) {
      setAnswers(props.question.answers);
    }
  }, []);

  const addModelAnswer = (newData) => {
    axios.post("apa/api/answers/model/", { ...newData, question: props.question.id }).then((res) => {
      props.onChangeModelAnswers([...props.question.model_answers, newData]);
    });
  };

  const editModelAnswer = (newData, oldData) => {
    axios.put(`apa/api/answers/model/${newData.id}/`, { ...newData, question: props.question.id }).then((res) => {
      const data = [...props.question.model_answers];
      data[data.indexOf(oldData)] = newData;
      props.onChangeModelAnswers(data);
    });
  };

  const deleteModelAnswer = (rowData) => {
    axios.delete(`apa/api/answers/model/${rowData.id}/`).then((res) => {
      let data = [...props.question.model_answers];
      data.splice(data.indexOf(rowData), 1);
      props.onChangeModelAnswers(data);
    });
  };

  const editGrade = (newData, oldData) => {
    axios.patch(`apa/api/answers/open/${newData.id}/`, { grade: newData.grade, manual: "True" }).then((res) => {
      const data = [...answers];
      data[data.indexOf(oldData)] = newData;
      setAnswers([...data]);
    });
  };

  const addAnswer = (newData) => {
    axios.post("apa/api/answers/open/", { ...newData, question: props.question.id }).then((res) => {
      if (answers) {
        setAnswers([...answers, { ...res.data }]);
      } else {
        setAnswers([{ ...res.data }]);
      }
    });
  };

  const editAnswer = (newData, oldData) => {
    axios.put(`apa/api/answers/open/${newData.student_id}/`, { ...newData, question: props.question.id }).then((res) => {
      const data = [...answers];
      data[data.indexOf(oldData)] = newData;
      setAnswers([...data]);
    });
  };

  const deleteAnswer = (rowData) => {
    axios.delete(`apa/api/answers/open/${rowData.student_id}/`).then((res) => {
      const data = [...answers];
      data.splice(data.indexOf(rowData), 1);
      setAnswers([...data]);
    });
  };

  const grade_to_color = (grade) => {
    switch (grade) {
      case "G":
        return green[600];
      case "V":
        return yellow[300];
      case "O":
        return red[300];
      default:
        return grey[300];
    }
  };
  const renderContent = () => {
    if (props.type === "model_answers") {
      return (
        <MaterialTable
          columns={[
            {
              title: "Index",
              editable: "never",
              render: (rowData) => (
                <Box fontWeight="fontWeightBold" fontSize={14}>
                  {!rowData ? null : rowData.tableData.id + 1}
                </Box>
              ),
              width: 100,
              cellStyle: {
                width: 40,
                maxWidth: 40,
              },
              headerStyle: {
                width: 56,
                maxWidth: 56,
              },
            },
            {
              title: "Model Answer",
              field: "answer",
              editComponent: (props) => (
                <TextField multiline rows={3} fullWidth value={props.value} onChange={(e) => props.onChange(e.target.value)} />
              ),
            },
          ]}
          components={{
            Toolbar: (props) => (
              <div>
                <MTableToolbar {...props} />

                <Box mx={3}>
                  <Typography variant="subtitle2" color="primary" display="inline" style={{ whiteSpace: "pre-line" }} gutterBottom>
                    {question_string.replace(/\s{2,}/g, " ")}
                  </Typography>
                </Box>
              </div>
            ),
          }}
          data={props.question.model_answers}
          title={`Question ${props.question.position}`}
          editable={
            props.editable
              ? {
                  onRowAdd: (newData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        addModelAnswer(newData);
                        resolve();
                      }, 100);
                    }),
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        editModelAnswer(newData, oldData);
                        resolve();
                      }, 100);
                    }),
                }
              : null
          }
          actions={[
            props.editable
              ? {
                  icon: () => <DeleteIcon color="error" />,
                  tooltip: "Delete",
                  onClick: (event, rowData) => {
                    deleteModelAnswer(rowData);
                  },
                }
              : null,
          ]}
          options={{
            actionsColumnIndex: 3,
            draggable: false,
            padding: "dense",
            sorting: false,
            search: false,
            pageSize: 5,
            pageSizeOptions: [5, 10],
            minBodyHeight: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) * 0.5,
            maxBodyHeight: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) * 0.5,
          }}
        />
      );
    }
    if (props.type === "answers") {
      return (
        <Box height="50%" maxHeight={300}>
          <MaterialTable
            columns={[
              {
                editable: "never",
                title: "Student ID",
                render: (rowData) => rowData.student,
                width: 150,
              },
              {
                title: "Answer",
                field: "answer",
                render: (rowData) => (
                  <Typography variant="body2" gutterBottom>
                    {rowData.answer}
                  </Typography>
                ),
                editComponent: (props) => (
                  <TextField multiline rows={3} fullWidth value={props.value} onChange={(e) => props.onChange(e.target.value)} />
                ),
              },
            ]}
            components={{
              Toolbar: (props) => (
                <div>
                  <MTableToolbar {...props} />

                  <Box mx={3}>
                    <Typography variant="subtitle2" color="primary" display="inline" style={{ whiteSpace: "pre-line" }} gutterBottom>
                      {question_string.replace(/\s{2,}/g, " ")}
                    </Typography>
                  </Box>
                </div>
              ),
            }}
            editable={
              props.editable
                ? {
                    onRowAdd: (newData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          addAnswer(newData);
                          resolve();
                        }, 100);
                      }),
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          editAnswer(newData, oldData);
                          resolve();
                        }, 100);
                      }),
                  }
                : null
            }
            actions={[
              props.editable
                ? {
                    icon: () => <DeleteIcon color="error" />,
                    tooltip: "Delete",
                    onClick: (event, rowData) => {
                      deleteAnswer(rowData);
                    },
                  }
                : null,
            ]}
            data={answers ? answers : []}
            title={`Question ${props.question.position}`}
            options={{
              actionsColumnIndex: 3,
              draggable: false,
              padding: "dense",
              sorting: false,
              pageSize: 5,
              pageSizeOptions: [5, 10, 25],
              minBodyHeight: 400,
              maxBodyHeight: 400,
            }}
          />
        </Box>
      );
    }
    if (props.type === "grading") {
      return (
        <Tooltip
          disableHoverListener={modelAnswers && modelAnswers.length ? true : false}
          title={"No model answers available for this question"}
        >
          <Card
            style={{
              borderColor: red[500],
              overflowY: "auto",
              height: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) * 0.5,
            }}
            variant={modelAnswers && modelAnswers.length ? "elevation" : "outlined"}
          >
            <CardContent>
              <Box display="flex">
                <Box alignSelf="flex-end">
                  <Typography variant="caption" align="left" style={{ marginRight: "8px" }}>
                    <b>{props.question.position}</b>
                  </Typography>
                </Box>
                <Typography variant="body2" display="inline" color="primary" style={{ whiteSpace: "pre-line" }}>
                  {props.question.question}
                </Typography>
              </Box>
              {modelAnswers ? (
                <List>
                  {modelAnswers.map((answer, index) => (
                    <ListItem
                      dense
                      key={index}
                      onClick={() => {
                        setSelectedAnswer(answer.id);
                        props.onModelAnswerSelection(answer.id);
                      }}
                    >
                      <ListItemIcon>
                        <Radio
                          checked={answer.id == selectedAnswer}
                          onChange={() => {
                            setSelectedAnswer(answer.id);
                            props.onModelAnswerSelection(answer.id);
                          }}
                          color="primary"
                        ></Radio>
                      </ListItemIcon>
                      <ListItemText primary={answer.model_answer} />
                    </ListItem>
                  ))}
                </List>
              ) : null}
            </CardContent>
          </Card>
        </Tooltip>
      );
    }
    if (props.type === "graded") {
      return (
        <MaterialTable
          columns={[
            {
              title: "Student ID",
              editable: "never",
              render: (rowData) => rowData.student,
              width: 150,
            },
            {
              title: "Answer",
              field: "answer",
              sorting: false,
              editable: "never",
              render: (rowData) => (
                <Typography variant="body2" display="inline" style={{ whiteSpace: "pre-line", fontSize: 12 }}>
                  {rowData.answer}
                </Typography>
              ),
            },
            { title: "Grade", field: "grade", emptyValue: "N/A", width: 40, lookup: { G: "G", V: "V", O: "O" } },
          ]}
          data={answers ? answers : []}
          cellEditable={{
            onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
              return new Promise((resolve, reject) => {
                if (columnDef.field == "grade") {
                  let answer = rowData;
                  answer["grade"] = newValue;
                  editGrade(answer, rowData);
                }
                setTimeout(resolve, 1000);
              });
            },
          }}
          components={{
            Toolbar: (props) => (
              <div>
                <MTableToolbar {...props} />
                <Box ml={3} display="flex" flexDirection="column">
                  <Typography variant="body2" display="inline" color="primary" style={{ whiteSpace: "pre-line" }}>
                    {question_string.replace(/\s{2,}/g, " ")}
                  </Typography>
                  {model_answer ? (
                    <Typography variant="caption" color="textSecondary" style={{ whiteSpace: "pre-line" }} gutterBottom>
                      {model_answer}
                    </Typography>
                  ) : (
                    <Typography variant="subtitle2" color="error" gutterBottom>
                      No model answer was available.
                    </Typography>
                  )}
                </Box>
              </div>
            ),
          }}
          title={`Question ${props.question.position}`}
          options={{
            actionsColumnIndex: 3,
            draggable: false,
            padding: "dense",
            sorting: true,
            rowStyle: (rowData) => {
              return {
                backgroundColor: grade_to_color(rowData.grade),
                borderBottom: `2px solid ${grade_to_color(rowData.grade)}`,
              };
            },
            pageSize: 5,
            pageSizeOptions: [5, 10, 25],
            minBodyHeight: 350,
            maxBodyHeight: 350,
          }}
        />
      );
    }
    return (
      <Card>
        <CardContent>
          <Box display="flex">
            <Box alignSelf="flex-end">
              <Typography variant="caption" align="left" style={{ marginRight: "8px" }}>
                <b>{props.question.position}</b>
              </Typography>
            </Box>
            <Typography variant="body2" display="inline" style={{ whiteSpace: "pre-line" }}>
              {props.question.question}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    );
  };
  return <React.Fragment>{renderContent()}</React.Fragment>;
}

export default OpenQuestionCard;
