// Role-authorization rules
// Static rules depend only on the role and the action
// Dynamic rules depend on something like an owner id and not on the role only

const rules = {
  pscribe_user: {
    static: [],
    dynamic: {},
  },
  standard: {
    static: ["dashboard-testing:get"],
    dynamic: {},
  },
  admin: {
    static: ["dashboard-admin:get", "dashboard-testing:get","dashboard-admin:visit"],
    dynamic: {},
  },
  superadmin: {
    static: ["dashboard-admin:get", "dashboard-testing:get", "dashboard-admin:visit", "users:edit"],
    dynamic: {},
  },

  //   visitor: {
  //     static: ["posts:list", "home-page:visit"],
  //   },
  //   writer: {
  //     static: ["posts:list", "posts:create", "users:getSelf", "home-page:visit", "dashboard-page:visit"],
  //     dynamic: {
  //       "posts:edit": ({ userId, postOwnerId }) => {
  //         if (!userId || !postOwnerId) return false;
  //         return userId === postOwnerId;
  //       },
  //     },
  //   },
  //   admin: {
  //     static: [
  //       "posts:list",
  //       "posts:create",
  //       "posts:edit",
  //       "posts:delete",
  //       "users:get",
  //       "users:getSelf",
  //       "home-page:visit",
  //       "dashboard-page:visit",
  //     ],
  //   },
};

export default rules;
