import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ForwardIcon from "@material-ui/icons/Forward";
import axios from "axios";
import React from "react";
import { useHistory } from "react-router-dom";
import MaterialTable from "../utility/MaterialTable";

export default function CasesTable(props) {
  let history = useHistory();

  const [cases, setCases] = React.useState(null);

  const [loading, setLoading] = React.useState(false);

  const [openDialog, setOpenDialog] = React.useState(false);
  const [dataPeriods, setDataPeriods] = React.useState([]);
  const [selectedCase, setSelectedCase] = React.useState(null);
  const [selectedDataPeriod, setSelectedDataPeriod] = React.useState(null);
  const loadCases = () => {
    setLoading(true);
    axios
      .get("apa/api/cases/")
      .then((res) => {
        const data = res.data.map((_case) => ({
          ..._case,
          indications_display: _case.indications.map((indication) => indication.name).join(", "),
          indications: _case.indications,
          owner: _case.owner != null ? _case.owner.username : null,
          created_at: _case.created_at,
          modified_at: _case.created_at,
        }));
        setCases(data);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const deleteCase = (rowData) => {
    axios
      .delete(`apa/api/cases/${rowData.id}`)
      .then((res) => {
        const data = [...cases];
        data.splice(data.indexOf(rowData), 1);
        setCases(data);
      })
      .catch((error) => console.log(error));
  };

  React.useEffect(() => {
    if (cases == null && !loading) {
      loadCases();
    }
  }, [loadCases]);

  return (
    <React.Fragment>
      <MaterialTable
        columns={[
          { title: "Title", field: "title" },
          { title: "Indications", field: "indications_display" },
          { title: "Author", field: "author" },
          { title: "Organisation", field: "organisation" },
          { title: "Owner", field: "owner" },
          {
            title: "Last modified",
            field: "modified_at",
            filtering: false,
          },
          {
            title: "Created",
            field: "created_at",
            filtering: false,
          },
        ]}
        isLoading={loading}
        options={{
          actionsColumnIndex: -1,
          draggable: false,
          padding: "dense",
          pageSize: 10,
          pageSizeOptions: [10, 15, 20, 25],
          filtering: true,
          filterCellStyle: { paddingTop: "0px", paddingBottom: "8px" },
        }}
        data={cases == null ? [] : cases}
        title="Cases"
        toolbar={true}
        actions={[
          {
            icon: () => <ForwardIcon color="primary" />,
            tooltip: "Select case",
            onClick: (event, rowData) => {
              setSelectedCase(rowData.id);
              setOpenDialog(true);
              axios
                .get("apa/api/periods/", {
                  params: {
                    case_id: rowData.id,
                  },
                })
                .then((res) => {
                  setDataPeriods(res.data);
                })
                .catch((error) => {
                  console.log(error);
                });
            },
          },

          {
            icon: () => <EditIcon color="primary" />,
            tooltip: "Edit",
            onClick: (event, rowData) => {
              props.onEdit(rowData);
            },
          },
          {
            icon: () => <DeleteIcon color="error" />,
            tooltip: "Delete",
            onClick: (event, rowData) => {
              deleteCase(rowData);
            },
          },
        ]}
        detailPanel={(rowData) => {
          return (
            <Box pl={2} pt={1}>
              <Typography variant="body2" gutterBottom>
                {rowData.description}
              </Typography>
            </Box>
          );
        }}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
      />
      <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="xs" onEntering={() => null} open={openDialog}>
        <DialogTitle>Choose a data period</DialogTitle>
        <DialogContent dividers>
          <RadioGroup value={selectedDataPeriod} onChange={(e) => setSelectedDataPeriod(parseInt(e.target.value))}>
            <FormControlLabel value={0} control={<Radio />} label={"All periods"} />
            {dataPeriods.map((period) => (
              <FormControlLabel value={period.id} control={<Radio />} label={period.period} />
            ))}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setOpenDialog(false);
              setDataPeriods([]);
              setSelectedCase(null);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              localStorage.setItem("case_id", selectedCase);
              localStorage.setItem("case_title", cases.find((_case) => _case.id === selectedCase).title);

              if (selectedDataPeriod != 0) {
                localStorage.setItem("case_access_id", selectedDataPeriod);
                localStorage.setItem("period", dataPeriods.find((period) => period.id === selectedDataPeriod).period);
              } else {
                localStorage.removeItem("case_access_id");
                localStorage.removeItem("period");
              }

              history.go(0);
              history.push(`/`);
            }}
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
