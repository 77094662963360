import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      width="50%"
      m={2}
    >
      {value === index && (
        <Typography variant="body2" gutterBottom>
          {children}
        </Typography>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexGrow: 1,
    maxHeight: 1000,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function VerticalTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const case_id = localStorage.getItem("case_id");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <Tab label="Welcome" {...a11yProps(0)} />
        <Tab label="Video guide open questions" {...a11yProps(1)} />
        <Tab label="Video guide prescriptions" {...a11yProps(2)} />
        <Tab label="Adding model answers for open questions" {...a11yProps(3)} />
        <Tab label="Grading open questions" {...a11yProps(4)} />
        <Tab label="Adding grading schemes for prescriptions" {...a11yProps(5)} />
        <Tab label="Grading prescriptions" {...a11yProps(6)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Typography variant="body1">
          Welcome to the Pharmagrader automatic grading environment. In this section, all components are explained. A tutorial is given for
          each component, showing you how to create model answers for open questions and grading schemes for prescriptions, as well as use
          your created model answers and grading schemes to grade students.
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box>
          <Box>
            <Typography variant="h6">Open questions</Typography>
            <iframe
              width="1280"
              height="720"
              src="https://www.youtube.com/embed/YNKw1ASjUIA?rel=0"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </Box>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Box>
          <Box>
            <Typography variant="h6">Prescriptions</Typography>
            <iframe
              width="1280"
              height="720"
              src="https://www.youtube.com/embed/1WzJIU04ZSY?rel=0"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </Box>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Typography variant="body1">
          To grade open questions, first a model answer has to be added. This answer is compared to the student answer to check whether they
          have all the required information in their answer. For optimal results, please use a model answer which is concise but answers the
          question fully. To enter new model answers, go to <b>‘ Open questions’ → ‘Model answers’</b> or go{" "}
          <Link href="#" to={"/oqa/assesment/" + case_id}>
            here.
          </Link>{" "}
          <br></br>
          <br></br>
          Here, the different questions in the case are listed. To add an answer for a question, click on the plus icon, type your model
          answer in the line that appears and click the checkmark. The model answer is now saved. It is possible to remove your model
          answer, edit it, or add multiple model answers (although only one is used for grading).
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Typography variant="body1">
          After adding model answers for your questions, the student answers can be graded. Go to <b>‘Model answers’ → ‘Grade’</b> or go{" "}
          <Link href="#" to={"/oqa/assesment/grade/" + case_id}>
            here.
          </Link>{" "}
          . You can now select which questions you want to grade. For all of them, click on the checkbox next to ‘Question’. Now click on
          ‘Next’. After this, you can select your desired model answer for each question. Choose one, and click ‘Next’. In the next screen,
          your graded answers are shown. Note that you can scroll through all graded answers for a single question by clicking on the ‘next
          page’ arrow inside a question box, or scroll through all different questions by clicking on the navigation circles denoted by ‘1’,
          ‘ 2’ etc.
          <br></br>
          <br></br>
          It is now possible to review all grades. To edit a grade which you think is not correct, simply click on the grade itself, choose
          a new grade from the dropdown box and click the checkmark. Your edited grades are saved for this case, if you want to view the
          graded and reviewed results again, go to <b>‘Open questions’ → ‘Student answers’</b> or go{" "}
          <Link href="#" to={"/oqa/answers/" + case_id}>
            here.
          </Link>{" "}
          Note that if you grade again for the same question, your manually editted grades will be overwritten.
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Typography variant="body1" paragraph>
          Prescriptions filled in by the students are transferred from Pscribe to this application. The answers match the ones from your
          selected Pscribe group statistics page. They can be found under <b>'Prescriptions → Student Answers'</b> . To grade these you
          first need to fill in a grading scheme. To do this go to 'Prescriptions → Grading Schemes' page and press the bottom right +
          button. Alternatively you can press <Link to={"/apa/assesment/new/" + case_id}>here</Link>.<br></br> <br></br>
          The main information you need to fill in are MAUA grades for drug, administration form and strength combinations. These are grades
          from 0 to 10 on the effectieness, safety and applicability of the chosen combination. A new combination can be added to the
          grading scheme through the + button in the table. You can either search the combination through its IDM information or press on
          the search icon and search for a drug to start with. The drugs table also had the functionality to group the rows based on one of
          the headers. For example, by dragging the drug header to the designated area, all combinations with the same group will be grouped
          together.
          <br></br> <br></br>
          Other information you need to consider is the rules section. This is currently still in development, so there is only one rule
          available for testing purposes. A rule deducts points from the final grade if it is not satisfied. A rule can be turned on by
          pressing the checkbox. The points deducted when the rule is not satisfied can also be changed from the default value on the right
          of the rule. Lastly there is some general information that has to be filled in on the grading scheme in the information card. It
          can be saved using the button in the bottom right. Multiple grading schemes can be filled in and can be seen on the{" "}
          <b>'Prescriptions → Grading Schemes'</b> page.
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={6}>
        <Typography variant="body1">
          Once you've filled in a grading scheme you can start grading the prescriptions. To do this go to <b>'Prescriptions → Grade'</b> or{" "}
          <Link to={"/apa/assesment/grade/" + case_id}>here</Link>. Here you have a stepper that guides you through the grading process. In
          the first step you can select the prescriptions you want to use. To choose all select the topmost checkbox. The next step asks you
          to select a grading scheme. To view the details of a grading scheme press the detail button on the left of the corresponding row.
          Press the checkbox next to the grading scheme you want to use for grading. The final step displays the graded prescriptions.
          Pressing the detail button on the left of each row provides you with information on how the system determined the grade. If you
          don't agree with the grade you can adjust it by pressing on it. Now the grading is done! In the future these grades can be sent
          back to Pscribe.
        </Typography>
      </TabPanel>
    </div>
  );
}

// export default function FullWidthTabs() {
//   const classes = useStyles();
//   const [value, setValue] = React.useState(0);

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   return (
//     <div className={classes.root}>
//       <AppBar position="static" color="default">
//         <Tabs
//           orientation="vertical"
//           variant="scrollable"
//           value={value}
//           onChange={handleChange}
//           aria-label="Vertical tabs example"
//           className={classes.tabs}
//         >

//         </Tabs>
//       </AppBar>

//       <TabPanel value={value} index={0}>
//         Welcome to the ARB environment. API and testing grounds for an automatic prescription grading algorithm. This
//         page explains the workings of the webservice. There are 3 main components to the ARB that are all connected,
//         cases, prescriptions and grading schemes. Their connections and workings will be explained in the next sections.
//       </TabPanel>
//       <TabPanel value={value} index={1}>

//       </TabPanel>
//       <TabPanel value={value} index={3}>

//       </TabPanel>
//       <TabPanel value={value} index={4}>
//         Item Two
//       </TabPanel>
//       <TabPanel value={value} index={5}>
//         Item Three
//       </TabPanel>
//       <TabPanel value={value} index={6}>
//         Item Three
//       </TabPanel>
//     </div>
//   );
// }
