import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography,
  Divider,
  InputBase,
  Paper,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import React from "react";
import SelectList from "../utility/SelectList";
import { green } from "@material-ui/core/colors";
import AddIcon from "@material-ui/icons/Add";
const useStyles = makeStyles((theme) => ({
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  choiceField: {
    marginBottom: theme.spacing(2),
  },
  positionIndicator: {
    marginLeft: 8,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function MultipleChoiceQuestionCreateCard(props) {
  const classes = useStyles();

  const [state, setState] = React.useState({
    question: "",
    index: 0,
    step_index: 0,
    parent_step_index: 0,
    choices: [],
  });

  React.useEffect(() => {
    if (props.question) {
      setState({
        ...state,
        ...props.question,
      });
    }
  }, [props.question]);

  const handleSubmit = () => {
    if (props.mode === "edit") {
      axios.put(`apa/api/questions/mc/${state.id}/`, { ...state }).then((res) => {
        props.onClose();
        props.onSuccess();
      });
    } else {
      axios.post("apa/api/questions/mc/", { ...state, case_id: props.case_id }).then((res) => {
        props.onClose();
        props.onSuccess();
      });
    }
  };

  return (
    <Card>
      <CardContent style={{ height: 500, overflow: "scroll" }}>
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Typography variant="h5" component="h2">
            {props.mode === "edit" ? "Edit" : "New"} multiple-choice question
          </Typography>

          <IconButton onClick={props.onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
        {props.mode === "edit" ? (
          <Typography variant="caption" color="error">
            NOTE! Removing options changes their position causing current answers to not match anymore.
          </Typography>
        ) : null}

        <form>
          <Grid container spacing={3}>
            <Grid container item xs={12} spacing={2}>
              <Grid container direction="column" item xs={4}>
                <TextField
                  className={classes.textField}
                  label="Parent Step Index"
                  value={state.parent_step_index}
                  onChange={(e) => setState({ ...state, parent_step_index: e.target.value })}
                />
                <TextField
                  className={classes.textField}
                  label="Step Index"
                  value={state.step_index}
                  onChange={(e) => setState({ ...state, step_index: e.target.value })}
                />
                <TextField
                  className={classes.textField}
                  label="Index"
                  value={state.index}
                  onChange={(e) => setState({ ...state, index: e.target.value })}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  className={classes.textField}
                  fullWidth
                  multiline
                  rows="6"
                  label="Question"
                  value={state.question}
                  onChange={(e) => setState({ ...state, question: e.target.value })}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {state.choices.map((choice, i) => (
                <Paper className={classes.choiceField} elevation={1} variant="outlined" square>
                  <Box display="flex" alignItems="center">
                    <Typography className={classes.positionIndicator} variant="caption" color="textSecondary">
                      {String.fromCharCode(97 + i)}
                    </Typography>

                    <InputBase
                      className={classes.input}
                      value={state.choices[i].choice}
                      onChange={(e) => {
                        const choices = state.choices;
                        let changedChoice = state.choices[i];
                        changedChoice.choice = e.target.value;
                        choices[i] = changedChoice;
                        setState({ ...state, choices: choices });
                      }}
                    />
                    <Divider className={classes.divider} orientation="vertical" />
                    <IconButton
                      className={classes.iconButton}
                      onClick={(e) => {
                        const choices = state.choices;
                        choices.splice(i, 1);
                        setState({ ...state, choices: choices });
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Paper>
              ))}
              <Button
                variant="outlined"
                size="small"
                style={{ color: green[600] }}
                endIcon={<AddIcon>Add choice</AddIcon>}
                onClick={(e) => {
                  const choices = state.choices;
                  choices.push({ choice: "", position: choices.length });
                  setState({ ...state, choices: choices });
                }}
              >
                Add choice
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>

      <CardActions>
        <Button onClick={props.onClose} color="primary">
          Cancel
        </Button>
        <Button color="primary" onClick={handleSubmit}>
          {props.mode === "edit" ? "Save" : "Submit"}
        </Button>
      </CardActions>
    </Card>
  );
}
