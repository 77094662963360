import { Avatar, Button, Card, CardContent, CssBaseline, Grid, Link, TextField, Typography, Box, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import React from "react";
import { connect } from "react-redux";
import RouterLink from "react-router-dom/Link";
import { Field, reduxForm } from "redux-form";
import * as actions from "../../store/actions/auth";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  card: {
    margin: "auto",
    width: "25rem",
  },
}));

const renderTextField = ({ value, label, input, meta: { touched, invalid, error }, ...custom }) => (
  <TextField
    value={value}
    label={label}
    placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom}
    variant="outlined"
    fullWidth
    {...input}
  />
);
function UpdateProfile(props) {
  //id = localStorage.getItem("id")
  const [state, setState] = React.useState({
    username: "",
    email: "",
    first_name: "",
    last_name: "",
    password: "",
    role: "",
  });

  const GetUserInfo = () => {
    axios.get("apa/auth/users/" + localStorage.getItem("id")).then((res) => {
      const data = res.data;
      //setState({ ...state, data });
      setState({
        username: data.username,
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        role: data.groups[0].name,
      });
    });
  };

  React.useEffect(GetUserInfo, []);

  const classes = useStyles();

  const updateAuth = (values, event, props) => {
    props.onAuth(values.username, values.first_name, values.last_name);
  };

  return (
    <Container maxWidth="md">
      <Avatar className={classes.blue}></Avatar>
      <form className={classes.form} onSubmit={props.handleSubmit(updateAuth)}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <p>Username</p>
          </Grid>
          <Grid item xs={10}>
            <Field name="username" value={state.username} label={state.username} component={renderTextField} disabled={false} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <p>Email</p>
          </Grid>
          <Grid item xs={10}>
            <Field name="email" label={state.email} component={renderTextField} disabled={true} />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={2}>
            <p>First name</p>
          </Grid>
          <Grid item xs={10}>
            <Field name="first_name" value={state.first_name} label={state.first_name} component={renderTextField} disabled={false} />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={2}>
            <p>Last name</p>
          </Grid>
          <Grid item xs={10}>
            <Field name="last_name" value={state.last_name} label={state.last_name} component={renderTextField} disabled={false} />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={2}>
            <p>Role</p>
          </Grid>
          <Grid item xs={10}>
            <Field name="role" label={state.role} component={renderTextField} disabled={true} />
          </Grid>
        </Grid>

        <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
          Save
        </Button>
      </form>
    </Container>
  );
}

UpdateProfile = reduxForm({
  // a unique name for the form
  form: "update",
})(UpdateProfile);

const mapStateToProps = (state) => {
  return {
    errorstate: state.auth.error,
    error: state.auth.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (username, first_name, last_name) => dispatch(actions.authUpdate(username, first_name, last_name)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UpdateProfile);
