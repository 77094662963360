import { Box, Link, Paper, Typography, Button, ButtonGroup, Divider, IconButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import HelpIcon from "@material-ui/icons/Help";
import ReceiptIcon from "@material-ui/icons/ReceiptOutlined";
import DescriptionIcon from "@material-ui/icons/Description";
import ListIcon from "@material-ui/icons/List";
import React from "react";
import { useHistory } from "react-router-dom";
import Case from "../cases/Case";
import MultipleChoiceQuestionCard from "../questions/MultipleChoiceQuestionCard";
import OpenQuestionCard from "../questions/OpenQuestionCard";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
  },
  paper: {
    padding: theme.spacing(1, 2),
  },

  grade: {
    border: "1px solid #81c784",
  },
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

export default function Dashboard(props) {
  let history = useHistory();
  const case_id = localStorage.getItem("case_id");
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState({ open_questions: null, mc_questions: null });
  const classes = useStyles();

  const onErrorLoadingCase = () => {
    localStorage.removeItem("case_id");
    localStorage.removeItem("case_title");
  };

  const loadOpenQuestions = (case_id) => {
    // Load the case data from the backend
    axios
      .get("apa/api/questions/open/", {
        params: {
          case_id: case_id,
        },
      })
      .then((res) => {
        setState({ ...state, open_questions: res.data });
      })
      .catch((error) => console.log(error));
  };

  const loadMCQuestions = (case_id) => {
    // Load the case data from the backend
    axios
      .get("apa/api/questions/mc/", {
        params: {
          case_id: case_id,
        },
      })
      .then((res) => {
        setState({ ...state, mc_questions: res.data });
      })
      .catch((error) => console.log(error));
  };

  React.useEffect(() => {
    if (state.open_questions == null) {
      loadOpenQuestions(case_id);
    }
    if (state.mc_questions == null) {
      loadMCQuestions(case_id);
    }
  }, [loadOpenQuestions, loadMCQuestions]);

  const renderDashboard = () => {
    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Paper className={classes.grade}>
              <Box display="flex" pt={1} justifyContent="center">
                <Typography variant="h6" color="textPrimary" gutterBottom>
                  Grade
                </Typography>
              </Box>
              <Divider />
              <ButtonGroup className={classes.root} orientation="vertical" color="primary" variant="text">
                <Button size="small" startIcon={<DescriptionIcon />} onClick={() => history.push(`/oqa/assesment/grade/${case_id}`)}>
                  Open questions
                </Button>
                <Button size="small" startIcon={<ListIcon />} onClick={() => history.push(`/mca/assesment/grade/${case_id}`)}>
                  Multiple choice
                </Button>
                <Button size="small" startIcon={<ReceiptIcon />} onClick={() => history.push(`/apa/assesment/grade/${case_id}`)}>
                  Prescriptions
                </Button>
              </ButtonGroup>
              <Divider />
              <Box display="flex" pt={1} justifyContent="center">
                <Typography variant="h6" color="textPrimary" gutterBottom>
                  Assesment
                </Typography>
              </Box>

              <Divider />
              <ButtonGroup className={classes.root} orientation="vertical" color="primary" variant="text">
                <Button size="small" onClick={() => history.push(`/oqa/assesment/${case_id}`)}>
                  Open Question Model Answers
                </Button>
                <Button size="small" onClick={() => history.push(`/mca/assesment/${case_id}`)}>
                  Prescription Grading schemes
                </Button>
              </ButtonGroup>
            </Paper>

            <IconButton onClick={() => history.push(`/help`)}>
              <HelpIcon fontSize="large" />
            </IconButton>
          </Grid>
          <Grid item xs={3}>
            <Case id={case_id} onError={onErrorLoadingCase} />
          </Grid>
          <Grid item xs>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Typography variant="h6">Open questions</Typography>
                </Paper>
              </Grid>

              {state.open_questions
                ? state.open_questions.map((question) => (
                    <Grid item xs={"auto"}>
                      <OpenQuestionCard question={question} />
                    </Grid>
                  ))
                : null}
            </Grid>
          </Grid>
          {state.mc_questions && state.mc_questions.length ? (
            <Grid item xs={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <Typography variant="h6">Multiple-choice questions</Typography>
                  </Paper>
                </Grid>

                {state.mc_questions.map((question) => (
                  <Grid item xs={6}>
                    <MultipleChoiceQuestionCard question={question} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ) : null}
          {/* <Grid item xs>
          <PrescriptionsTable case_id={case_id} mode="dashboard" />
        </Grid> */}
        </Grid>
      </React.Fragment>
    );
  };

  const renderNotAvailable = () => {
    return (
      <Box width={0.25}>
        <Paper>
          <Box bgcolor="text.secondary" color="background.paper" p={2}>
            <Typography variant="body1">
              Please choose a case to work with first <Link href="/cases/testing">here</Link>.
            </Typography>
          </Box>
        </Paper>
      </Box>
    );
  };

  return <Box>{case_id ? renderDashboard() : renderNotAvailable()}</Box>;
}
