import { Box, Grid, makeStyles, Typography, Card } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  box: {
    margin: theme.spacing(1, 2),
  },
}));

export default function Prescription(props) {
  const classes = useStyles();
  const prescription = props.prescription;

  const renderSigna = (type) => {
    if (type === "open") {
      return (
        <React.Fragment>
          <Box>
            <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
              {prescription.signa}
            </Typography>
          </Box>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Box display="flex">
            <Box mr={3} fontWeight="fontWeightBold">
              S.
            </Box>
            <Box>
              {prescription.frequency}x {prescription.quantity} {prescription.intake_unit}
            </Box>
          </Box>
          <Box display="flex">
            <Box mr={3} fontWeight="fontWeightBold">
              Therapy time
            </Box>
            <Box>
              {prescription.therapy_time} {prescription.therapy_unit}
            </Box>
          </Box>
          <Box>
            <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
              {prescription.instructions}
            </Typography>
          </Box>
        </React.Fragment>
      );
    }
  };
  return (
    <Grid container spacing={3}>
      <Grid className={classes.box} item xs={12}>
        <Box borderBottom={1} height={35}>
          <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
            {prescription.address_gp}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box height="100%" borderRight={1}></Box>
      </Grid>
      <Grid item xs>
        <Box display="flex" justifyContent="space-between">
          <img src={"https://www.freeiconspng.com/uploads/rx-logo-image-1.png"} alt="Rx" width="25" height="25" />
          <Box mr={2}>{prescription.created_at}</Box>
        </Box>
        <Box mt={1} display="flex" flexDirection="column">
          <Box mr={2}>{prescription.drug}</Box>
          <Box mr={2}>{prescription.administration_form}</Box>
          <Box mr={2}>{prescription.strength}</Box>
        </Box>
        <Box mt={2} display="flex">
          <Box mr={2} fontWeight="fontWeightBold">
            da
          </Box>
          <Box width="100%">{prescription.total_quantity}</Box>
        </Box>
        {renderSigna(prescription.type)}
      </Grid>
      <Grid className={classes.box} item xs={12}>
        <Box borderTop={1} mt={1} height={35} borderColor="grey.500">
          <Typography variant="body2" style={{ whiteSpace: "pre-line" }} gutterBottom>
            {prescription.address_patient}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
