import { Box, Container, Fab, Grid, makeStyles, Paper, Slider, Snackbar, TextField, Tooltip, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import axios from "axios";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import IDMDialog from "../utility/IDMDialog";
import MaterialTable from "../utility/MaterialTable";
import SnackbarContentWrapper from "../utility/SnackbarContentWrapper";
import Rules from "./Rules";

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
    "&:hover": {
      color: "white",
    },
  },
  paper: {
    padding: theme.spacing(1, 2),
  },
}));

const marks = [
  { value: 0, label: 0 },
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 },
];

//TODO add form validation possibly using custom edit component
export default function GradingScheme(props) {
  let history = useHistory();
  const classes = useStyles();
  const case_id = localStorage.getItem("case_id");
  const mode = props.mode;
  const [state, setState] = React.useState({
    description: "",
    target_group: "",
    rules: null,
    columns: [
      { title: "Drug", field: "drug", editable: "never" },
      { title: "Administration form", field: "administration_form", editable: "never" },
      { title: "Strength", field: "strength", editable: "never" },
      {
        title: "Effectiveness",
        field: "effectiveness",
        width: 20,
        type: "numeric",
        editComponent: (props) => (
          <Slider
            defaultValue={props.value}
            onChangeCommitted={(event, newValue) => props.onChange(newValue)}
            step={0.5}
            min={0}
            max={10}
            marks={[
              { value: 0, label: 0 },
              { value: 5, label: 5 },
              { value: 10, label: 10 },
            ]}
            valueLabelDisplay="on"
            style={{ zIndex: 9999 }}
          />
        ),
      },
      {
        title: "Safety",
        field: "safety",
        width: 20,
        type: "numeric",
        editComponent: (props) => (
          <Slider
            defaultValue={props.value}
            onChangeCommitted={(event, newValue) => props.onChange(newValue)}
            step={0.5}
            min={0}
            max={10}
            marks={[
              { value: 0, label: 0 },
              { value: 5, label: 5 },
              { value: 10, label: 10 },
            ]}
            valueLabelDisplay="on"
            style={{ zIndex: 9999 }}
          />
        ),
      },
      {
        title: "Applicability",
        field: "applicability",
        width: 20,
        type: "numeric",
        editComponent: (props) => (
          <Slider
            defaultValue={props.value}
            onChangeCommitted={(event, newValue) => props.onChange(newValue)}
            step={0.5}
            min={0}
            max={10}
            marks={[
              { value: 0, label: 0 },
              { value: 5, label: 5 },
              { value: 10, label: 10 },
            ]}
            valueLabelDisplay="on"
            style={{ zIndex: 9999 }}
          />
        ),
      },
    ],
    data: null,
    // [
    //   {
    //     drug: "Paracetamol",
    //     administration_form: "Tablet",
    //     strength: "400 mg",
    //     effectiveness: 10,
    //     safety: 5,
    //     applicability: 1,
    //     drug_id: "paracetamol",
    //     administration_form_id: "lotion",
    //     strength_id: 5015
    //   }
    // ]
  });
  const [chooseIDM, setChooseIDM] = React.useState(false);
  const [openSuccessMsg, setOpenSucessMsg] = React.useState(false);
  const [succesMsg, setSuccesMsg] = React.useState("");
  const id = props.id ? props.id : props.match.params.id;
  const loadGradingScheme = (id) => {
    axios
      .get("apa/api/grading/gradingschemes/" + id)
      .then((res) => {
        // TODO display other grading scheme data
        const MAUA_scores = res.data.MAUA_scores.map((MAUA_score) => ({
          ...MAUA_score,
          drug: MAUA_score.drug.name,
          drug_id: MAUA_score.drug.reference_name,
          administration_form: MAUA_score.administration_form.signature_form,
          administration_form_id: MAUA_score.administration_form.signature_form,
          strength: MAUA_score.strength.name,
          strength_id: MAUA_score.strength.id,
        }));

        let rules = res.data.rules.map((rule) => ({ ...rule }));
        delete res.data.MAUAScores;
        delete res.data.rules;
        setState({ ...state, ...res.data, rules: rules, data: MAUA_scores });
      })
      .catch((error) => console.log(error));
  };

  React.useEffect(() => {
    if (state.data == null && (mode === "details" || mode === "edit")) {
      loadGradingScheme(id);
    } else if (state.rules == null) {
      loadRules();
    }
  });

  const loadRules = () => {
    axios
      .get("apa/api/grading/gradingschemes/rules/")
      .then((res) => {
        setState({ ...state, rules: res.data.map((rule) => ({ ...rule })) });
      })
      .catch((error) => console.log(error));
  };

  const handleRulesChange = (rules) => {
    setState({ ...state, rules: rules });
  };

  const handleSubmitIDM = (values) => {
    const newData = {
      ...values,
      drug: values.drug.label,
      drug_id: values.drug.value,
      administration_form: values.administration_form.value,
      strength: values.strength.label,
    };
    const data = state.data == null ? [] : [...state.data];
    data.push(newData);
    setState({ ...state, data });
  };

  const handleSubmitGradingScheme = () => {
    let mauaScores = [];
    if (state.data != null) {
      mauaScores = state.data.map((row) => ({
        drug: row.drug_id,
        administration_form: row.administration_form,
        strength: row.strength,
        effectiveness: row.effectiveness,
        safety: row.safety,
        applicability: row.applicability,
      }));
    }
    const data = {
      MAUA_scores: mauaScores,
      case: case_id,
      target_group: state.target_group,
      description: state.description,
      rules: state.rules,
    };

    axios.post("apa/api/grading/gradingschemes/", data).then(
      (response) => {
        history.push(`/apa/assesment/scheme/${response.data.id}`);
        // TODO figure out a way to make this work
        openSuccesMsg("Added new grading scheme!");
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const updateGradingScheme = () => {
    const mauaScores = state.data.map((row) => ({
      drug: row.drug_id,
      administration_form: row.administration_form,
      strength: row.strength,
      effectiveness: row.effectiveness,
      safety: row.safety,
      applicability: row.applicability,
    }));
    const data = {
      MAUA_scores: mauaScores,
      target_group: state.target_group,
      description: state.description,
      rules: state.rules,
    };
    axios.put(`apa/api/grading/gradingschemes/${id}/`, data).then(
      (response) => {
        openSuccesMsg("Saved grading scheme!");
        history.push(`/apa/assesment/scheme/${id}`);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const openSuccesMsg = (msg) => {
    setSuccesMsg(msg);
    setOpenSucessMsg(true);
  };
  const handleSuccesMsgClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSucessMsg(false);
  };

  const renderMode = (mode, id) => {
    switch (mode) {
      case "new":
        return (
          <Container>
            <MaterialTable
              title="Drugs"
              columns={state.columns}
              options={{
                actionsColumnIndex: state.columns.length,
                padding: "dense",
                search: false,
                grouping: true,
                pageSize: 10,
                minBodyHeight: 400,
                maxBodyHeight: 500,
              }}
              data={state.data == null ? [] : state.data}
              actions={[
                {
                  icon: () => <AddIcon color="primary" />,
                  tooltip: "Add drug row",
                  isFreeAction: true,
                  onClick: (event) => setChooseIDM(true),
                },

                {
                  icon: () => <DeleteIcon color="error" />,
                  tooltip: "Delete",
                  onClick: (event, rowData) => {
                    const data = [...state.data];
                    data.splice(data.indexOf(rowData), 1);
                    setState({ ...state, data });
                  },
                },
              ]}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      const data = [...state.data];
                      data[data.indexOf(oldData)] = newData;
                      setState({ ...state, data });
                    }, 100);
                  }),
              }}
            />
            <Tooltip title="Save">
              <Fab className={classes.fab} color="primary" onClick={handleSubmitGradingScheme}>
                <SaveIcon />
              </Fab>
            </Tooltip>
          </Container>
        );
      case "details":
        return (
          <Container>
            <MaterialTable
              title="Drugs"
              columns={state.columns}
              data={state.data == null ? [] : state.data}
              options={{
                grouping: true,
                pageSize: 5,
                minBodyHeight: 400,
                maxBodyHeight: 400,
              }}
            />
            {props.hideButton ? null : (
              <Tooltip title="Edit">
                <Fab
                  className={classes.fab}
                  color="secondary"
                  component={(props) => <Link to={"/apa/assesment/scheme/edit/" + id} {...props} />}
                >
                  <EditIcon />
                </Fab>
              </Tooltip>
            )}
          </Container>
        );
      case "edit":
        return (
          <Container>
            <MaterialTable
              title="Drugs"
              columns={state.columns}
              options={{
                actionsColumnIndex: state.columns.length,
                padding: "dense",
                search: false,
                grouping: true,
                pageSize: 10,
                minBodyHeight: 400,
                maxBodyHeight: 500,
              }}
              data={state.data == null ? [] : state.data}
              actions={[
                {
                  icon: () => <AddIcon color="primary" />,
                  tooltip: "Add drug row",
                  isFreeAction: true,
                  onClick: (event) => setChooseIDM(true),
                },
                {
                  icon: () => <DeleteIcon color="error" />,
                  tooltip: "Delete",
                  onClick: (event, rowData) => {
                    const data = [...state.data];
                    data.splice(data.indexOf(rowData), 1);
                    setState({ ...state, data });
                  },
                },
              ]}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      const data = [...state.data];
                      data[data.indexOf(oldData)] = newData;
                      setState({ ...state, data });
                    }, 0);
                  }),
              }}
            />
            <Tooltip title="Save">
              <Fab className={classes.fab} color="primary" onClick={updateGradingScheme}>
                <SaveIcon />
              </Fab>
            </Tooltip>
          </Container>
        );
      default:
        return null;
    }
  };

  const handleInputChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.value });
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Paper className={classes.paper}>
            <Box mb={2}>
              <Box display="flex" alignItems="flex-end" justifyContent="space-between">
                <Typography variant="h6">Information</Typography>
                <Typography variant="subtitle2">{state.created_at}</Typography>
              </Box>

              <TextField
                fullWidth
                label="Target group"
                margin="normal"
                required
                variant="outlined"
                helperText="For example, 3rd year students."
                value={state.target_group}
                disabled={mode === "details" ? true : false}
                onChange={handleInputChange("target_group")}
              />
              <TextField
                label="Description (optional)"
                multiline
                fullWidth
                variant="outlined"
                value={state.description}
                disabled={mode === "details" ? true : false}
                onChange={handleInputChange("description")}
              />
            </Box>
          </Paper>
          <Box mt={2}>
            <Rules rules={state.rules} mode={mode} onChange={handleRulesChange} />
          </Box>
        </Grid>

        <Grid item xs={8}>
          {renderMode(mode, id)}
        </Grid>
      </Grid>
      {mode !== "details" ? (
        <IDMDialog
          open={chooseIDM}
          onClose={() => {
            setChooseIDM(false);
          }}
          onSubmit={handleSubmitIDM}
          MAUA
        />
      ) : null}

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSuccessMsg}
        autoHideDuration={6000}
        onClose={handleSuccesMsgClose}
      >
        <SnackbarContentWrapper onClose={handleSuccesMsgClose} variant="success" message={succesMsg} />
      </Snackbar>
    </Box>
  );
}
