import { AppBar as MuiAppbar, Button, IconButton, makeStyles, Menu, MenuItem, Toolbar, Typography } from "@material-ui/core";

import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import React from "react";
import { connect } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { logout } from "../../store/actions/auth";

// Top navigation bar
// Contains the common action between pages of the application like logout and viewing the user profile

// Drawer width to determine the shift of the navigation bar when the drawer is collapsed
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  grow: {
    flexGrow: 1,
  },
  logout: {
    marginLeft: theme.spacing(2),
  },
}));

function AppBar(props) {
  const classes = useStyles();
  let history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const profileButtonRef = React.useRef();

  // Currently unused vars for mobile app bar
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(profileButtonRef.current);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("api_auth");
    history.push("/");
    props.logout();
  };

  return (
    <MuiAppbar
      className={clsx(classes.appBar, {
        [classes.appBarShift]: props.openDrawer,
      })}
      position="fixed"
    >
      {/* The AppBar itself is within the toolbar */}
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={props.onDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, props.openDrawer && classes.hide)}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          Pharmagrader
        </Typography>
        <div className={classes.grow} />
        <Typography variant="h6" noWrap>
          Welcome! {props.username}
        </Typography>
        <div className={classes.sectionDesktop}>
          <IconButton edge="end" onClick={handleProfileMenuOpen} color="inherit" ref={profileButtonRef}>
            <AccountCircle />
          </IconButton>
          <Button className={classes.logout} color="inherit" onClick={handleLogout}>
            Logout
          </Button>
        </div>
      </Toolbar>
      {/* Component that holds the user menu */}
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
        getContentAnchorEl={null}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem component={Link} to="/profile" onClick={handleMenuClose}>
          {" "}
          Profile
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>My account</MenuItem>
      </Menu>
    </MuiAppbar>
  );
}
const mapDispatchToProps = (dispatch) => {
  // Function to dispatch logout when user presses logout button
  return {
    logout: () => dispatch(logout()),
  };
};

const mapStateToProps = (state) => {
  return { username: state.auth.username };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppBar);
