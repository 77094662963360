import React from "react";
import PropTypes from "prop-types";
import OpenQuestionCard from "./OpenQuestionCard";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Hidden } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

OpenQuestionAnswersSelect.propTypes = {
  questions: PropTypes.arrayOf(Object),
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginRight: theme.spacing(1, 2),
  },

  pagination: {
    marginRight: "auto !important",
    marginLeft: "auto !important",
    marginTop: "8px",
  },
}));

function OpenQuestionAnswersSelect(props) {
  const classes = useStyles();

  const [currentQuestions, setCurrentQuestions] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const numberOfItemsPerPage = 3;

  const handleChange = (event, value) => {
    setCurrentQuestions(props.questions.slice((value - 1) * numberOfItemsPerPage, value * numberOfItemsPerPage));
    setPage(value);
  };

  React.useEffect(() => {
    if (!currentQuestions.length) {
      setCurrentQuestions(props.questions.slice((page - 1) * numberOfItemsPerPage, page * numberOfItemsPerPage));
    }
  }, []);

  return (
    <React.Fragment>
      <Box mb={32}>
        <Grid container spacing={3} justify="center">
          {props.questions.map((question) => (
            <Grid component={Box} item xs={4} display={currentQuestions.some((item) => item.id == question.id) ? "inline" : "none"}>
              <OpenQuestionCard
                question={question}
                type={"grading"}
                onModelAnswerSelection={(model_answer) => {
                  props.onSelectionChange(model_answer, question.id);
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box position="fixed" bottom={0} mb={2} display="flex" width={0.75} justifyContent="center">
        <Pagination
          className={classes.pagination}
          color="primary"
          variant="outlined"
          size="large"
          showFirstButton
          showLastButton
          count={Math.ceil(props.questions.length / numberOfItemsPerPage)}
          page={page}
          onChange={handleChange}
        />
      </Box>
    </React.Fragment>
  );
}

export default OpenQuestionAnswersSelect;
