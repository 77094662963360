import {
  Box,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  Slide,
  Dialog,
  Button,
  Card,
  CardHeader,
  CardContent,
  IconButton,
} from "@material-ui/core";
import { green, red, lightGreen, deepOrange } from "@material-ui/core/colors";
import { createMuiTheme, MuiThemeProvider, makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import React from "react";
import MaterialTable from "../utility/MaterialTable";
import Prescription from "./Prescription";
import GradingResult from "../gradingSchemes/GradingResult";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";

PrescriptionsTable.propTypes = {
  mode: PropTypes.string.isRequired,
  graded: PropTypes.bool,
  title: PropTypes.string,
  onEdit: PropTypes.func,
  loading: PropTypes.bool,
  prescriptions: PropTypes.array,
};

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
    "&:hover": {
      color: "white",
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

export default function PrescriptionsTable(props) {
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  // Base columns
  const columns = [
    { title: "Type", field: "type", editable: "never" },
    { title: "Drug", field: "drug", editable: "never" },
    { title: "Administration form", field: "administration_form", editable: "never" },
    { title: "Strength", field: "strength", editable: "never" },
  ];
  const tableRef = React.createRef();
  // Add extra columns based on mode

  if (props.mode === "answers" || props.mode === "testing") {
    columns.push({
      title: "Da",
      field: "total_quantity",
      editable: "never",
    });
    // columns.push({
    //   title: "Quantity",
    //   field: "quantity",
    //   editable: "never",
    // });
    // columns.push({
    //   title: "Frequency",
    //   field: "frequency",
    //   editable: "never",
    // });
    // columns.push({
    //   title: "Unit",
    //   field: "intake_unit",
    //   editable: "never",
    // });
    // columns.push({
    //   title: "Therapy time",
    //   field: "therapy_time",
    //   editable: "never",
    // });
    // columns.push({
    //   title: "Unit",
    //   field: "therapy_unit",
    //   editable: "never",
    // });
  }
  if (props.mode === "testing") {
    columns.push({
      title: "Last modified",
      field: "modified_at",
      editable: "never",
    });
    columns.push({
      title: "Created",
      field: "created_at",
      editable: "never",
    });
    columns.push({
      title: "Owner",
      field: "owner",
      editable: "never",
    });
  }

  if (props.graded) {
    columns.push({
      title: "Grade",
      field: "grade",
      emptyValue: "N/A",
    });
  }

  const [prescriptions, setPrescriptions] = React.useState(null);

  const [selectedRows, setSelectedRows] = React.useState([]);

  const theme = createMuiTheme({
    palette: {
      secondary: {
        main: "#618833",
      },
    },
  });

  const loadPrescriptions = () => {
    setLoading(true);
    axios
      .get("apa/api/answers/prescriptions/", {
        params: {
          case_id: props.case_id,
          case_access_id: localStorage.getItem("case_acces_id"),
        },
      })
      .then((res) => {
        const data = res.data.map((prescription) => ({
          ...prescription,
          drug: prescription.drug ? prescription.drug.name : null,
          administration_form: prescription.administration_form ? prescription.administration_form.signature_form : null,
          strength: prescription.strength ? prescription.strength.name : null,
          grade: prescription.grading_result ? Math.round((prescription.grading_result.grade + Number.EPSILON) * 100) / 100 : null,
          created_at: new Date(prescription.created_at).toLocaleDateString(),
          modified_at: new Date(prescription.modified_at).toLocaleDateString(),
          owner: prescription.owner ? prescription.owner.username : null,
        }));

        setPrescriptions(data);

        setLoading(false);
      })
      .catch((error) => {
        setPrescriptions([]);
        setLoading(false);
      });
  };

  const tableActions = () => {
    let actions = [];

    switch (props.mode) {
      case "testing":
        actions = [
          {
            icon: () => <EditIcon color="primary" />,
            tooltip: "Edit",
            onClick: (event, rowData) => {
              props.onEdit(rowData);
            },
          },
          {
            icon: () => <DeleteIcon color="error" />,
            tooltip: "Delete",
            onClick: (event, rowData) => {
              deletePrescription(rowData);
            },
          },
        ];
        break;
      case "answers":
        break;

      default:
        break;
    }

    if (props.selection) {
      actions.push = {
        tooltip: "Grade all selected prescriptions",
        icon: () => <CheckIcon fontSize="large" style={{ color: green[500] }} />,
        onClick: (event, data) => props.onGrade(),
      };
    }
    return actions;
  };
  const deletePrescription = (rowData) => {
    axios
      .delete(`apa/api/answers/prescriptions/${rowData.student_id}`)
      .then((res) => {
        if (props.onDelete) {
          props.onDelete(rowData);
        } else {
          const data = [...prescriptions];
          data.splice(data.indexOf(rowData), 1);
          setPrescriptions(data);
        }
      })
      .catch((error) => console.log(error));
  };

  const editGrade = (newData, oldData) => {
    axios.post(`apa/api/answers/prescriptions/${newData.id}/update_grade/`, { grade: newData.grade, manual: "True" }).then((res) => {
      if (props.prescriptions) {
        props.onEditGrade(newData, oldData);
      } else {
        const data = [...prescriptions];
        data[data.indexOf(oldData)] = newData;
        setPrescriptions([...data]);
      }
    });
  };

  React.useEffect(() => {
    if (props.case_id && !props.prescriptions && prescriptions == null && !loading) {
      loadPrescriptions();
    }
  }, [loadPrescriptions]);

  const choose_data = () => {
    if (props.prescriptions) {
      return props.prescriptions;
    } else {
      return prescriptions == null ? [] : prescriptions;
    }
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const grade_to_color = (grade) => {
    const intensity_max = 500;
    const intensity_min = 200;
    if (grade < 5.0) {
      const oldRange = 5.0 - 0;
      const newRange = intensity_max - intensity_min;
      let intensity = ((grade - 0) * newRange) / oldRange + 100;
      intensity = intensity_max - Math.round(intensity / 100) * 100;

      return red[intensity];
    } else {
      const oldRange = 10 - 5.0;
      const newRange = intensity_max - intensity_min;
      let intensity = ((grade - 5.0) * newRange) / oldRange + 100;
      intensity = Math.round(intensity / 100) * 100;
      return lightGreen[intensity];
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <MaterialTable
        tableRef={tableRef}
        columns={columns}
        isLoading={"loading" in props ? props.loading : loading}
        options={{
          draggable: false,
          actionsColumnIndex: columns.length,
          padding: "dense",
          selection: props.selection,
          pageSize: 10,
          exportButton: props.export,
          pageSizeOptions: [10],
          maxBodyHeight: 500,
          rowStyle: (rowData) => {
            if (rowData.grade === null || !columns.some((e) => e.field == "grade")) {
              return {};
            } else {
              return {
                backgroundColor: grade_to_color(rowData.grade),
                borderBottom: `2px solid ${grade_to_color(rowData.grade)}`,
              };
            }
          },
        }}
        cellEditable={
          props.graded
            ? {
                onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                  return new Promise((resolve, reject) => {
                    if (columnDef.field == "grade") {
                      let answer = rowData;
                      answer["grade"] = newValue;
                      editGrade(answer, rowData);
                    }
                    setTimeout(resolve, 500);
                  });
                },
              }
            : null
        }
        data={choose_data()}
        actions={tableActions()}
        onSelectionChange={(rows) => (props.onChoose ? props.onChoose(rows) : setSelectedRows(rows))}
        title={props.title ? props.title : "Prescriptions"}
        detailPanel={(rowData) => {
          return (
            <Dialog
              open={true}
              TransitionComponent={Transition}
              maxWidth={1000}
              onBackdropClick={() => {
                tableRef.current.onToggleDetailPanel([rowData.tableData.id], tableRef.current.props.detailPanel);
              }}
            >
              {/* <AppBar className={classes.appBar}>
                <Toolbar>
                  <Typography variant="h6" className={classes.title}>
                    Prescription
                  </Typography>
                  <Button
                    autoFocus
                    color="inherit"
                    onClick={() => {
                      tableRef.current.onToggleDetailPanel([rowData.tableData.id], tableRef.current.props.detailPanel);
                    }}
                  >
                    <CloseIcon />
                  </Button>
                </Toolbar>
              </AppBar> */}
              <Box width={1000}>
                <Card raised>
                  <CardHeader
                    title="Prescription"
                    style={{ backgroundColor: deepOrange[200] }}
                    action={
                      <IconButton
                        autoFocus
                        color="inherit"
                        onClick={() => {
                          tableRef.current.onToggleDetailPanel([rowData.tableData.id], tableRef.current.props.detailPanel);
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    }
                  />
                  <CardContent>
                    <Grid container>
                      <Grid item xs>
                        <Prescription prescription={rowData} />
                      </Grid>
                      {props.graded && rowData.grading_result ? (
                        <Grid item xs={8}>
                          <GradingResult data={rowData.grading_result} />
                        </Grid>
                      ) : null}
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Dialog>

            // <Box pl={2} pt={1}>
            //   <Typography variant="body2" gutterBottom>
            //     {rowData.description}
            //   </Typography>
            // </Box>
          );

          return (
            <Box maxWidth={700}>
              <Grid container>
                <Grid item xs>
                  <Prescription prescription={rowData} />
                </Grid>
                <Grid item xs></Grid>
                {props.graded && rowData.grading_result ? (
                  <Grid item xs={5}>
                    <GradingResult data={rowData.grading_result} />
                  </Grid>
                ) : null}
              </Grid>
            </Box>
          );
        }}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
      />
    </MuiThemeProvider>
  );
}
