import { Avatar, Button, Card, CardContent, CssBaseline, Grid, Link, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import React from "react";
import { connect } from "react-redux";
import RouterLink from "react-router-dom/Link";
import { Field, reduxForm } from "redux-form";
import * as actions from "../../store/actions/auth";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  card: {
    margin: "auto",
    width: "25rem",
  },
}));

const renderTextField = ({ label, input, meta: { touched, invalid, error }, ...custom }) => (
  <TextField
    label={label}
    placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    variant="outlined"
    fullWidth
    {...input}
    {...custom}
  />
);

function ResetPassword(props) {
  const submitAuth = (values, event, props) => {
    props.onAuth(values.username, values.password);
  };

  const sendResetLink = (values, props) => {
    delete axios.defaults.headers.common["Authorization"];
    axios.post("/apa/password_reset/", {
      email: values.email,
    });
    //.post("/rest-auth/password/reset/", {
    // .post("/apa/password_reset/", {
    //   email: values.email,
    // })
  };

  //alert(props.error);
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset password
          </Typography>

          <form className={classes.form} onSubmit={props.handleSubmit(sendResetLink)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div>
                  <Field name="email" label="Email" component={renderTextField} />
                </div>
              </Grid>

              <Grid item xs={12}>
                {props.errorstate ? (
                  <Typography color="error" variant="subtitle1">
                    Email not found
                  </Typography>
                ) : null}
              </Grid>
            </Grid>

            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
              Send reset link
            </Button>
          </form>
        </div>
      </CardContent>
    </Card>
  );
}

ResetPassword = reduxForm({
  // a unique name for the form
  form: "login",
})(ResetPassword);

const mapStateToProps = (state) => {
  return {
    errorstate: state.auth.error,
    error: state.auth.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (username, password) => dispatch(actions.authLogin(username, password)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
