import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
  LinearProgress,
  Paper,
  Fab,
  Grid,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import CheckIcon from "@material-ui/icons/Check";
import ReactExport from "react-data-export";
import Alert from "@material-ui/lab/Alert";
import GetAppIcon from "@material-ui/icons/GetApp";
import Pagination from "@material-ui/lab/Pagination";
import MultipleChoiceQuestionCard from "../questions/MultipleChoiceQuestionCard";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ["Select correct choices", "Results"];
}

export default function MultipleChoiceGradingStepper(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const case_id = props.match.params.case_id;
  const [loading, setLoading] = React.useState(false);
  const [questions, setQuestions] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const numberOfItemsPerPage = 6;
  const filename = localStorage.getItem("period") ? localStorage.getItem("period") : "Multiple Choice Graded Answers";

  const loadMCQuestions = (case_id) => {
    // Load the case data from the backend
    setLoading(true);
    axios
      .get("apa/api/questions/mc/", {
        params: {
          case_id: case_id,
          case_access_id: localStorage.getItem("case_acces_id"),
          include_answers: true,
        },
      })
      .then((res) => {
        let tempQuestions = res.data;
        tempQuestions = tempQuestions.map((question) => ({
          ...question,
          answer: question.choices.find((choice) => choice.correct)
            ? String.fromCharCode(97 + question.choices.find((choice) => choice.correct).position)
            : null,
          answers: question.answers.map((answer) => ({
            ...answer,
            answer_position: String.fromCharCode(97 + answer.choice.position),
            answer_choice: answer.choice.choice,
          })),
        }));
        setQuestions(tempQuestions);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };
  React.useEffect(() => {
    if (questions == null) {
      loadMCQuestions(case_id);
    }
  }, [loadMCQuestions]);

  const gradeQuestions = () => {
    let graded_questions = questions;
    graded_questions = graded_questions.map((question) => ({
      ...question,
      answers: question.answers.map((answer) => ({
        ...answer,
        answer_position: String.fromCharCode(97 + answer.choice.position),
        answer_choice: answer.choice.choice,
        correct: answer.choice.correct ? "Yes" : "No",
      })),
    }));
    setQuestions(graded_questions);
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <React.Fragment>
            {loading ? (
              <LinearProgress />
            ) : questions && questions.length ? (
              <React.Fragment>
                <Box mb={2} display="flex" alignItems="center">
                  <Box flexGrow={1}>
                    <Alert severity="info">
                      {localStorage.getItem("period")
                        ? `Answers are from the period: ${localStorage.getItem("period")}`
                        : "Answers are from all periods."}
                    </Alert>
                  </Box>
                </Box>

                <Grid container spacing={2}>
                  {questions.slice((page - 1) * numberOfItemsPerPage, page * numberOfItemsPerPage).map((question, index) => (
                    <Grid item xs={4}>
                      <MultipleChoiceQuestionCard
                        key={page}
                        question={question}
                        type={"assesment"}
                        onChangeChoices={(newChoices) => {
                          const newData = [...questions];
                          newData[index + numberOfItemsPerPage * (page - 1)].choices = newChoices;
                          setQuestions(newData);
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </React.Fragment>
            ) : (
              <Alert severity="info">No multiple choice questions exist for this case.</Alert>
            )}
          </React.Fragment>
        );
      case 1:
        return (
          <React.Fragment>
            <Box mb={2} display="flex" alignItems="center">
              <ExcelFile
                filename={filename}
                element={
                  <Tooltip title="Download data">
                    <IconButton color="primary" component="span">
                      <GetAppIcon fontSize="medium" />
                    </IconButton>
                  </Tooltip>
                }
              >
                <ExcelSheet data={questions} name="Overview">
                  <ExcelColumn label="Parent Step" value="parent_step_index" />
                  <ExcelColumn label="Step" value="step_index" />
                  <ExcelColumn label="Index" value="index" />
                  <ExcelColumn label="Question" value="question" />
                  <ExcelColumn label="Answer" value="answer" />
                </ExcelSheet>
                {questions.map((question) => (
                  <ExcelSheet data={question.answers} name={`Question ${question.position}`}>
                    <ExcelColumn label="Student ID" value="student" />
                    <ExcelColumn label="Answer" value="answer_position" />
                    <ExcelColumn label="Text" value="answer_choice" />
                    <ExcelColumn label="Correct" value="correct" />
                  </ExcelSheet>
                ))}
              </ExcelFile>
              <Box flexGrow={1}>
                <Alert severity="info">
                  {localStorage.getItem("period")
                    ? `Answers are from the period: ${localStorage.getItem("period")}`
                    : "Answers are from all periods."}
                </Alert>
              </Box>
            </Box>

            <Grid container spacing={2}>
              {questions.slice((page - 1) * numberOfItemsPerPage, page * numberOfItemsPerPage).map((question, index) => (
                <Grid item xs={4}>
                  <MultipleChoiceQuestionCard
                    key={page}
                    question={question}
                    type={"graded"}
                    onChangeChc={(newChoices) => {
                      let newData = [...questions];
                      newData[index + numberOfItemsPerPage * (page - 1)].choices = newChoices;
                      setQuestions(newData);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </React.Fragment>
        );
      default:
        return "Unknown stepIndex";
    }
  };

  const handleNext = () => {
    switch (activeStep) {
      case 0:
        gradeQuestions();
        break;
      default:
        break;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    switch (activeStep) {
      case 1:
        break;
      case 2:
        break;
      default:
        break;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderNextButton = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <Button variant="contained" color="primary" onClick={handleNext} disabled={!(questions && questions.length)}>
            Next
          </Button>
        );
      case 1:
        return (
          <Button variant="contained" color="primary" onClick={() => alert("Not yet implemented!")} disabled>
            Send to Pscribe
          </Button>
        );
      default:
        return "Unknown stepIndex";
    }
  };

  return (
    <React.Fragment>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography className={classes.instructions} variant="body1" gutterBottom>
              Completed grading!
            </Typography>
            <Button variant="contained" color="primary">
              Return to Pscribe
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box my={2}>{getStepContent(activeStep)}</Box>
            <Box position="fixed" bottom={0} mb={2} display="flex" width={0.5} justifyContent="space-between">
              <Box>
                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.backButton}>
                  Back
                </Button>
                {renderNextButton(activeStep)}
              </Box>

              {questions && questions.length / numberOfItemsPerPage > 1 ? (
                <Pagination
                  className={classes.pagination}
                  color="primary"
                  variant="outlined"
                  size="large"
                  showFirstButton
                  showLastButton
                  count={Math.ceil(questions.length / numberOfItemsPerPage)}
                  page={page}
                  onChange={(event, value) => {
                    setPage(value);
                  }}
                />
              ) : null}
            </Box>
          </React.Fragment>
        )}
      </Box>
    </React.Fragment>
  );
}
