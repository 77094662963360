import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
  CircularProgress,
  LinearProgress,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import React from "react";
import GradingSchemesTable from "../gradingSchemes/GradingSchemesTable";
import PrescriptionsTable from "../prescriptions/PrescriptionsTable";
import OpenQuestionsTable from "../questions/OpenQuestionsTable";
import OpenQuestionAnswersSelect from "../questions/OpenQuestionAnswersSelect";
import OpenQuestionsGraded from "../questions/OpenQuestionsGraded";
import ReactExport from "react-data-export";
import GetAppIcon from "@material-ui/icons/GetApp";
import Alert from "@material-ui/lab/Alert";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ["Select questions(s)", "Select model answer(s)", "Results"];
}

export default function OpenQuestionGradingStepper(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const case_id = props.match.params.case_id;
  const filename = localStorage.getItem("period") ? localStorage.getItem("period") : "Graded Open Questions";
  const [selectedQuestions, setSelectedQuestions] = React.useState(null);
  const [selectedModelAnswers, setSelectedAnswers] = React.useState(null);
  const [gradedQuestions, setGradedQuestions] = React.useState(null);
  const [loadingGrades, setLoadingGrades] = React.useState(false);
  const [error, setError] = React.useState(false);
  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <OpenQuestionsTable mode="grading" case_id={case_id} onSelectionChange={(rows) => setSelectedQuestions(rows)} />;
      case 1:
        return selectedQuestions ? (
          <OpenQuestionAnswersSelect
            questions={selectedQuestions}
            onSelectionChange={(model_answer, question_id) => {
              const idx = selectedModelAnswers.findIndex((item) => item.id == question_id);
              const newSelected = selectedModelAnswers;
              newSelected[idx].model_answer = model_answer;
              setSelectedAnswers(newSelected);
            }}
          />
        ) : null;
      case 2:
        if (error) {
          return <Alert severity="error">Error grading the open questions. Please try again later.</Alert>;
        }
        if (!loadingGrades && (!gradedQuestions || !gradedQuestions.length)) {
          return <Alert severity="error">No open questions were graded, because no model answers were selected.</Alert>;
        }
        return loadingGrades ? (
          <Box height={500}>
            <LinearProgress />
          </Box>
        ) : (
          <OpenQuestionsGraded questions={gradedQuestions} />
        );
      default:
        return "Unknown stepIndex";
    }
  };

  const handleGrading = () => {
    setLoadingGrades(true);
    const questions = selectedQuestions.map((question) => ({
      ...question,
      selected_model_answer: question.model_answers.find(
        (item) => item.id == selectedModelAnswers.find((item) => item.id == question.id).model_answer
      ),
    }));

    let promises = [];
    let valid_questions = [];
    questions.forEach((question) => {
      if (question.selected_model_answer) {
        promises.push(
          axios.post(
            "submitlist",
            { teacher_answer: question.selected_model_answer.answer, student_answers: question.answers.map((answer) => answer.answer) },
            { baseURL: axios.defaults.baseURL === "https://pharmagrader.com/" ? axios.defaults.baseURL : "http://localhost:5000/" }
          )
        );
        valid_questions.push(question);
      }
    });

    // questions.forEach((question) =>
    //   console.log(
    //     JSON.stringify({
    //       teacher_answer: question.selected_model_answer.answer,
    //       student_answers: question.answers.map((answer) => answer.answer),
    //     })
    //   )
    // );

    Promise.all(promises)
      .then(function (values) {
        const grades_map = { Goed: "G", Voldoende: "V", Onvoldoende: "O" };

        const graded_questions = valid_questions.map((question, question_idx) => ({
          ...question,
          answers: question.answers.map((answer, answer_idx) => ({
            ...answer,
            model_answer: question.selected_model_answer.answer,
            grade: grades_map[values[question_idx].data.grades[answer_idx]],
          })),
          model_answer: question.selected_model_answer.answer,
        }));

        graded_questions.forEach((question) =>
          axios
            .post("apa/api/answers/open/grade/", { answers: question.answers, model_answer: question.selected_model_answer.id })
            .then((res) => {})
        );
        setGradedQuestions(graded_questions);
        setLoadingGrades(false);
        setError(false);
      })
      .catch((err) => {
        setError(true);
        setLoadingGrades(false);
      });
  };

  const handleNext = () => {
    switch (activeStep) {
      case 0:
        setSelectedAnswers(
          selectedQuestions.map((question) => ({
            id: question.id,
            model_answer: question.model_answers.length ? question.model_answers[0].id : null,
          }))
        );
        break;
      case 1:
        handleGrading();
        break;
      case 2:
        break;
      default:
        break;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    switch (activeStep) {
      case 1:
        setError(false);
        break;
      case 2:
        break;
      default:
        break;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setSelectedQuestions(null);
    setSelectedAnswers(null);
    setSelectedQuestions(null);
    setLoadingGrades(false);
    setError(false);
    setActiveStep(0);
  };

  const renderNextButton = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <Button variant="contained" color="primary" onClick={handleNext} disabled={selectedQuestions === null ? true : false}>
            Next
          </Button>
        );
      case 1:
        return (
          <Button variant="contained" color="primary" onClick={handleNext} disabled={!null === null ? true : false}>
            Next
          </Button>
        );
      case 2:
        return (
          <React.Fragment>
            <Button onClick={handleReset} className={classes.backButton}>
              Reset
            </Button>
            <Button variant="contained" color="primary" onClick={() => alert("Not yet implemented!")} disabled>
              Send to Pscribe
            </Button>
          </React.Fragment>
        );
      default:
        return "Unknown stepIndex";
    }
  };

  return (
    <React.Fragment>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <React.Fragment>
        <Box mb={2} display="flex" alignItems="center">
          {activeStep === 2 && !loadingGrades && gradedQuestions.length ? (
            <ExcelFile
              filename={filename}
              element={
                <Tooltip title="Download data">
                  <IconButton color="primary" component="span">
                    <GetAppIcon fontSize="medium" />
                  </IconButton>
                </Tooltip>
              }
            >
              <ExcelSheet data={gradedQuestions} name="Overview">
                <ExcelColumn label="Parent Step" value="parent_step_index" />
                <ExcelColumn label="Step" value="step_index" />
                <ExcelColumn label="Index" value="index" />
                <ExcelColumn label="Question" value="question" />
                <ExcelColumn label="Model Answer" value="model_answer" />
              </ExcelSheet>
              {gradedQuestions.map((question) => (
                <ExcelSheet data={question.answers} name={`Question ${question.position}`}>
                  <ExcelColumn label="Student ID" value="student" />
                  <ExcelColumn label="Answer" value="answer" />
                  <ExcelColumn label="Grade" value="grade" />
                </ExcelSheet>
              ))}
            </ExcelFile>
          ) : null}
          <Box flexGrow={1}>
            <Alert severity="info">
              {localStorage.getItem("period")
                ? `Answers are from the period: ${localStorage.getItem("period")}`
                : "Answers are from all periods."}
            </Alert>
          </Box>
        </Box>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography className={classes.instructions} variant="body1" gutterBottom>
              Completed grading!
            </Typography>
            <Button onClick={handleReset} className={classes.backButton}>
              Reset
            </Button>
            <Button variant="contained" color="primary">
              Return to Pscribe
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box
              my={2}
              mb={12}
              height={Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) * 0.65}
              display="block"
            >
              {getStepContent(activeStep)}
            </Box>
            <Box display="flex" position="fixed" bottom={0} mb={2} justifyContent="space-between">
              <Box>
                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.backButton}>
                  Back
                </Button>
                {renderNextButton(activeStep)}
              </Box>
            </Box>
          </React.Fragment>
        )}
      </React.Fragment>
    </React.Fragment>
  );
}
