import React from "react";
import PropTypes from "prop-types";
import { TextField, Typography, Grid, Paper, Box } from "@material-ui/core";
import MaterialTable from "../utility/MaterialTable";
import axios from "axios";
import DeleteIcon from "@material-ui/icons/Delete";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import { green, red, lightGreen, deepOrange } from "@material-ui/core/colors";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
OpenQuestionsTable.propTypes = {};

function OpenQuestionsTable(props) {
  const [openQuestions, setOpenQuestions] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const loadOpenQuestions = () => {
    // Load the case data from the backend
    setLoading(true);
    axios
      .get("apa/api/questions/open/", {
        params: {
          case_id: props.case_id,
          case_access_id: localStorage.getItem("case_access_id"),
          include_answers: true,
          include_model_answers: true,
        },
      })
      .then((res) => {
        setOpenQuestions(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setOpenQuestions([]);
        console.log(error);
      });
  };
  const addOpenQuestion = (newData) => {
    axios
      .post("apa/api/questions/open/", {
        question: newData.question,
        index: newData.index,
        step_index: newData.step_index,
        case_id: props.case_id,
      })
      .then((res) => {
        let question = res.data;
        question.model_answers = [];
        question.answers = [];
        setOpenQuestions([...openQuestions, { ...question }]);
      });
  };

  const editOpenQuestion = (newData, oldData) => {
    axios.put(`apa/api/questions/open/${newData.id}/`, { ...newData }).then((res) => {
      const data = [...openQuestions];
      data[data.indexOf(oldData)] = newData;
      setOpenQuestions([...data]);
    });
  };
  const deleteOpenQuestion = (rowData) => {
    axios.delete(`apa/api/questions/open/${rowData.id}/`).then((res) => {
      const data = [...openQuestions];
      data.splice(data.indexOf(rowData), 1);
      setOpenQuestions([...data]);
    });
  };

  const theme = createMuiTheme({
    palette: {
      secondary: {
        main: "#618833",
      },
    },
  });
  React.useEffect(() => {
    if (openQuestions == null && !loading) {
      loadOpenQuestions();
    }
  }, [loadOpenQuestions]);

  const tableActions = () => {
    let actions = [];

    switch (props.mode) {
      case "testing":
        actions = [
          {
            icon: () => <DeleteIcon color="error" />,
            tooltip: "Delete",
            onClick: (event, rowData) => {
              deleteOpenQuestion(rowData);
            },
          },
          {
            icon: () => <QuestionAnswerIcon color="primary" />,
            tooltip: "View answers",
            onClick: (event, rowData) => {
              props.onSelectQuestion(rowData);
            },
          },
        ];
        break;
      case "grading":
        break;

      default:
        break;
    }

    return actions;
  };
  return (
    <MuiThemeProvider theme={theme}>
      <MaterialTable
        columns={[
          {
            title: "Parent Step",
            field: "parent_step_index",
            width: 50,
          },
          {
            title: "Step",
            field: "step_index",
            width: 50,
          },
          {
            title: "Index",
            field: "index",

            width: 50,
          },
          {
            title: "Question",
            field: "question",
            editComponent: (props) => (
              <TextField multiline rows={3} fullWidth value={props.value} onChange={(e) => props.onChange(e.target.value)} />
            ),
            render: (rowData) => (
              <Typography variant="body2" display="inline" style={{ whiteSpace: "pre-line" }}>
                {rowData.question}
              </Typography>
            ),
          },
          {
            title: "# Model answers",
            field: "model_answers",
            editable: "never",
            render: (rowData) => (
         
              <Typography variant="body2" display="inline" style={{ whiteSpace: "pre-line" }}>
                {rowData.model_answers.length}
              </Typography>
            ),
            width: 200,
          },
          {
            title: "# Student answers",
            field: "answers",
            editable: "never",
            render: (rowData) => (
              <Typography variant="body2" display="inline" style={{ whiteSpace: "pre-line" }}>
                {rowData.answers.length}
              </Typography>
            ),
            width: 200,
          },
        ]}
        data={openQuestions ? openQuestions : []}
        title={"Open questions"}
        editable={
          props.mode === "testing"
            ? {
                onRowAdd: (newData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      addOpenQuestion(newData);
                      resolve();
                    }, 100);
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      editOpenQuestion(newData, oldData);
                      resolve();
                    }, 100);
                  }),
              }
            : null
        }
        actions={tableActions()}
        onSelectionChange={(rows) => props.onSelectionChange(rows)}
        isLoading={"loading" in props ? props.loading : loading}
        options={{
          actionsColumnIndex: -1,
          draggable: false,
          padding: "dense",
          sorting: false,
          selection: props.mode === "grading" ? true : false,
          pageSize: 10,
          pageSizeOptions: [10, 15, 20, 25],
          exportButton: props.export,
        }}
      />
    </MuiThemeProvider>
  );
}

export default OpenQuestionsTable;
