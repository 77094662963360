import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Fab, Tooltip, LinearProgress, IconButton } from "@material-ui/core";
import MultipleChoiceQuestionCard from "../questions/MultipleChoiceQuestionCard";
import axios from "axios";
import { Link } from "react-router-dom";
import CheckIcon from "@material-ui/icons/Check";
import ReactExport from "react-data-export";
import Alert from "@material-ui/lab/Alert";
import GetAppIcon from "@material-ui/icons/GetApp";
import Pagination from "@material-ui/lab/Pagination";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
    "&:hover": {
      color: "white",
    },
  },
  paper: {
    padding: theme.spacing(1, 2),
  },
  pagination: {
    marginRight: "auto !important",
    marginLeft: "auto !important",
    marginTop: "8px",
  },
}));

MultipleChoiceAnswers.propTypes = {};

function MultipleChoiceAnswers(props) {
  const classes = useStyles();

  const case_id = props.match.params.case_id;
  const [questions, setQuestions] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const numberOfItemsPerPage = 6;
  const filename = localStorage.getItem("period") ? localStorage.getItem("period") : "Multiple Choice Answers";
  const loadMCQuestions = (case_id) => {
    // Load the case data from the backend
    setLoading(true);
    axios
      .get("apa/api/questions/mc/", {
        params: {
          case_id: case_id,
          case_access_id: localStorage.getItem("case_acces_id"),
          include_answers: true,
        },
      })
      .then((res) => {
        let tempQuestions = res.data;
        tempQuestions = tempQuestions.map((question) => ({
          ...question,
          answers: question.answers.map((answer) => ({
            ...answer,
            answer_position: String.fromCharCode(97 + answer.choice.position),
            answer_choice: answer.choice.choice,
          })),
        }));

        setQuestions(tempQuestions);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  React.useEffect(() => {
    if (questions == null) {
      loadMCQuestions(case_id);
    }
  }, [loadMCQuestions]);

  return (
    <React.Fragment>
      {loading ? (
        <LinearProgress />
      ) : questions && questions.length ? (
        <React.Fragment>
          <Box mb={2} display="flex" alignItems="center">
            <ExcelFile
              filename={filename}
              element={
                <Tooltip title="Download data">
                  <IconButton color="primary" component="span">
                    <GetAppIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
              }
            >
              <ExcelSheet data={questions} name="Overview">
                <ExcelColumn label="Parent Step" value="parent_step_index" />
                <ExcelColumn label="Step" value="step_index" />
                <ExcelColumn label="Index" value="index" />
                <ExcelColumn label="Question" value="question" />
              </ExcelSheet>
              {questions.map((question) => (
                <ExcelSheet data={question.answers} name={`Question ${question.position}`}>
                  <ExcelColumn label="Student ID" value="student" />
                  <ExcelColumn label="Position" value="answer_position" />
                  <ExcelColumn label="Answer" value="answer_choice" />
                </ExcelSheet>
              ))}
            </ExcelFile>
            <Box flexGrow={1}>
              <Alert severity="info">
                {localStorage.getItem("period")
                  ? `Answers are from the period: ${localStorage.getItem("period")}`
                  : "Answers are from all periods."}
              </Alert>
            </Box>
          </Box>

          <Box style={{ height: 550 }}>
            <Grid container spacing={3} style={{ height: 550 }}>
              {questions.slice((page - 1) * numberOfItemsPerPage, page * numberOfItemsPerPage).map((question) => (
                <Grid item xs={4}>
                  <MultipleChoiceQuestionCard key={page} question={question} type={"answers"} />
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box position="fixed" bottom={0} mb={2} display="flex" width={0.75} justifyContent="center">
            {questions && questions.length / numberOfItemsPerPage > 1 ? (
              <Pagination
                className={classes.pagination}
                color="primary"
                variant="outlined"
                size="large"
                showFirstButton
                showLastButton
                count={Math.ceil(questions.length / numberOfItemsPerPage)}
                page={page}
                onChange={(event, value) => {
                  setPage(value);
                }}
              />
            ) : null}
          </Box>

          <Tooltip title="Grade">
            <Fab color="primary" component={(props) => <Link to={`/mca/assesment/grade/${case_id}`} {...props} />} className={classes.fab}>
              <CheckIcon />
            </Fab>
          </Tooltip>
        </React.Fragment>
      ) : (
        <Alert severity="info">No multiple choice questions exist for this case.</Alert>
      )}
    </React.Fragment>
  );
}

export default MultipleChoiceAnswers;
