import {
  Box,
  Checkbox,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import React from "react";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",

    position: "relative",
    overflow: "auto",
    maxHeight: 400,
  },

  cardTitle: {
    marginTop: "8px",
    marginLeft: "16px",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    marginRight: "3px",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  listSubheader: {
    backgroundColor: theme.palette.background.paper,
  },
  numberField: {
    width: 50,
  },
}));

function Rules(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const [rules, setRules] = React.useState(null);

  React.useEffect(() => {
    if (props.rules) {
      setRules(props.rules.map((rule) => ({ ...rule })));
    }
  }, [props.rules]);

  const handleExpandClick = () => {
    setExpanded(false);
    // setExpanded(!expanded);
  };

  const handleToggle = (idx) => () => {
    let temp_rules = rules;

    temp_rules[idx].active = !temp_rules[idx].active;
    props.onChange(temp_rules.map((rule) => ({ ...rule })));
    setRules(temp_rules.map((rule) => ({ ...rule })));
  };

  const handlePointschange = (idx) => (event) => {
    let temp_rules = rules;

    temp_rules[idx].points = event.target.value;
    props.onChange(temp_rules.map((rule) => ({ ...rule })));
    setRules(temp_rules.map((rule) => ({ ...rule })));
  };

  const Rule = (data, idx, mode) => {
    const labelId = `checkbox-list-label-${idx}`;
    if (mode === "details") {
      if (!rules[idx].active) {
        return;
      }
    }
    return (
      <ListItem key={idx} dense button={mode !== "details"} onClick={mode !== "details" ? handleToggle(idx) : null}>
        <ListItemIcon>
          {mode === "details" ? (
            <CheckIcon style={{ color: green[500] }} />
          ) : (
            <Checkbox
              edge="start"
              checked={rules[idx].active}
              tabIndex={-1}
              color="primary"
              disableRipple
              inputProps={{ "aria-labelledby": labelId }}
            />
          )}
        </ListItemIcon>
        <ListItemText primary={data.name} secondary={data.description} />
        <ListItemSecondaryAction>
          {mode === "details" ? (
            data.points
          ) : (
            <TextField style={{ width: 35 }} value={rules[idx].points} onChange={handlePointschange(idx)} />
          )}
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  return (
    <Paper className={classes.root}>
      <Box display="flex">
        <Typography className={classes.cardTitle} color="textSecondary" gutterBottom variant="h6" component="h2">
          Rules (disabled)
        </Typography>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </Box>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <List
          dense
          subheader={
            <ListSubheader className={classes.listSubheader}>
              <Box display="flex" justifyContent="space-between">
                <Box>Active</Box>
                <Box>Points</Box>
              </Box>
            </ListSubheader>
          }
        >
          {rules ? rules.map((rule, idx) => Rule(rule, idx, props.mode)) : null}
        </List>
      </Collapse>
    </Paper>
  );
}

export default Rules;
