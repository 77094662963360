import React from "react";
import { Route } from "react-router-dom";
import Login from "../auth/Login";
import SignUp from "../auth/SignUp";
import PscribeRedirect from "../general/PscribeRedirect";
import ResetPassword from "../auth/ResetPassword";

// Routes for the login/signup screens and the api connection

const AuthRoutes = (props) => (
  <React.Fragment>
    <Route exact path="/" render={(props) => <Login onSucces={props.onAuth} {...props} />} />
    <Route exact path="/redirect/:case_id/:token/:case_access_id" component={PscribeRedirect} />
    {/* <Route exact path="/login" component={(props) => <Login onComplete={props.onAuth} {...props} />} /> */}
    <Route exact path="/signup" render={(props) => <SignUp onComplete={props.onAuth} {...props} />} />
    <Route exact path="/resetpassword" render={(props) => <ResetPassword onComplete={props.onAuth} {...props} />} />
    {/* <Redirect to="/" /> */}
  </React.Fragment>
);

export default AuthRoutes;
