import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Grid,
  Paper,
  Tooltip,
  TextField,
  Input,
  Slider,
  Typography,
} from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    overflow: "auto",
  },

  cardTitle: {
    marginTop: "8px",
    marginLeft: "16px",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    marginRight: "3px",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  listSubheader: {
    backgroundColor: theme.palette.background.paper,
  },
  numberField: {
    width: 50,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

function GradingResult(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [value, setValue] = React.useState(5);
  const [weight, setWeight] = React.useState(25);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const Rule = (data) => {
    return (
      <ListItem className={classes.nested} dense>
        <ListItemIcon>
          {data.satisfied ? <CheckIcon style={{ color: green[500] }} /> : <CloseIcon style={{ color: red[500] }} />}
        </ListItemIcon>
        <ListItemText primary={data.name} secondary={data.description} />
        <ListItemSecondaryAction>{data.points}</ListItemSecondaryAction>
      </ListItem>
    );
  };

  // return (
  //   <Box width={"100%"} mr={2}>
  //     <TableContainer component={Box}>
  //       <Table size="small">
  //         <TableHead>
  //           <TableRow>
  //             <TableCell>Type</TableCell>

  //             <TableCell align="right">Grade</TableCell>
  //             <TableCell>0-100</TableCell>
  //             <TableCell align="right">Weight</TableCell>
  //             <TableCell align="left">%</TableCell>
  //           </TableRow>
  //         </TableHead>
  //         <TableBody>
  //           <TableRow key={0}>
  //             <TableCell component="th" scope="row">
  //               R/
  //             </TableCell>
  //             <TableCell align="right">
  //               <Box display="flex" alignItems="center">
  //                 {/* <Box mr={2}>
  //                   <Typography>{value}</Typography>
  //                 </Box> */}
  //                 <Slider
  //                   value={typeof value === "number" ? value : 0}
  //                   onChange={(event, newValue) => setValue(newValue)}
  //                   // onChangeCommitted={undefined}
  //                   step={5}
  //                   min={0}
  //                   max={100}
  //                   valueLabelDisplay="off"
  //                 />
  //               </Box>
  //             </TableCell>
  //             <TableCell>
  //               <Typography>{value}</Typography>
  //             </TableCell>
  //             <TableCell align="right">
  //               <Slider
  //                 value={typeof weight === "number" ? weight : 0}
  //                 onChange={(event, newValue) => setWeight(newValue)}
  //                 // onChangeCommitted={undefined}
  //                 step={5}
  //                 min={0}
  //                 max={100}
  //                 valueLabelDisplay="off"
  //               />
  //             </TableCell>
  //             <TableCell>
  //               <Typography>{weight}</Typography>
  //             </TableCell>
  //           </TableRow>
  //           <TableRow key={0}>
  //             <TableCell component="th" scope="row">
  //               Da
  //             </TableCell>
  //             <TableCell align="right">
  //               <Slider defaultValue={0} onChangeCommitted={undefined} step={0.05} min={0} max={1} valueLabelDisplay="auto" />
  //             </TableCell>
  //             <TableCell align="right">
  //               <Slider defaultValue={0} onChangeCommitted={undefined} step={0.05} min={0} max={1} valueLabelDisplay="auto" />
  //             </TableCell>
  //           </TableRow>
  //           <TableRow key={0}>
  //             <TableCell component="th" scope="row">
  //               S/
  //             </TableCell>
  //             <TableCell align="right">
  //               <Slider defaultValue={0} onChangeCommitted={undefined} step={0.05} min={0} max={1} valueLabelDisplay="auto" />
  //             </TableCell>
  //             <TableCell align="right">
  //               <Slider defaultValue={0} onChangeCommitted={undefined} step={0.05} min={0} max={1} valueLabelDisplay="auto" />
  //             </TableCell>
  //           </TableRow>
  //           <TableRow key={0}>
  //             <TableCell component="th" scope="row">
  //               Other
  //             </TableCell>
  //             <TableCell align="right">
  //               <Slider defaultValue={0} onChangeCommitted={undefined} step={0.05} min={0} max={1} valueLabelDisplay="auto" />
  //             </TableCell>
  //             <TableCell align="right">
  //               <Slider defaultValue={0} onChangeCommitted={undefined} step={0.05} min={0} max={1} valueLabelDisplay="auto" />
  //             </TableCell>
  //           </TableRow>
  //         </TableBody>
  //       </Table>
  //     </TableContainer>
  //   </Box>
  //   // <Grid container spacing={0} style={{ width: "100%" }}>
  //   //   <Grid container xs={12}>
  //   //     <Grid xs={4}>Type</Grid>
  //   //     <Grid xs={4}>Grade</Grid>
  //   //     <Grid xs={4}>Weight</Grid>
  //   //   </Grid>

  //   //   {/* <Grid xs={3}>R/</Grid>
  //   //   <Grid xs={4}>{Math.round((props.data.MAUA_score + Number.EPSILON) * 100) / 100}</Grid>
  //   //   <Grid xs={4}>1</Grid>

  //   //   <Grid xs={3}>Da</Grid>
  //   //   <Grid xs={4}>0</Grid>
  //   //   <Grid xs={4}>0</Grid>

  //   //   <Grid xs={3}>S/</Grid>
  //   //   <Grid xs={4}>0</Grid>
  //   //   <Grid xs={4}>0</Grid> */}
  //   //   <Grid container xs={12}>
  //   //     <Grid xs={4}>Other</Grid>
  //   //     <Grid xs={4}>
  //   //       <Box my={2}>
  //   //         <Slider defaultValue={10} onChangeCommitted={undefined} step={0.5} min={0} max={10} marks={marks} valueLabelDisplay="on" />
  //   //       </Box>
  //   //     </Grid>
  //   //     <Grid xs={4}>
  //   //       <Box my={2}>
  //   //         <Slider defaultValue={0} onChangeCommitted={undefined} step={0.05} min={0} max={1} valueLabelDisplay="on" />
  //   //       </Box>
  //   //     </Grid>
  //   //   </Grid>

  //   //   {/* R/ Da S/ Other information  */}
  //   // </Grid>
  // );

  return (
    <Paper className={classes.root}>
      <List
        dense
        subheader={
          <ListSubheader className={classes.listSubheader}>
            <Box display="flex" justifyContent="space-between">
              <Box>Sufficient</Box>
              <Box>Points</Box>
            </Box>
          </ListSubheader>
        }
      >
        <ListItem key={1} dense>
          <ListItemIcon>
            {props.data.MAUA_score < 5.0 ? <CloseIcon style={{ color: red[500] }} /> : <CheckIcon style={{ color: green[500] }} />}
          </ListItemIcon>
          <ListItemText primary={"MAUA"} secondary={props.data.MAUA_feedback} style={{ whiteSpace: "pre-line" }} />
          <ListItemSecondaryAction>{Math.round((props.data.MAUA_score + Number.EPSILON) * 100) / 100}</ListItemSecondaryAction>
        </ListItem>
        <ListItem key={2} dense>
          <ListItemIcon>
            {props.data.intake_information_score < 0 ? (
              <CloseIcon style={{ color: red[500] }} />
            ) : (
              <CheckIcon style={{ color: green[500] }} />
            )}
          </ListItemIcon>
          <ListItemText primary={"Intake information"} secondary={props.data.intake_information_feedback} />
          <ListItemSecondaryAction>
            {Math.round((props.data.intake_information_score + Number.EPSILON) * 100) / 100}
          </ListItemSecondaryAction>
        </ListItem>

        {/* <ListItem button onClick={handleExpandClick}>
          <ListItemIcon>
            {props.data.rules_score < 0 ? <CloseIcon style={{ color: red[500] }} /> : <CheckIcon style={{ color: green[500] }} />}
          </ListItemIcon>
          <ListItemText primary="Rules" />
          <ListItemSecondaryAction>
            <Box display="flex" alignItems="center">
              {expanded ? <ExpandLess /> : <ExpandMore />}
              <Box>{props.data.rules_score}</Box>
            </Box>
          </ListItemSecondaryAction>
        </ListItem>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <List dense component="div" disablePadding>
            {props.data.rules_feedback.map((rule) => Rule(rule))}
          </List>
        </Collapse> */}
      </List>
    </Paper>
  );
}

export default GradingResult;
