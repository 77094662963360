import React from "react";
import axios from "axios";
import UsersTable from "./UsersTable";

function Users(props) {
  // Users dashboard component
  return <UsersTable />;
}

export default Users;
