import { Box, Fab, Snackbar, Tooltip, Typography, Button, AppBar, Toolbar, Dialog, DialogContent } from "@material-ui/core";
import { createMuiTheme, makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import UnfoldMore from "@material-ui/icons/UnfoldMore";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import ComfirmDeleteDialog from "../utility/ConfirmDeleteDialog";
import MaterialTable from "../utility/MaterialTable";
import SnackbarContentWrapper from "../utility/SnackbarContentWrapper";
import Slide from "@material-ui/core/Slide";
import GradingScheme from "./GradingScheme";

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
    "&:hover": {
      color: "white",
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

export default function GradingSchemesTable(props) {
  const history = useHistory();
  const classes = useStyles();
  const [openDeleteMsg, setOpenDeleteMsg] = React.useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);
  const [selected, setSelected] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const tableRef = React.createRef();

  const [gradingSchemes, setGradingSchemes] = React.useState(null);
  const theme = createMuiTheme({
    palette: {
      secondary: {
        main: "#3f51b5",
      },
    },
  });

  const loadGradingSchemes = () => {
    setLoading(true);
    axios
      .get("apa/api/grading/gradingschemes/", { params: { case_id: props.case_id } })
      .then((res) => {
        const data = res.data.map((gradingScheme) => ({
          ...gradingScheme,
          owner: gradingScheme.owner != null ? gradingScheme.owner.username : null,
          type: "MAUA",
        }));
        setGradingSchemes(data);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const deleteGradingScheme = (id) => {
    axios
      .delete(`apa/api/grading/gradingschemes/${id}`)
      .then((res) => {
        loadGradingSchemes();

        setOpenDeleteMsg(true);
      })
      .catch((error) => console.log(error));
  };

  React.useEffect(() => {
    if (gradingSchemes == null && !loading) {
      loadGradingSchemes();
    }
  }, [loadGradingSchemes]);

  const handleDeleteMsgClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenDeleteMsg(false);
  };

  const handleDelete = () => {
    setOpenConfirmDeleteDialog(false);
    deleteGradingScheme(deleteId);
  };

  const handleCloseConfirmDelete = () => {
    setOpenConfirmDeleteDialog(false);
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const tableActions = (mode) => {
    let actions = [
      {
        icon: () => <EditIcon />,
        tooltip: "Edit",
        onClick: (event, rowData) => {
          history.push("/apa/assesment/scheme/edit/" + rowData.id);
        },
      },
      {
        icon: () => <DeleteIcon color="error" />,
        tooltip: "Delete",
        onClick: (event, rowData) => {
          setOpenConfirmDeleteDialog(true);
          setDeleteId(rowData.id);
        },
      },
    ];

    if (mode === "choose") {
      actions = [
        {
          icon: () => <AddIcon />,
          tooltip: "Add grading scheme",
          isFreeAction: true,
          onClick: (event) => history.push(`/apa/assesment/scheme/new/${props.case_id}`),
        },
        {
          icon: () => <CloseIcon />,
          tooltip: "Cancel",
          onClick: (event, rowData) => {
            setSelected(null);
          },
        },
      ];
    }

    return actions;
  };

  const onSelectionChange = (rows) => {
    if (rows.length < 1) {
      setSelected(null);
      props.onChoose(null);
    } else {
      setSelected(rows[0].id);
      props.onChoose(rows[0]);
    }
  };
  return (
    <Box>
      <MuiThemeProvider theme={theme}>
        <MaterialTable
          tableRef={tableRef}
          columns={[
            { title: "Target group", field: "target_group" },
            { title: "Owner", field: "owner", width: 200 },
            { title: "Created at", field: "created_at", width: 200 },
            { title: "Modified at", field: "modified_at", width: 200 },
          ]}
          data={gradingSchemes == null ? [] : gradingSchemes}
          title="Grading schemes"
          options={{
            draggable: false,
            padding: "dense",
            actionsColumnIndex: -1,
            selection: props.mode === "choose" ? true : false,
            showSelectAllCheckbox: false,
            selectionProps: (rowData) => ({
              disabled: selected === null ? false : rowData.id !== selected,
              color: "primary",
            }),
            pageSize: 10,
            pageSizeOptions: [10, 15, 20, 25],
          }}
          isLoading={loading}
          onSelectionChange={(rows) => onSelectionChange(rows)}
          actions={tableActions(props.mode)}
          localization={{
            toolbar: { nRowsSelected: "Selected grading scheme" },
          }}
          detailPanel={(rowData) => {
            return (
              <Dialog
                open={true}
                TransitionComponent={Transition}
                fullWidth={800}
                maxWidth={800}
                onBackdropClick={() => {
                  tableRef.current.onToggleDetailPanel([rowData.tableData.id], tableRef.current.props.detailPanel);
                }}
              >
                <AppBar className={classes.appBar}>
                  <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                      Grading Scheme
                    </Typography>
                    <Button
                      autoFocus
                      color="inherit"
                      onClick={() => {
                        tableRef.current.onToggleDetailPanel([rowData.tableData.id], tableRef.current.props.detailPanel);
                      }}
                    >
                      <CloseIcon />
                    </Button>
                  </Toolbar>
                </AppBar>
                <Box p={4} bgcolor="text.disabled">
                  <GradingScheme mode={"details"} id={rowData.id} hideButton />
                </Box>
              </Dialog>
              // <Box pl={2} pt={1}>
              //   <Typography variant="body2" gutterBottom>
              //     {rowData.description}
              //   </Typography>
              // </Box>
            );
          }}
        />
      </MuiThemeProvider>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openDeleteMsg}
        autoHideDuration={6000}
        onClose={handleDeleteMsgClose}
      >
        <SnackbarContentWrapper onClose={handleDeleteMsgClose} variant="success" message="Deleted grading scheme!" />
      </Snackbar>
      <ComfirmDeleteDialog handleOk={handleDelete} onClose={handleCloseConfirmDelete} open={openConfirmDeleteDialog} />
    </Box>
  );
}
