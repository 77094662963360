import {
  Box,
  Collapse,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AssesmentIcon from "@material-ui/icons/Assessment";
import ConnectIcon from "@material-ui/icons/CallMissed";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CheckIcon from "@material-ui/icons/Check";
import ComputerIcon from "@material-ui/icons/Computer";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DescriptionIcon from "@material-ui/icons/Description";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HelpIcon from "@material-ui/icons/HelpOutline";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ReceiptIcon from "@material-ui/icons/ReceiptOutlined";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import SuperVisorIcon from "@material-ui/icons/SupervisorAccount";
import clsx from "clsx";
import React from "react";
import { Link, Route, Switch } from "react-router-dom";
import Can from "../auth/Can";
import SiteRoutes from "../routes/SiteRoutes";
import AppBar from "./AppBar";
import SiteBreadCrumbs from "./SiteBreadCrumbs";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import ListIcon from "@material-ui/icons/List";
import { connect } from "react-redux";

// Left-side drawer component that contains common actions for the user
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },

  nested: {
    paddingLeft: theme.spacing(4),
    float: "right",
  },
  toolbar: theme.mixins.toolbar,
}));

function ResponsiveDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const case_id = localStorage.getItem("case_id");
  const [openDrawer, setOpenDrawer] = React.useState(true);
  const [openTestingCollapse, setOpenTestingCollapse] = React.useState(false);
  const [openAdminCollapse, setOpenAdminCollapse] = React.useState(false);
  const [openGradingCollapse, setOpenGradingCollapse] = React.useState(false);
  const [openAnswersCollapse, setOpenbAnswersCollapse] = React.useState(false);
  const [openOQACollapse, setOpenOQACollapse] = React.useState(false);
  const [openAPACollapse, setOpenAPACollapse] = React.useState(false);
  const [openMCCollapse, setOpenMCCollapse] = React.useState(false);
  const forceUpdate = React.useReducer((bool) => !bool)[1];
  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleOpenTestingCollapse = () => {
    setOpenTestingCollapse(!openTestingCollapse);
  };

  const handleOpenAdminCollapse = () => {
    setOpenAdminCollapse(!openAdminCollapse);
  };

  const handleOpenGradingCollapse = () => {
    setOpenGradingCollapse(!openGradingCollapse);
  };

  const handleOpenAnswersCollapse = () => {
    setOpenbAnswersCollapse(!openAnswersCollapse);
  };
  const renderTesting = () => {
    // Render the testing submenu
    return (
      <div>
        <Divider />
        <List>
          <ListItem button onClick={handleOpenTestingCollapse}>
            <ListItemIcon>
              <ComputerIcon />
            </ListItemIcon>
            <ListItemText primary="Testing" />
            {openTestingCollapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
          <Collapse in={openTestingCollapse} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem className={classes.nested} button component={Link} to="/cases/testing">
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Cases" primaryTypographyProps={{ variant: "subtitle2" }} />
              </ListItem>

              <ListItem className={classes.nested} button component={Link} to="/oqa/testing">
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary="Open questions" primaryTypographyProps={{ variant: "subtitle2" }} />
              </ListItem>

              <ListItem className={classes.nested} button component={Link} to="/apa/testing">
                <ListItemIcon>
                  <ReceiptIcon />
                </ListItemIcon>
                <ListItemText primary="Prescriptions" primaryTypographyProps={{ variant: "subtitle2" }} />
              </ListItem>
              <ListItem className={classes.nested} button component={Link} to="/mca/testing">
                <ListItemIcon>
                  <ListIcon />
                </ListItemIcon>
                <ListItemText primary="Multiple choice questions" primaryTypographyProps={{ variant: "subtitle2" }} />
              </ListItem>
              <ListItem className={classes.nested} button component={Link} to="/api/example">
                <ListItemIcon>
                  <ConnectIcon />
                </ListItemIcon>
                <ListItemText primary="API" primaryTypographyProps={{ variant: "subtitle2" }} />
              </ListItem>
            </List>
          </Collapse>
        </List>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <CssBaseline />

      {/* Appbar is rendered on top of the page */}
      <AppBar openDrawer={openDrawer} onDrawerOpen={handleDrawerOpen} />

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={openDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>{theme.direction === "ltr" ? <ChevronLeftIcon /> : <ChevronRightIcon />}</IconButton>
        </div>
        <Divider />
        <List>
          <ListItem button component={Link} to="/">
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
        </List>

        <List>
          <ListItem button onClick={() => setOpenOQACollapse(!openOQACollapse)}>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary="Open questions" />
            {openOQACollapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
          <Collapse in={openOQACollapse} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem className={classes.nested} button component={Link} to={`/oqa/assesment/${case_id}`}>
                <ListItemIcon>
                  <AssesmentIcon />
                </ListItemIcon>
                <ListItemText primary="Model Answers" primaryTypographyProps={{ variant: "subtitle2" }} />
              </ListItem>
              <ListItem className={classes.nested} button component={Link} to={`/oqa/answers/${case_id}`}>
                <ListItemIcon>
                  <QuestionAnswerIcon />
                </ListItemIcon>
                <ListItemText primary="Student Answers" primaryTypographyProps={{ variant: "subtitle2" }} />
              </ListItem>
              <ListItem className={classes.nested} button component={Link} to={`/oqa/assesment/grade/${case_id}`}>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText primary="Grade" primaryTypographyProps={{ variant: "subtitle2" }} />
              </ListItem>
            </List>
          </Collapse>
        </List>

        <List>
          <ListItem button onClick={() => setOpenAPACollapse(!openAPACollapse)}>
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText primary="Prescriptions" />
            {openAPACollapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
          <Collapse in={openAPACollapse} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem className={classes.nested} button component={Link} to={`/apa/assesment/${case_id}`}>
                <ListItemIcon>
                  <AssesmentIcon />
                </ListItemIcon>
                <ListItemText primary="Grading Schemes" primaryTypographyProps={{ variant: "subtitle2" }} />
              </ListItem>
              <ListItem className={classes.nested} button component={Link} to={`/apa/answers/${case_id}`}>
                <ListItemIcon>
                  <QuestionAnswerIcon />
                </ListItemIcon>
                <ListItemText primary="Student Answers" primaryTypographyProps={{ variant: "subtitle2" }} />
              </ListItem>
              <ListItem className={classes.nested} button component={Link} to={`/apa/assesment/grade/${case_id}`}>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText primary="Grade" primaryTypographyProps={{ variant: "subtitle2" }} />
              </ListItem>
            </List>
          </Collapse>
        </List>

        <List>
          <ListItem button onClick={() => setOpenMCCollapse(!openMCCollapse)}>
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary="Multiple choice" />
            {openMCCollapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
          <Collapse in={openMCCollapse} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem className={classes.nested} button component={Link} to={`/mca/answers/${case_id}`}>
                <ListItemIcon>
                  <QuestionAnswerIcon />
                </ListItemIcon>
                <ListItemText primary="Student Answers" primaryTypographyProps={{ variant: "subtitle2" }} />
              </ListItem>
              <ListItem className={classes.nested} button component={Link} to={`/mca/assesment/grade/${case_id}`}>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText primary="Grade" primaryTypographyProps={{ variant: "subtitle2" }} />
              </ListItem>
            </List>
          </Collapse>
        </List>

        <Can role={props.group} perform="dashboard-testing:get" yes={() => renderTesting()} no={() => null} />
        <Can
          role={props.group}
          perform="dashboard-admin:get"
          yes={() => (
            <List>
              <ListItem button onClick={handleOpenAdminCollapse}>
                <ListItemIcon>
                  <SuperVisorIcon />
                </ListItemIcon>
                <ListItemText primary="Admin" />
                {openAdminCollapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItem>
              <Collapse in={openAdminCollapse} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem className={classes.nested} button component={Link} to="/admin/dashboard" disabled>
                    <ListItemIcon>
                      <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" primaryTypographyProps={{ variant: "subtitle2" }} />
                  </ListItem>
                  <ListItem className={classes.nested} button component={Link} to="/admin/users">
                    <ListItemIcon>
                      <RecentActorsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Users" primaryTypographyProps={{ variant: "subtitle2" }} />
                  </ListItem>
                </List>
              </Collapse>
            </List>
          )}
          no={() => null}
        />

        <Divider />

        <List>
          <ListItem button component={Link} to="/help">
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText primary="Tutorial" />
          </ListItem>
        </List>
        <Divider />
        <Box mx={2} display="flex" flexDirection="col" alignItems="flex-end" height="100%">
          <Typography variant="caption" display="block" gutterBottom>
            v0.7.1 alpha
            <br />
            Made by Gronalytica {"\u00a9"} 2021
          </Typography>
        </Box>
      </Drawer>

      {/* This is where the main content of the site is displayed through the switch and routes */}
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: openDrawer,
        })}
      >
        <div className={classes.toolbar} />
        <Box>
          <SiteBreadCrumbs location={props.location} />

          <Box mt={2}>
            <Route
              location={props.location}
              render={() => (
                <Switch>
                  <SiteRoutes />
                </Switch>
              )}
            />
          </Box>
        </Box>
      </main>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  // Function to dispatch logout when user presses logout button
  return {};
};

const mapStateToProps = (state) => {
  return { group: state.auth.group };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveDrawer);
