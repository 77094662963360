import {
  AppBar,
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Slider,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import React from "react";
import SwipeableViews from "react-swipeable-views";
import SelectList from "./SelectList";

const PrettoSlider = withStyles({
  root: {
    color: "#52af77",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const useStyles = makeStyles((theme) => ({
  divider: {
    height: theme.spacing(2),
  },
}));

const marks = [
  { value: 0, label: 0 },
  { value: 5, label: 5 },
  { value: 10, label: 10 },
];
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const IDMDialog = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [valid, setValid] = React.useState(true);

  // Selected inputs
  const [selected, setSelected] = React.useState({
    indication_group: null,
    indication: null,
    drug_group: null,
    drug: null,
    administration_form: null,
    strength: null,
  });

  // Loading state
  const [loading, setLoading] = React.useState({
    indication_groups: false,
    indications: false,
    drug_groups: false,
    drugs: false,
    administration_forms: false,
    strengths: false,
  });

  // Data state
  const [data, setData] = React.useState({
    indication_groups: [],
    indications: [],
    drug_groups: [],
    drugs: [],
    administration_forms: [],
    strengths: [],
  });

  const [MAUAScore, setMAUAScore] = React.useState({ effectiveness: 5.5, safety: 5.5, applicability: 5.5 });

  const [error, setError] = React.useState({
    drug: false,
    administration_form: false,
    strength: false,
  });

  const handleMAUAScoreChange = (name) => (event, newValue) => {
    setMAUAScore({ ...MAUAScore, [name]: newValue });
  };

  const handleChangeTabIndex = (index) => {
    setTabIndex(index);
  };

  const handleChangeTab = (event, newValue) => {
    if (newValue === 1) {
      // Search tab case -> Load full drugs list
      loadDrugs();
    } else {
      // IDM tab case -> Reset drug selections and downards and load drugs again if a drug group is selected
      resetSelections("drug");
      resetData("drug");
      if (selected.drug_group != null) {
        loadDrugs(selected.drug_group.value);
      }
    }
    setTabIndex(newValue);
  };
  const handleClose = () => {
    setOpen(false);
    setValid(true);

    // Return to default tab
    setTabIndex(0);

    // Reset dialog
    setError({ drug: false, administration_form: false, strength: false });
    resetSelections("indication_group");
    resetData("indication");
    props.onClose();
  };

  const loadIndicationGroups = () => {
    setLoading({ ...loading, indication_groups: true });
    axios
      .get(`apa/api/idm/indicationgroups`)
      .then((res) => {
        const indication_groups = res.data.map((indication_group) => ({
          value: indication_group.id,
          label: indication_group.name,
        }));
        setData({ ...data, indication_groups });
        setLoading({ ...loading, indication_groups: false });
      })
      .catch((error) => console.log(error));
  };

  const loadIndications = (indication_group_id) => {
    setLoading({ ...loading, indications: true });
    axios
      .get("apa/api/idm/indications", {
        params: {
          indicationgroup: indication_group_id,
        },
      })
      .then((res) => {
        const indications = res.data.map((indication) => ({
          value: indication.id,
          label: indication.name,
        }));
        setData({ ...data, indications });
        setLoading({ ...loading, indications: false });
      })
      .catch((error) => console.log(error));
  };

  const loadDrugGroups = (indication_id) => {
    setLoading({ ...loading, drug_groups: true });
    axios
      .get("apa/api/idm/druggroups", {
        params: {
          indication: indication_id,
        },
      })
      .then((res) => {
        const drug_groups = res.data.map((drug_group) => ({
          value: drug_group.id,
          label: drug_group.name,
        }));
        setData({ ...data, drug_groups });
        setLoading({ ...loading, drug_groups: false });
      })
      .catch((error) => console.log(error));
  };

  const loadDrugs = (drug_group_id) => {
    setLoading({ ...loading, drugs: true });
    axios
      .get("apa/api/idm/drugs", {
        params: {
          druggroup: drug_group_id,
        },
      })
      .then((res) => {
        const drugs = res.data.map((drug) => ({
          value: drug.reference_name,
          label: drug.name,
        }));
        setData({ ...data, drugs });
        setLoading({ ...loading, drugs: false });
      })
      .catch((error) => console.log(error));
  };

  const loadAdministrationForms = (drug_id) => {
    setLoading({ ...loading, administration_forms: true });
    axios
      .get("apa/api/idm/administrationforms", {
        params: {
          drug: drug_id,
        },
      })
      .then((res) => {
        const administration_forms = res.data.map((administration_form) => ({
          value: administration_form.signature_form,
          label: administration_form.signature_form,
        }));
        setData({ ...data, administration_forms });
        setLoading({ ...loading, administration_forms: false });
      })
      .catch((error) => console.log(error));
  };

  const loadStrengths = (drug_id, administration_form_id) => {
    setLoading({ ...loading, strengths: true });
    axios
      .get("apa/api/idm/strengths", {
        params: {
          drug: drug_id,
          administrationform: administration_form_id,
        },
      })
      .then((res) => {
        const strengths = res.data.map((strength) => ({
          value: strength.id,
          label: strength.name,
        }));
        setData({ ...data, strengths });
        setLoading({ ...loading, strengths: false });
      })
      .catch((error) => console.log(error));
  };

  React.useEffect(() => {
    // Load the data on component mount
    if (props.open) {
      setMAUAScore({ effectiveness: 5.5, safety: 5.5, applicability: 5.5 });
      setOpen(props.open);
    }
    if (data.indicationGroups == null) {
      loadIndicationGroups();
    }
    if (data.indications == null) {
      loadIndications();
    }
  }, [props.open]);

  const resetSelections = (start_point) => {
    // Resets selections from starting point downwards
    switch (start_point) {
      case "indication_group":
        setSelected({
          ...selected,
          indication_group: null,
          indication: null,
          drug_group: null,
          drug: null,
          administration_form: null,
          strength: null,
        });

        break;
      case "indication":
        setSelected({
          ...selected,
          indication: null,
          drug_group: null,
          drug: null,
          administration_form: null,
          strength: null,
        });

        break;
      case "drug_group":
        setSelected({ ...selected, drug_group: null, drug: null, administration_form: null, strength: null });

        break;
      case "drug":
        setSelected({ ...selected, drug: null, administration_form: null, strength: null });

        break;
      case "administration_form":
        setSelected({ ...selected, administration_form: null, strength: null });

        break;
      case "strength":
        setSelected({ ...selected, strength: null });

        break;
    }
  };
  const resetData = (start_point) => {
    // Resets data from start_point downwards
    switch (start_point) {
      case "indication_group":
        setData({
          ...data,
          indication_groups: [],
          indications: [],
          drug_groups: [],
          drugs: [],
          administration_forms: [],
          strengths: [],
        });
        break;
      case "indication":
        setData({
          ...data,
          indications: [],
          drug_groups: [],
          drugs: [],
          administration_forms: [],
          strengths: [],
        });
        break;
      case "drug_group":
        setData({ ...data, drug_groups: [], drugs: [], administration_forms: [], strengths: [] });
        break;
      case "drug":
        setData({ ...data, drugs: [], administration_forms: [], strengths: [] });
        break;
      case "administration_form":
        setData({ ...data, administration_forms: [], strengths: [] });
        break;
      case "strength":
        setData({ ...data, strengths: [] });
        break;
    }
  };

  const handleSubmit = () => {
    // Handles validation and sets up data to be passed to higher level onSubmit
    if (selected.drug === null) {
      setValid(false);
      setError({ drug: true, administration_form: true, strength: true });
    } else if (selected.administration_form === null) {
      setValid(false);
      setError({ drug: false, administration_form: true, strength: true });
    } else if (selected.strength === null) {
      setValid(false);
      setError({ drug: false, administration_form: false, strength: true });
    } else {
      setValid(true);
      let values = {
        drug: selected.drug,
        administration_form: selected.administration_form,
        strength: selected.strength,
      };
      if (props.MAUA) {
        values = { ...values, ...MAUAScore };
      }
      props.onSubmit(values);
      handleClose();
    }
  };

  const errorMessage = () => {
    // Define validation error msg
    if (!valid) {
      return (
        <Typography className="mt-2" variant="caption" display="block" color="error" gutterBottom>
          Error: All fields are required
        </Typography>
      );
    }
  };

  const renderMAUA = () => {
    // Renders the MAUA-score markers
    return (
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography gutterBottom>Effectiveness</Typography>
          <Slider
            defaultValue={5}
            onChangeCommitted={handleMAUAScoreChange("effectiveness")}
            step={0.5}
            min={0}
            max={10}
            marks={marks}
            valueLabelDisplay="on"
          />
        </Grid>
        <Grid item xs={4}>
          <Typography gutterBottom>Safety</Typography>
          <Slider
            defaultValue={5}
            step={0.5}
            onChangeCommitted={handleMAUAScoreChange("safety")}
            min={0}
            max={10}
            marks={marks}
            valueLabelDisplay="on"
          />
        </Grid>
        <Grid item xs={4}>
          <Typography gutterBottom>Applicability</Typography>
          <Slider
            defaultValue={5}
            onChangeCommitted={handleMAUAScoreChange("applicability")}
            step={0.5}
            min={0}
            max={10}
            marks={marks}
            valueLabelDisplay="on"
          />
        </Grid>
      </Grid>
    );
  };
  return (
    <Dialog classes={{ paperScrollPaper: classes.root }} open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
      {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
      <AppBar position="static" color="default">
        <Tabs
          value={tabIndex}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="IDM" {...a11yProps(0)} />
          <Tab icon={<SearchIcon />} {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <DialogContent style={{ overflow: "hidden" }}>
        <SwipeableViews axis={theme.direction === "rtl" ? "x-reverse" : "x"} index={tabIndex} onChangeIndex={handleChangeTabIndex}>
          <TabPanel value={tabIndex} index={0} dir={theme.direction}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SelectList
                  options={data.indication_groups}
                  onChange={(event, newValue) => {
                    setSelected({
                      ...selected,
                      indication_group: newValue,
                      indication: null,
                      drug_group: null,
                      drug: null,
                      administration_form: null,
                      strength: null,
                    });
                    if (newValue != null) {
                      loadIndications(newValue.value);
                      resetData("indication");
                    } else {
                      loadIndications();
                      resetData("drug_group");
                    }
                  }}
                  size="small"
                  value={selected.indication_group}
                  loading={loading.indication_groups}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => <TextField {...params} label="Indication group" />}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectList
                  options={data.indications}
                  onChange={(event, newValue) => {
                    setSelected({
                      ...selected,
                      indication: newValue,
                      drug_group: null,
                      drug: null,
                      administration_form: null,
                      strength: null,
                    });
                    if (newValue != null) {
                      loadDrugGroups(newValue.value);
                    }
                    //TODO add a check here to see if the selected indication_group is null, if this is the case select the corresponding indication_group
                    resetData("drug_group");
                  }}
                  size="small"
                  value={selected.indication}
                  loading={loading.indications}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => <TextField {...params} label="Indication" />}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectList
                  options={data.drug_groups}
                  onChange={(event, newValue) => {
                    setSelected({ ...selected, drug_group: newValue, drug: null, administration_form: null, strength: null });
                    if (newValue != null) {
                      loadDrugs(newValue.value);
                    }
                    resetData("drug");
                  }}
                  size="small"
                  value={selected.drug_group}
                  loading={loading.drug_groups}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => <TextField {...params} label="Drug group" />}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectList
                  options={data.drugs}
                  onChange={(event, newValue) => {
                    setSelected({ ...selected, drug: newValue, administration_form: null, strength: null });
                    if (newValue != null) {
                      setError({ ...error, drug: false });
                      loadAdministrationForms(newValue.value);
                    }
                    resetData("administration_form");
                  }}
                  size="small"
                  value={selected.drug}
                  loading={loading.drugs}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => <TextField {...params} label="Drug" error={error.drug} />}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectList
                  options={data.administration_forms}
                  onChange={(event, newValue) => {
                    setSelected({ ...selected, administration_form: newValue, strength: null });
                    if (newValue != null) {
                      setError({ ...error, administration_form: false });

                      loadStrengths(selected.drug.value, newValue.value);
                    }
                    resetData("strength");
                  }}
                  size="small"
                  value={selected.administration_form}
                  loading={loading.administration_forms}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => <TextField {...params} label="Administration form" error={error.administration_form} />}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectList
                  options={data.strengths}
                  onChange={(event, newValue) => {
                    setSelected({ ...selected, strength: newValue });

                    setError({ ...error, strength: false });

                    setValid(true);
                  }}
                  size="small"
                  value={selected.strength}
                  loading={loading.strengths}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => <TextField {...params} label="Strength" error={error.strength} />}
                />
              </Grid>
              <Grid item xs={12}>
                {errorMessage()}
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tabIndex} index={1} dir={theme.direction}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SelectList
                  options={data.drugs.sort(
                    (a, b) => -b.label.match("[a-zA-Z]").pop().toUpperCase().localeCompare(a.label.match("[a-zA-Z]").pop().toUpperCase())
                  )}
                  onChange={(event, newValue) => {
                    setSelected({ ...selected, drug: newValue, administration_form: null, strength: null });
                    if (newValue != null) {
                      setError({ ...error, drug: false });
                      loadAdministrationForms(newValue.value);
                    }
                    resetData("administration_form");
                  }}
                  size="small"
                  value={selected.drug}
                  loading={loading.drugs}
                  groupBy={(option) => option.label.match("[a-zA-Z]").pop().toUpperCase()}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => <TextField {...params} label="Drug" error={error.drug} />}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectList
                  options={data.administration_forms}
                  onChange={(event, newValue) => {
                    setSelected({ ...selected, administration_form: newValue, strength: null });
                    if (newValue != null) {
                      setError({ ...error, administration_form: false });

                      loadStrengths(selected.drug.value, newValue.value);
                    }
                    resetData("strength");
                  }}
                  size="small"
                  value={selected.administration_form}
                  loading={loading.administration_forms}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => <TextField {...params} label="Administration form" error={error.administration_form} />}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectList
                  options={data.strengths}
                  onChange={(event, newValue) => {
                    setSelected({ ...selected, strength: newValue });

                    setError({ ...error, strength: false });

                    setValid(true);
                  }}
                  size="small"
                  value={selected.strength}
                  loading={loading.strengths}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => <TextField {...params} label="Strength" error={error.strength} />}
                />
              </Grid>
              <Grid item xs={12}>
                {errorMessage()}
              </Grid>
            </Grid>
          </TabPanel>
        </SwipeableViews>
        {props.MAUA ? renderMAUA() : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IDMDialog;
