import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, LinearProgress } from "@material-ui/core";
import MultipleChoiceQuestionCard from "../questions/MultipleChoiceQuestionCard";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1, 2),
  },
}));

MultipleChoiceAssesment.propTypes = {};

function MultipleChoiceAssesment(props) {
  const classes = useStyles();

  const case_id = props.match.params.case_id;
  const [questions, setQuestions] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const loadMCQuestions = (case_id) => {
    // Load the case data from the backend
    setLoading(true);
    axios
      .get("apa/api/questions/mc/", {
        params: {
          case_id: case_id,
        },
      })
      .then((res) => {
        setLoading(false);
        setQuestions(res.data.sort((a, b) => (a.choices.length < b.choices.length ? 1 : -1)));
      })
      .catch((error) => console.log(error));
  };

  React.useEffect(() => {
    if (questions == null) {
      loadMCQuestions(case_id);
    }
  }, [loadMCQuestions]);

  return (
    <React.Fragment>
      {loading ? (
        <LinearProgress />
      ) : questions && questions.length ? (
        <Grid container spacing={3}>
          {questions.map((question) => (
            <Grid item xs={4}>
              <MultipleChoiceQuestionCard question={question} type="assesment" />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Alert severity="info">No multiple choice questions exist for this case.</Alert>
      )}
    </React.Fragment>
  );
}
export default MultipleChoiceAssesment;
