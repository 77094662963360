import { Box, LinearProgress, makeStyles, CssBaseline } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { Switch } from "react-router-dom";
import BackgroundImage from "./assets/background.jpg";
import ResponsiveDrawer from "./components/navigation/ResponsiveDrawer";
import AuthRoutes from "./components/routes/AuthRoutes";
import { authCheckState } from "./store/actions/auth";


// Application component
// This is the main component the user interacts with and handles the authentication

const useStyles = makeStyles((theme) => ({
  progress: {
    width: "100%",
    position: "absolute",
    top: "0px",
    right: "0",
  },
  paperContainer: {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100vh",
    margin: "0px !important"
  },
}));

function App(props) {
  const classes = useStyles();
  const [triedSignup, setTriedSignup] = React.useState(false);
  // Determine if the user is authorized through an api
  const isAPIAuth = localStorage.getItem("api_auth");

  React.useEffect(() => {
    // Try to authorize the user based on the stored token
    if (!triedSignup) {
      props.onTryAutoSignup();
      setTriedSignup(true);
    }
  }, [props.isAuthenticated, props.isLoading, props, triedSignup]);

  const renderLoading = () => {
    return <LinearProgress className={classes.progress} />;
  };

  const renderApp = () => {
    // If the user is authorized render the application, otherwise the login screen
    return (
      <div>
        {props.isAuthenticated || isAPIAuth ? (
          <ResponsiveDrawer />
        ) : (
          <Box className={classes.paperContainer} pt={8}>
               <CssBaseline />
            <Switch>
              <AuthRoutes />
            </Switch>
          </Box>
        )}
      </div>
    );
  };
  return <div>{props.isLoading ? renderLoading() : renderApp()}</div>;
}

const mapStateToProps = (state) => {
  // Set the isAuthenticated flag if a login token exists in redux
  return {
    isAuthenticated: state.auth.token != null,
    isLoading: state.auth.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  // Check if the token is the valid to authorize the user
  return {
    onTryAutoSignup: () => dispatch(authCheckState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
